import moment from 'moment';
import { CourseModificationReasonResult } from '../api-results/course-modification-reason.api-result';
import { Course } from './course.entity';
import { Writer } from './writer.entity';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import {
  AddCourseModificationReasonPayload,
  CourseModificationReasonFormValue,
  UpdateCourseModificationReasonPayload,
} from '../payloads/course-modification-reason.payload';
import { AddedEntity } from '../api-results/base.api-result';

export class CourseModificationReason {
  id: string;
  createdAt: Date;
  content: string;
  author: Writer;

  constructor(result: CourseModificationReasonResult) {
    this.id = result.id;
    if (result.createdAt) this.createdAt = moment(result.createdAt).toDate();
    this.content = result.content;
    if (result.author) this.author = new Writer(result.author);
  }

  toFormValue(): CourseModificationReasonFormValue {
    return {
      content: this.content,
    };
  }

  toResult(): CourseModificationReasonResult {
    return {
      id: this.id,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      content: this.content,
      author: this.author?.toResult(),
    };
  }

  static addedEntityToResult(
    payload: AddCourseModificationReasonPayload,
    writer: Writer,
    addedEntity: AddedEntity
  ): CourseModificationReasonResult {
    return {
      id: addedEntity.id,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      content: payload.content,
      author: writer.toResult(),
    };
  }

  static updatedEntityToResult(
    payload: UpdateCourseModificationReasonPayload
  ): Partial<CourseModificationReasonResult> {
    return {
      content: payload.content,
    };
  }
}
