import { questionMaxGradeForContext } from '../../utils/question-utils';
import { ContentSessionResult } from '../api-results/content-session.api-result';
import { NotionRank } from '../enums/notion.enums';
import { ContentSessionType } from '../enums/session.enum';
import { ContentQuestion } from './content-question.entity';
import { Course } from './course.entity';
import { Exam } from './exam.entity';
import { Folder } from './folder.entity';
import { Rate } from './rate.entity';
import { SessionQuestion } from './session-question.entity';

export abstract class ContentSession {
  id: string;
  grade: number;
  questions: SessionQuestion[];

  index: number;

  constructor(result: ContentSessionResult) {
    this.id = result.id;
    this.grade = result.grade;
  }

  toResult(): ContentSessionResult {
    return {
      id: this.id,
      grade: this.grade,
      challengeSessionQuestionAnswers: this.questions?.map((elt) => elt.toResult()),
      customExamSessionQuestionAnswers: this.questions?.map((elt) => elt.toResult()),
      folderSessionQuestionAnswers: this.questions?.map((elt) => elt.toResult()),
      isolatedQuestionsSessionQuestionAnswers: this.questions?.map((elt) => elt.toResult()),
    };
  }

  abstract get type(): ContentSessionType;
  abstract get content(): Folder;
  abstract get contentQuestions(): ContentQuestion[];
  abstract get isCompleted(): boolean;
  abstract get maxGrade(): number;
  abstract get studentRate(): Rate;
  abstract get courses(): Course[];

  getSessionQuestion(id: string) {
    return this.questions.find((question) => question.question?.id === id);
  }

  get maxGradeOnRankAQuestions() {
    return this.contentQuestions
      .filter((contentQuestion) => contentQuestion.question.hardestNotion?.rank === NotionRank.A)
      .reduce((sum, contentQuestion) => sum + questionMaxGradeForContext(this.content?.type), 0);
  }

  get gradeOnRankAQuestions() {
    return this.questions
      .filter(
        (question) =>
          this.contentQuestions.find(
            (contentQuestion) => contentQuestion.question.id === question.question?.id
          )?.question.hardestNotion?.rank === NotionRank.A
      )
      .reduce((sum, question) => sum + question.grade, 0);
  }
}
