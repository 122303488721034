export enum NotificationTemplate {
  COMMUNICATION_MARKETING = 'COMMUNICATION_MARKETING',
  CUSTOM = 'CUSTOM',
  NEW_EXAM_ONLINE = 'NEW_EXAM_ONLINE',
  NEW_CORRECTION_ONLINE = 'NEW_CORRECTION_ONLINE',
  NEW_RANKING_ONLINE = 'NEW_RANKING_ONLINE',
  NEW_EXAM_GROUP_RANKING_ONLINE = 'NEW_EXAM_GROUP_RANKING_ONLINE',
  NEW_ARTICLE_ONLINE = 'NEW_ARTICLE_ONLINE',
  NEW_ITEM_ONLINE = 'NEW_ITEM_ONLINE',

  NEW_USER_MESSAGE = 'NEW_USER_MESSAGE',
  NEW_FEEDBACK_ANSWER_FROM_WRITER = 'NEW_FEEDBACK_ANSWER_FROM_WRITER',
  ANCRAGE_NEW_REVISION = 'ANCRAGE_NEW_REVISION',
  FOLLOW_NEW_FOLLOW_REQUEST = 'FOLLOW_NEW_FOLLOW_REQUEST',
  FOLLOW_REQUEST_APPROVED = 'FOLLOW_REQUEST_APPROVED',
  FOLLOW_NEW_FOLLOWER = 'FOLLOW_NEW_FOLLOWER',
  CHALLENGE_FIRST_PLACE = 'CHALLENGE_FIRST_PLACE',
  CHALLENGE_ACCEPT_INVITATION = 'CHALLENGE_ACCEPT_INVITATION',
  CHALLENGE_SEND_INVITATION = 'CHALLENGE_SEND_INVITATION',
  CHALLENGE_DECLINE_INVITATION = 'CHALLENGE_DECLINE_INVITATION',
  TRANSACTION_SUCCEED = 'TRANSACTION_SUCCEED',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
}

export enum NotificationCampaignTargetedActiveUsers {
  LOGGED_IN_LAST_3_MONTHS = 'LOGGED_IN_LAST_3_MONTHS',
  LOGGED_IN_LAST_6_MONTHS = 'LOGGED_IN_LAST_6_MONTHS',
  ALL = 'ALL',
}
