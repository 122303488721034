<div
  class="nnki-rounded-background-image mb-4 relative w-20"
  [style.background-image]="sanitizerService.imageProfile(student.picture)"
>
  <div
    class="absolute top-0 bottom-0 right-0 left-0 bg-theme-gray-twentytwo"
    *ngIf="student.selected"
  ></div>

  <div
    class="absolute rounded-full w-12 h-12 -right-2 bottom-0 bg-green-three flex-center"
    *ngIf="student.selected"
  >
    <svg-icon
      class="text-theme-white"
      src="assets/images/svg-icons/icon-tick-2.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </div>
</div>

<div class="text-13 font-bold truncate text-center max-w-full">
  {{ student.username }}
</div>
