import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { filter, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/inherited/base/base.component';

import { LanguageService } from './language.service';
import {
  StudentSettingName,
  StudentSettingSiteColorScheme,
} from '../../shared/models/enums/student-settings.enum';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../core/state/auth-state/auth.selectors';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ColorService extends BaseComponent {
  renderer: Renderer2;
  colorScheme: 'light' | 'dark' = 'light';

  userSiteColorScheme: StudentSettingSiteColorScheme;
  osPrefersDarkMode: boolean;

  constructor(
    rendererFactory: RendererFactory2,
    private router: Router,
    private store: Store,
    private languageService: LanguageService
  ) {
    super();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init() {
    this.renderer.addClass(document.body, this.languageService.siteThemeClass);

    this.store
      .select(AuthSelectors.studentSetting(StudentSettingName.SITE_COLOR_SCHEME))
      .pipe(takeUntil(this.alive$))
      .subscribe((siteColorScheme) => {
        this.userSiteColorScheme = siteColorScheme?.siteColorSchemeValue
          ? siteColorScheme.siteColorSchemeValue
          : StudentSettingSiteColorScheme.LIGHT_MODE;
        this.updateThemeMode();
      });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateThemeMode();
    });

    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      this.osPrefersDarkMode = darkMediaQuery.matches;
    } else {
      this.osPrefersDarkMode = false;
    }
    this.updateThemeMode();

    try {
      darkMediaQuery.addEventListener('change', (e) => {
        this.osPrefersDarkMode = e.matches;
        this.updateThemeMode();
      });
    } catch (e1) {
      try {
        darkMediaQuery.addListener((e) => {
          this.osPrefersDarkMode = e.matches;
          this.updateThemeMode();
        });
      } catch (e2) {
        console.error(e2);
      }
    }
  }

  updateThemeMode() {
    const url = this.router.url;
    if (
      this.userSiteColorScheme === StudentSettingSiteColorScheme.LIGHT_MODE ||
      url.search('/app/admin') !== -1
    ) {
      this.updateRenderer('light');
    } else if (this.userSiteColorScheme === StudentSettingSiteColorScheme.DARK_MODE) {
      this.updateRenderer('dark');
    } else if (this.osPrefersDarkMode) {
      this.updateRenderer('dark');
    } else {
      this.updateRenderer('light');
    }
  }

  updateRenderer(colorScheme: 'light' | 'dark') {
    if (this.colorScheme !== colorScheme) {
      this.colorScheme = colorScheme;

      if (this.colorScheme === 'dark') {
        this.renderer.addClass(document.body, 'dark-theme-mode');
        this.renderer.removeClass(document.body, 'light-theme-mode');
      } else if (this.colorScheme === 'light') {
        this.renderer.addClass(document.body, 'light-theme-mode');
        this.renderer.removeClass(document.body, 'dark-theme-mode');
      }
    }
  }

  // nnkitodo [v2later] faire ça plus proprement ?

  getSiteMainColor() {
    if (this.languageService.isEcni()) {
      return '#0054F2';
    } else if (this.languageService.isPrepSSM()) {
      return '#0ec164';
    } else if (this.languageService.isPrepMIR()) {
      return '#d32030';
    }
  }

  getSiteLightSidebar() {
    if (this.languageService.isEcni()) {
      return '#ebf3fe';
    } else if (this.languageService.isPrepSSM()) {
      return '#dcffeb';
    } else if (this.languageService.isPrepMIR()) {
      return '#ebf3fe';
    }
    return '#ebf3fe';
  }

  getAppGreenThree() {
    return '#0ec164';
  }

  getGreenFive() {
    return '#CDF6F2';
  }

  getAppGreenSix() {
    return '#b4ec51';
  }

  getAppBlueSeven() {
    return '#0054f2';
  }

  getAppPurpleFour() {
    return '#b471f3';
  }

  getAppPurpleFive() {
    return '#F0E3FD';
  }

  getAppGrayEight() {
    return '#dadfe4';
  }

  getAppOrangeOne() {
    return '#f74e13';
  }

  getAppOrangeTwo() {
    return '#eeb635';
  }

  getAppRed() {
    return '#fc4747';
  }

  getAppRedTwo() {
    return '#f10b28';
  }

  getAppYellowOne() {
    return '#eeb735';
  }

  getTextColor() {
    if (this.colorScheme === 'light') {
      return '#000000';
    } else if (this.colorScheme === 'dark') {
      return '#ffffff';
    }
  }
}
