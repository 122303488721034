import { Store } from '@ngrx/store';
import { ListGraduationNamesResult } from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversitiesResult } from '../../../shared/models/api-results/university.api-result';
import { FormControl } from '@angular/forms';
import { combineLatest, map, startWith } from 'rxjs';
import * as GlobalEntitiesSelectors from '../global-entities-state/global-entities.selectors';
import { UniversityCountry } from '../../../shared/models/entities/university-country.entity';
import { UniversityCity } from '../../../shared/models/entities/university-city.entity';

export function getGraduationNameResult(
  graduationNames: ListGraduationNamesResult,
  graduationNameId: string
) {
  if (!graduationNames) {
    return null;
  }
  return graduationNames?.['hydra:member'].find(
    (graduationName) => graduationName.id === graduationNameId
  );
}

export function getUniversityResult(universities: ListUniversitiesResult, universityId: string) {
  if (!universities) {
    return null;
  }
  return universities?.['hydra:member'].find((university) => university.id === universityId);
}

export function getUniversityCitiesFromCountry(
  store: Store,
  control: FormControl<UniversityCountry>
) {
  return combineLatest([
    store.select(GlobalEntitiesSelectors.universityCities),
    control.valueChanges.pipe(startWith(control.value)),
  ]).pipe(
    map(([universityCities, universityCountry]) => {
      if (!universityCities || !universityCountry) return [];
      return universityCities.filter(
        (universityCity) => universityCity.universityCountry.id === universityCountry.id
      );
    })
  );
}

export function getUniversitiesFromCity(store: Store, control: FormControl<UniversityCity>) {
  return combineLatest([
    store.select(GlobalEntitiesSelectors.universities),
    control.valueChanges.pipe(startWith(control.value)),
  ]).pipe(
    map(([universities, universityCity]) => {
      if (!universities || !universityCity) return [];
      return universities.filter(
        (university) => university.universityCity.id === universityCity.id
      );
    })
  );
}
