import moment from 'moment';
import { ChallengeResult } from '../api-results/challenge.api-result';
import { ChallengeInvitation } from './challenge-invitation.entity';
import { ChallengeSession } from './challenge-session.entity';
import { Specialty } from './specialty.entity';
import { Student } from './student.entity';

export class Challenge {
  id: string;
  author: Student;
  specialty: Specialty;
  createdAt: Date;
  isClosed: boolean;

  challengeInvitations: ChallengeInvitation[];
  challengeSessions: ChallengeSession[];

  constructor(result: ChallengeResult) {
    this.id = result.id;
    if (result.author) this.author = new Student(result.author);
    if (result.specialty) this.specialty = new Specialty(result.specialty);
    if (result.createdAt) this.createdAt = moment(result.createdAt).toDate();
    this.isClosed = result.isClosed ? true : false;

    if (result.challengeInvitations) {
      this.challengeInvitations = result.challengeInvitations.map(
        (elt) => new ChallengeInvitation(elt)
      );
    } else {
      this.challengeInvitations = [];
    }
    if (result.challengeSessions) {
      this.challengeSessions = result.challengeSessions.map((elt) => new ChallengeSession(elt));
    } else {
      this.challengeSessions = [];
    }
  }

  get willBeClosedAt() {
    const willBeClosedAt = moment(this.createdAt).toDate();
    willBeClosedAt.setHours(this.createdAt.getHours() + 24);
    return willBeClosedAt;
  }
}
