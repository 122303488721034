// nnkitodo [v2later modal] suffixer par modale + utiliser component modale
// nnkitodo [v2later modal] revoir tout le css/html des modals avec un component et ng-content ?-->
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() widthType: 'M' | 'L' | 'XL' = 'M';
  @Input() isWriterArea = false;
  @Input() color: 'site-main-one' | 'red-one' = 'site-main-one';

  @Output() close = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
