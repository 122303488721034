export enum CorrectionType {
  TEXT = 'TEXT',
  COORDINATE = 'COORDINATE',
  TCS = 'TCS',
}

export enum CorrectionStatus {
  WIN = 'WIN',
  FAIL = 'FAIL',
  MANDATORY = 'MANDATORY',
  ELIMINATORY = 'ELIMINATORY',
}
