import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  ChallengeScoreResult,
  GlobalChallengeScoreResult,
} from '../../../shared/models/api-results/challenge-score.api-result';
import { map } from 'rxjs';
import { ListChallengeSpecialtyScoreBadgesResult } from '../../../shared/models/api-results/challenge-specialty-score-badge.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';

@Injectable({
  providedIn: 'root',
})
export class HttpChallengeSpecialtyScoreBadgeService {
  constructor(private http: HttpClient) {}

  listMyChallengeSpecialtyScoreBadges(params: QueryData) {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_specialty_score_badges/list_my_challenge_specialty_score_badges`,
        {
          params: prepareQueryParams(params),
        }
      )
      .pipe(map((res) => res as ListChallengeSpecialtyScoreBadgesResult));
  }

  listChallengeSpecialtyScoreBadgesOfStudent(id: string, params: QueryData) {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_specialty_score_badges/list_challenge_specialty_score_badges_of_another_student/${id}`,
        {
          params: prepareQueryParams(params),
        }
      )
      .pipe(map((res) => res as ListChallengeSpecialtyScoreBadgesResult));
  }
}
