<div class="relative flex flex-col sm:block grow -mb-32 md:mb-0 h-auto md:h-[560px] lg:h-[500px]">
  <app-loader-initial *ngIf="firstLoading"></app-loader-initial>

  <ng-container *ngIf="!firstLoading">
    <div class="user-panel" [class.-inside]="animationState.panelLeft">
      <app-user-sunray
        [student]="loggedStudent$ | async"
        [challengeScore]="globalChallengeScore?.challengeScores"
        [challengeSpecialtyScoreBadges]="challengeSpecialtyScoreBadges"
        [introChallengeMode]="true"
        [class.-inside]="animationState.user"
      ></app-user-sunray>
    </div>

    <div class="opponents-panel" [class.-inside]="animationState.panelRight">
      <ng-container *ngIf="challengeSession">
        <div
          class="opponent-block"
          *ngFor="
            let student of challengeSession.students
              | filterLoggedUser : (loggedStudent$ | async).id
              | slice : 0 : 3;
            let index = index
          "
          [class.-inside]="animationState['challenger' + index]"
        >
          <app-user-sunray
            [student]="student"
            [challengeScore]="student.globalChallengeScore?.challengeScores"
            [challengeSpecialtyScoreBadges]="student.challengeSpecialtyScoreBadges"
            [introChallengeMode]="true"
            [class.-inside]="animationState.user"
          ></app-user-sunray>
        </div>

        <div
          class="more-opponents-block"
          *ngIf="
            (challengeSession.students | filterLoggedUser : (loggedStudent$ | async).id).length > 3
          "
          [class.-inside]="animationState.moreChallengers"
        >
          <div class="more-background"></div>
          <div class="more-numbers">
            +
            {{
              (challengeSession.students | filterLoggedUser : (loggedStudent$ | async).id).length -
                3
            }}
          </div>
          <div class="more-names">
            <ng-container
              *ngFor="
                let student of challengeSession.students
                  | filterLoggedUser : (loggedStudent$ | async).id
                  | slice : 3;
                let index = index
              "
            >
              <ng-container *ngIf="index > 0">, </ng-container>
              {{ student.username }}
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="spinner_element_container" *ngIf="!challengeSession">
        <app-loader-dots></app-loader-dots>
      </div>
    </div>

    <div
      class="discipline-badge absolute top-12 left-0"
      [class.-inside]="animationState.discipline"
    >
      <div class="badge-content">
        <img [src]="challengeSession.challenge.specialty.icon" />
        <span>{{ challengeSession.challenge.specialty.name }}</span>
      </div>
    </div>

    <div
      class="versus-badge"
      [class.-inside]="animationState.versus"
      [class.-heartbeat]="animationState.versusHeartbeat"
    >
      VS
      <svg-icon
        src="assets/images/svg-illustrations/icon-flash.svg"
        [svgStyle]="{ 'width.px': '64' }"
      ></svg-icon>

      <app-loader-dots *ngIf="isLoading"></app-loader-dots>
    </div>
  </ng-container>
</div>
