import { createSelector } from '@ngrx/store';
import { GraduationName } from '../../../shared/models/entities/graduation-name.entity';
import { UniversityCity } from '../../../shared/models/entities/university-city.entity';
import { UniversityCountry } from '../../../shared/models/entities/university-country.entity';
import { University } from '../../../shared/models/entities/university.entity';
import { AppState } from '../core.reducers';
import { ExamType } from '../../../shared/models/entities/exam-type.entity';
import { AuthorizedExamType } from '../../../shared/models/enums/exam.enums';

export const selectGlobalEntities = (state: AppState) => state.globalEntities;

export const rawGraduationNames = createSelector(
  selectGlobalEntities,
  (state) => state.graduationNames
);

export const graduationNames = createSelector(selectGlobalEntities, (state) => {
  if (state.graduationNames) {
    return state.graduationNames['hydra:member'].map((elt) => new GraduationName(elt));
  } else {
    return undefined;
  }
});

export const universityCountries = createSelector(selectGlobalEntities, (state) => {
  if (state.universityCountries) {
    return state.universityCountries['hydra:member']
      .map((elt) => new UniversityCountry(elt))
      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
  } else {
    return undefined;
  }
});

export const universityCities = createSelector(selectGlobalEntities, (state) => {
  if (state.universityCities) {
    return state.universityCities['hydra:member'].map((elt) => new UniversityCity(elt));
  } else {
    return undefined;
  }
});

export const rawUniversities = createSelector(selectGlobalEntities, (state) => state.universities);

export const universities = createSelector(selectGlobalEntities, (state) => {
  if (state.universities) {
    return state.universities['hydra:member'].map((elt) => new University(elt));
  } else {
    return undefined;
  }
});

export const examTypes = createSelector(selectGlobalEntities, (state) => {
  if (state.examTypes) {
    return state.examTypes['hydra:member']
      .map((elt) => new ExamType(elt))
      .sort((a, b) => (a.priority > b.priority ? 1 : -1));
  } else {
    return undefined;
  }
});

export const examType = (id: string) =>
  createSelector(examTypes, (examTypes) => examTypes?.find((examType) => examType.id === id));

export const isConftubeEnabled = createSelector(examTypes, (examTypes) => {
  if (!examTypes) return undefined;
  return !!examTypes.find((examType) => examType.name === AuthorizedExamType.CONFTUBE);
});

export const classicExamTypes = createSelector(examTypes, (examTypes) => {
  if (!examTypes) return undefined;
  // nnkitodo [v2later] ajouter des bools sur ExamTypes pour gérer Ecos / Unit folders etc
  return examTypes.filter(
    (examType) =>
      examType.name !== AuthorizedExamType.ECOS &&
      examType.name !== AuthorizedExamType.EASY_DP &&
      examType.name !== AuthorizedExamType.MINI_DP &&
      examType.name !== AuthorizedExamType.KFP &&
      examType.name !== AuthorizedExamType.TCS
  );
});

export const unitFolderExamTypes = createSelector(examTypes, (examTypes) => {
  if (!examTypes) return undefined;
  // nnkitodo [v2later] ajouter des bools sur ExamTypes pour gérer Ecos / Unit folders etc
  return examTypes.filter(
    (examType) =>
      examType.name === AuthorizedExamType.EASY_DP ||
      examType.name === AuthorizedExamType.MINI_DP ||
      examType.name === AuthorizedExamType.KFP ||
      examType.name === AuthorizedExamType.TCS
  );
});

export const ecosTypes = createSelector(examTypes, (examTypes) => {
  if (!examTypes) return undefined;
  // nnkitodo [v2later] ajouter des bools sur ExamTypes pour gérer Ecos / Unit folders etc
  return examTypes.filter((examType) => examType.name === AuthorizedExamType.ECOS);
});

export const annalsTypes = createSelector(examTypes, (examTypes) => {
  if (!examTypes) return undefined;
  // nnkitodo [v2later] ajouter des bools sur ExamTypes pour gérer Ecos / Unit folders etc
  return examTypes.filter((examType) => examType.name === AuthorizedExamType.ANNALS);
});

export const examsListURLFromType = (id: string) =>
  createSelector(
    classicExamTypes,
    unitFolderExamTypes,
    ecosTypes,
    (classicExamTypes, unitFolderExamTypes, ecosTypes) => {
      if (classicExamTypes?.find((examType) => examType.id === id)) {
        return ['/app/exams/examens'];
      } else if (unitFolderExamTypes?.find((examType) => examType.id === id)) {
        return ['/app/exams/folders-unite'];
      } else if (ecosTypes?.find((examType) => examType.id === id)) {
        return ['/app/ecos/ecos'];
      } else {
        return ['/app/exams'];
      }
    }
  );

export const countries = createSelector(selectGlobalEntities, (state) => state.countries);
