<div *ngIf="pictureStatementContents.length">
  <ng-container *ngFor="let document of pictureStatementContents | slice : 0 : 1">
    <img
      class="w-full rounded relative cursor-pointer"
      (click)="openModalImage(0)"
      [src]="document.file"
      alt=""
    />
  </ng-container>

  <div class="mt-4 grid gap-4 grid-cols-4">
    <div
      class="rounded overflow-hidden cursor-pointer aspect-w-1 aspect-h-1"
      (click)="openModalImage(i + 1)"
      *ngFor="let image of pictureStatementContents | slice : 1 : 4; let i = index"
    >
      <img class="object-cover object-center" [src]="image.file" alt="" />
    </div>

    <div
      class="rounded cursor-pointer bg-theme-white border border-theme-gray-three text-site-main-one font-extrabold flex-center"
      *ngIf="pictureStatementContents.length > 4"
      (click)="openModalImage(4)"
    >
      <span>+{{ pictureStatementContents.length - 4 }} </span>
    </div>
  </div>
</div>
