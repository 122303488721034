import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ListUniversitiesResult } from '../../../shared/models/api-results/university.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { UniversityPayload } from '../../../shared/models/payloads/university.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpUniversityService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/universities/list_universities`)
      .pipe(map((res) => res as ListUniversitiesResult));
  }

  wAdd(payload: UniversityPayload) {
    return this.http
      .post(`${environment.api_base_url}/universities/add_university`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UniversityPayload) {
    return this.http.post(
      `${environment.api_base_url}/universities/${id}/update_university`,
      payload
    );
  }
}
