import { Country } from '../../../shared/constants/countries';
import { ListExamTypesResult } from '../../../shared/models/api-results/exam-type.api-result';
import { ListGraduationNamesResult } from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversityCitiesResult } from '../../../shared/models/api-results/university-city.api-result';
import { ListUniversityCountriesResult } from '../../../shared/models/api-results/university-country.api-result';
import { ListUniversitiesResult } from '../../../shared/models/api-results/university.api-result';

export interface GlobalEntitiesState {
  graduationNames: ListGraduationNamesResult;
  universityCountries: ListUniversityCountriesResult;
  universityCities: ListUniversityCitiesResult;
  universities: ListUniversitiesResult;
  examTypes: ListExamTypesResult;
  countries: Country[];
}

export const initialGlobalEntitiesState: GlobalEntitiesState = {
  graduationNames: undefined,
  universityCountries: undefined,
  universityCities: undefined,
  universities: undefined,
  examTypes: undefined,
  countries: undefined,
};
