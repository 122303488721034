import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SanitizerService {
  constructor(private domSanitizer: DomSanitizer) {}

  defaultImage() {
    return `assets/images/thumbnails/someone-1.jpg`;
  }

  defaultImageToBackground() {
    return this.toBackground(this.defaultImage());
  }

  toBackground(url: string) {
    const newUrl = 'url(' + url + ')';
    return this.domSanitizer.bypassSecurityTrustStyle(newUrl);
  }

  imageProfile(url: string) {
    if (url) {
      return this.toBackground(url);
    } else {
      return this.defaultImageToBackground();
    }
  }
}
