import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  FrontAppVersionResult,
  LastDeployedVersionsResult,
  ListFrontAppVersionsResult,
} from '../../../shared/models/api-results/front-app-version.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { FrontAppVersionPayload } from '../../../shared/models/payloads/front-app-version.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpFrontAppVersionService {
  constructor(private http: HttpClient) {}

  getLastDeployedVersions() {
    return this.http
      .get(`${environment.api_base_url}/front_app_versions/list_last_deployed_front_app_versions`)
      .pipe(map((res) => res as LastDeployedVersionsResult));
  }

  wGet(id: string) {
    return this.http
      .get(`${environment.api_base_url}/front_app_versions/${id}/front_app_version_detail`)
      .pipe(map((res) => res as FrontAppVersionResult));
  }

  wList(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/front_app_versions/list_front_app_versions`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFrontAppVersionsResult));
  }

  wAdd(payload: FrontAppVersionPayload) {
    return this.http
      .post(`${environment.api_base_url}/front_app_versions/add_front_app_version`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wDelete(id: string) {
    return this.http.post(
      `${environment.api_base_url}/front_app_versions/${id}/delete_front_app_version`,
      {}
    );
  }

  wUpdate(id: string, payload: FrontAppVersionPayload) {
    return this.http.post(
      `${environment.api_base_url}/front_app_versions/${id}/update_front_app_version`,
      payload
    );
  }
}
