import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import * as AuthActions from '../state/auth-state/auth.actions';

import { take, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(AuthSelectors.loggedStudent).pipe(
      take(1),
      tap((student) => {
        if (!student) {
          this.store.dispatch(AuthActions.savePathToReachAfterLogin({ path: state.url }));
          this.router.navigate(['/user/login']);
        }
      }),
      map((student) => !!student)
    );
  }
}
