import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  FolderProgressResult,
  GlobalProgressResult,
  ListTrainingSessionsGraphResult,
} from '../../../shared/models/api-results/progress.api-result';
import { map, tap } from 'rxjs';
import { ExamTypeResult } from '../../../shared/models/api-results/exam-type.api-result';
import { ListResult } from '../../../shared/models/api-results/base.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import {
  ExamContentSessionGraphQueryData,
  TrainingSessionGraphQueryData,
} from '../../../shared/models/payloads/progress.payload';
import { ListExamContentSessionsResult } from '../../../shared/models/api-results/exam-content-session.api-result';
import { ListTrainingSessionsResult } from '../../../shared/models/api-results/training-session.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpProgressService {
  constructor(private http: HttpClient) {}

  getMyExamContentSessionsGraph(params: ExamContentSessionGraphQueryData) {
    delete params.page;
    return this.http
      .get(
        `${environment.api_base_url}/exam_content_session/me/list_my_exam_content_sessions_graph`,
        {
          params: prepareQueryParams(params),
        }
      )
      .pipe(map((res) => res as ListExamContentSessionsResult));
  }

  getMyTrainingSessionsGraph(params: TrainingSessionGraphQueryData) {
    delete params.page;
    return this.http
      .get(`${environment.api_base_url}/training_sessions/get_my_training_sessions_graph`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListTrainingSessionsGraphResult));
  }

  getMyGlobalProgress() {
    return this.http
      .get(`${environment.api_base_url}/questions/me/get_my_global_progress_on_published_questions`)
      .pipe(map((res) => res as GlobalProgressResult));
  }

  getGlobalProgressOfStudent(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/questions/get_the_global_progress_on_published_questions_of_another_student/${id}`
      )
      .pipe(map((res) => res as GlobalProgressResult));
  }

  getMyFolderProgress() {
    return this.http
      .get(`${environment.api_base_url}/folders/me/my_progress_on_published_folders`)
      .pipe(map((res) => res as FolderProgressResult));
  }

  getMyExamProgress() {
    return this.http
      .get(
        `${environment.api_base_url}/exam_types/list_my_progress_on_published_exams_grouped_by_exam_type`
      )
      .pipe(map((res) => res as ListResult<ExamTypeResult>));
  }
}
