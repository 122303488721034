import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { ListOffersResult, OfferResult } from '../../../shared/models/api-results/offer.api-result';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import {
  AddCustomOfferPayload,
  AddOfferPayload,
  UpdateOfferEndAtPayload,
  UpdateOfferPayload,
  WOfferQueryData,
} from '../../../shared/models/payloads/offer.payload';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpOfferService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/offers/list_available_offers`)
      .pipe(map((res) => res as ListOffersResult));
  }

  wGet(id: string) {
    return this.http
      .get(`${environment.api_base_url}/offers/${id}/offer_details`)
      .pipe(map((res) => res as OfferResult));
  }

  wList(params: WOfferQueryData) {
    return this.http
      .get(`${environment.api_base_url}/offers/list_offers`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListOffersResult));
  }

  wAdd(payload: AddOfferPayload | AddCustomOfferPayload) {
    return this.http
      .post(`${environment.api_base_url}/offers/add_offer`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UpdateOfferPayload) {
    return this.http.post(
      `${environment.api_base_url}/offers/${id}/update_offer_not_yet_valid`,
      payload
    );
  }

  wUpdateEndAt(id: string, payload: UpdateOfferEndAtPayload) {
    return this.http.post(
      `${environment.api_base_url}/offers/${id}/update_end_at_of_offer_not_yet_invalid`,
      payload
    );
  }
}
