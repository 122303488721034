import {
  BankAccountResult,
  WriterResult,
  WriterStatisticResult,
} from '../api-results/writer.api-result';
import { UserRole } from '../enums/auth.enums';
import {
  WriterBankAccountFormValue,
  WriterRedactionInformationsFormValue,
  WriterRedactionInformationsPayload,
} from '../payloads/writer.payload';
import { Contract } from './contract.entity';
import { EcniUser } from './ecni-user.entity';
import { Specialty } from './specialty.entity';

export class Writer extends EcniUser {
  socialSecurityNumber: string;

  bankAccount: BankAccount;
  specialties: Specialty[];
  contracts: Contract[];

  writerStatistic: WriterStatistic;

  constructor(result: WriterResult) {
    super(result);

    this.socialSecurityNumber = result.socialSecurityNumber;

    if (result.bankAccount) {
      this.bankAccount = new BankAccount(result.bankAccount);
    }

    if (result.specialties) {
      this.specialties = result.specialties
        .map((eltResult) => new Specialty(eltResult))
        .sort((a, b) => (a > b ? 1 : -1));
    }

    if (result.contracts) {
      this.contracts = result.contracts.map((elt) => new Contract(elt));
    }

    if (result.writerStatistic) {
      this.writerStatistic = new WriterStatistic(result.writerStatistic);
    }
  }

  // nnkitodo [v2later entity] voir si on peut pas gérer ça mieux
  writerToFormValue(): Partial<WriterRedactionInformationsFormValue> {
    return {
      socialSecurityNumber: this.socialSecurityNumber,
    };
  }

  toResult(): WriterResult {
    return {
      ...super.toResult(),
      role: this.role,
      socialSecurityNumber: this.socialSecurityNumber,
      bankAccount: this.bankAccount?.toResult(),
      specialties: this.specialties?.map((elt) => elt.toResult()),
      contracts: this.contracts?.map((elt) => elt.toResult()),
      writerStatistic: this.writerStatistic?.toResult(),
    };
  }

  // nnkitodo [v2later entity] voir si on peut mutualiser ça avec le toResult
  static writerUpdatedEntityToResult(
    payload: WriterRedactionInformationsPayload
  ): Partial<WriterResult> {
    return {
      socialSecurityNumber: payload.socialSecurityNumber,
    };
  }

  get isIdle() {
    if (!this.lastConnectedAt) return false;

    // nnkitodo [v2later date] plus réactif
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    return sixMonthsAgo > this.lastConnectedAt;
  }

  static userRoleHierarchy(role: UserRole) {
    switch (role) {
      case UserRole.ROLE_STUDENT:
        return 0;
      case UserRole.ROLE_WRITER:
        return 1;
      case UserRole.ROLE_COURSE_WRITER:
        return 2;
      case UserRole.ROLE_PROOFREADER:
        return 3;
      case UserRole.ROLE_CHIEF_WRITER:
        return 4;
      case UserRole.ROLE_ECNI:
        return 5;
      default:
        return 0;
    }
  }
}

export class BankAccount {
  iban: string;
  bic: string;

  constructor(result: BankAccountResult) {
    this.iban = result.iban;
    this.bic = result.bic;
  }

  toFormValue(): WriterBankAccountFormValue {
    return {
      iban: this.iban,
      bic: this.bic,
    };
  }

  toResult(): BankAccountResult {
    return {
      iban: this.iban,
      bic: this.bic,
    };
  }

  static updatedEntityToResult(
    formValue: Partial<WriterBankAccountFormValue>
  ): Partial<BankAccountResult> {
    return {
      iban: formValue.iban,
      bic: formValue.bic,
    };
  }
}

export class WriterStatistic {
  totalContentToPay: number;
  totalExamContentsProduced: number;
  totalFoldersProduced: number;
  totalIsolatedQuestionsProduced: number;

  totalFeedbacks: number;
  totalAnsweredFeedbacks: number;

  // nnkitodo [v2later] le résultat semble mal calculé
  totalFolderAndExamContentAverageRating: number;

  constructor(result: WriterStatisticResult) {
    this.totalContentToPay = result.totalContentToPay;
    this.totalExamContentsProduced = result.totalExamContentsProduced;
    this.totalFoldersProduced = result.totalFoldersProduced;
    this.totalIsolatedQuestionsProduced = result.totalIsolatedQuestionsProduced;
    this.totalFeedbacks = result.totalFeedbacks;
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    if (result.totalFolderAndExamContentAverageRating)
      this.totalFolderAndExamContentAverageRating = result.totalFolderAndExamContentAverageRating;
  }

  toResult(): WriterStatisticResult {
    return {
      totalContentToPay: this.totalContentToPay,
      totalExamContentsProduced: this.totalExamContentsProduced,
      totalFoldersProduced: this.totalFoldersProduced,
      totalIsolatedQuestionsProduced: this.totalIsolatedQuestionsProduced,
      totalFeedbacks: this.totalFeedbacks,
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFolderAndExamContentAverageRating: this.totalFolderAndExamContentAverageRating,
    };
  }
}
