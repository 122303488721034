import { environment } from '../../../../environments/environment';
import { Country, getCountryName } from '../../constants/countries';
import { StudentResult } from '../api-results/student.api-result';
import { StudentUniversityFormValue, StudentUniversityPayload } from '../payloads/student.payload';
import { GlobalChallengeScore } from './challenge-score.entity';
import { ChallengeSpecialtyScoreBadge } from './challenge-specialty-score-badge.entity';
import { EcniUser } from './ecni-user.entity';
import { Follow } from './follow.entity';
import { GraduationName } from './graduation-name.entity';
import { Subscription } from './subscription.entity';
import { University } from './university.entity';

export class Student extends EcniUser {
  graduationName: GraduationName;
  university: University;

  followedByMe: Follow;
  followingMe: Follow;
  currentSubscriptions: Subscription[];

  // nnkitodo [v2later] eurk
  followIsLoading = false;
  selected: boolean;

  globalChallengeScore: GlobalChallengeScore;
  challengeSpecialtyScoreBadges: ChallengeSpecialtyScoreBadge[];

  constructor(result: StudentResult) {
    super(result);

    if (result.graduationName) {
      this.graduationName = new GraduationName(result.graduationName);
    }
    if (result.university) {
      this.university = new University(result.university);
    }
    if (result.followedByMe) {
      this.followedByMe = new Follow(result.followedByMe);
    }
    if (result.followingMe) {
      this.followingMe = new Follow(result.followingMe);
    }
    if (result.currentSubscriptions) {
      this.currentSubscriptions = result.currentSubscriptions.map((elt) => new Subscription(elt));
    }
    this.selected = result.selected;
  }

  studentToFormValue(): Partial<StudentUniversityFormValue> {
    return {
      universityCountry: this.university.universityCity.universityCountry,
      universityCity: this.university.universityCity,
      university: this.university,
      graduationName: this.graduationName,
    };
  }

  toResult(): StudentResult {
    return {
      ...super.toResult(),
      graduationName: this.graduationName?.toResult(),
      university: this.university?.toResult(),
      followedByMe: this.followedByMe,
      followingMe: this.followingMe,
      currentSubscriptions: this.currentSubscriptions?.map((elt) => elt.toResult()),
      selected: this.selected,
    };
  }

  static studentUpdatedEntityToResult(
    formValue: Partial<StudentUniversityFormValue>
  ): Partial<StudentResult> {
    return {
      graduationName: formValue.graduationName.toResult(),
      university: formValue.university.toResult(),
    };
  }
}
