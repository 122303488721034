import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface WriterConfirmModalData {
  message: string;
}

export interface WriterConfirmModalResult {
  confirm: boolean;
}

@Component({
  selector: 'app-writer-confirm-modal',
  templateUrl: './writer-confirm-modal.component.html',
  styleUrls: ['./writer-confirm-modal.component.scss'],
})
export class WriterConfirmModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<WriterConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: WriterConfirmModalData
  ) {}

  ngOnInit(): void {}

  close(confirm: boolean) {
    const modalResult: WriterConfirmModalResult = { confirm };
    this.dialogRef.close(modalResult);
  }
}
