import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LinkService } from '../../../core/services/link.service';

export interface ModalQuestionImagesApplicationData {
  images: string[];
}

@Component({
  selector: 'app-modal-list-images-application',
  templateUrl: './modal-list-images-application.component.html',
  styleUrls: ['./modal-list-images-application.component.scss'],
})
export class ModalListImagesApplicationComponent implements OnInit {
  images: string[];

  constructor(
    public dialogRef: MatDialogRef<ModalListImagesApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalQuestionImagesApplicationData,
    private linkService: LinkService
  ) {
    this.images = data.images;
  }

  ngOnInit(): void {}

  openPhotoViewer(image: string) {
    this.linkService.openBlank(image);
  }

  close() {
    this.dialogRef.close();
  }
}
