import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  ListWritersResult,
  WriterResult,
} from '../../../shared/models/api-results/writer.api-result';
import { map } from 'rxjs';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import {
  AddWriterPayload,
  PromoteWriterPayload,
  WWriterQueryData,
  WriterAssociateSpecialtyPayload,
  WriterBankAccountPayload,
  WriterRedactionInformationsPayload,
  WriterUpdateRolePayload,
} from '../../../shared/models/payloads/writer.payload';
import { ListEcniUserTypeaheadResult } from '../../../shared/models/api-results/ecni-user.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpWriterService {
  constructor(private http: HttpClient) {}

  getMyWriterProfile() {
    return this.http
      .get(`${environment.api_base_url}/writers/me/my_personal_information_detail`)
      .pipe(map((res) => res as WriterResult));
  }

  wUpdateMyRedactionInformations(payload: WriterRedactionInformationsPayload) {
    return this.http.post(
      `${environment.api_base_url}/writers/me/update_my_redaction_information`,
      payload
    );
  }

  wUpdateMyBankAccount(payload: WriterBankAccountPayload) {
    return this.http.post(`${environment.api_base_url}/users/me/update_my_bank_account`, payload);
  }

  wAssociateSpecialtyToMe(id: string) {
    return this.http.post(
      `${environment.api_base_url}/writers/me/${id}/associate_specialty_to_my_profile`,
      {}
    );
  }

  wDissociateSpecialtyFromMe(id: string) {
    return this.http.post(
      `${environment.api_base_url}/writers/me/${id}/dissociate_specialty_from_my_profile`,
      {}
    );
  }

  wGet(id: string) {
    return this.http
      .get(`${environment.api_base_url}/writers/${id}/personal_information_detail`)
      .pipe(map((res) => res as WriterResult));
  }

  wList(params: WWriterQueryData) {
    return this.http
      .get(`${environment.api_base_url}/users/list_writers_details`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListWritersResult));
  }

  wTypeaheadList(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/users/list_writers`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListEcniUserTypeaheadResult));
  }

  wAdd(payload: AddWriterPayload) {
    return this.http.post(`${environment.api_base_url}/credentials/add_writer_credential`, payload);
  }

  wPromote(id: string, payload: PromoteWriterPayload) {
    return this.http.post(`${environment.api_base_url}/users/${id}/promote_to_writer`, payload);
  }

  wUnlockIdleWriter(id: string) {
    return this.http.post(`${environment.api_base_url}/users/${id}/enable_an_inactive_writer`, {});
  }

  wUpdateWriterRole(id: string, payload: WriterUpdateRolePayload) {
    return this.http.post(`${environment.api_base_url}/users/${id}/change_role_of_writer`, payload);
  }

  wUpdateWriterRedactionInformations(id: string, payload: WriterRedactionInformationsPayload) {
    return this.http.post(
      `${environment.api_base_url}/writers/${id}/update_redaction_information`,
      payload
    );
  }

  wUpdateWriterBankAccount(id: string, payload: WriterBankAccountPayload) {
    return this.http.post(
      `${environment.api_base_url}/writers/${id}/update_writer_bank_account`,
      payload
    );
  }

  wAssociateSpecialtyToWriter(payload: WriterAssociateSpecialtyPayload) {
    return this.http.post(
      `${environment.api_base_url}/writers/associate_specialty_to_writer`,
      payload
    );
  }

  wDissociateSpecialtyFromWriter(payload: WriterAssociateSpecialtyPayload) {
    return this.http.post(
      `${environment.api_base_url}/writers/dissociate_specialty_from_writer`,
      payload
    );
  }
}
