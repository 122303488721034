export function getCountdown(end: Date, inHours?: boolean) {
  const endDate = end.getTime();
  const currentDate = new Date().getTime();

  let delta = Math.abs(currentDate - endDate) / 1000;

  let days = 0;
  if (!inHours) {
    // calculate (and subtract) whole days
    days = Math.floor(delta / 86400);
    delta -= days * 86400;
  }

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60);
  delta -= minutes * 60;
  // what's left is seconds
  const seconds = delta % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}
