import { createAction, props } from '@ngrx/store';
import { LoginResult } from '../../../shared/models/api-results/auth.api-result';
import { ApiError } from '../../../shared/models/api-results/error.api-result';
import { GraduationNameResult } from '../../../shared/models/api-results/graduation-name.api-result';
import { StudentResult } from '../../../shared/models/api-results/student.api-result';
import { UniversityResult } from '../../../shared/models/api-results/university.api-result';
import { WriterResult } from '../../../shared/models/api-results/writer.api-result';
import { SSOProvider } from '../../../shared/models/enums/auth.enums';
import { LoginFormValue } from '../../../shared/models/payloads/auth.payload';
import { StudentUniversityFormValue } from '../../../shared/models/payloads/student.payload';
import {
  UserAddressAndInformationsFormValue,
  UserEmailFormValue,
  UserUsernameFormValue,
} from '../../../shared/models/payloads/user.payload';
import { UpdateUserPictureResult } from '../../../shared/models/api-results/ecni-user.api-result';
import { SpecialtyResult } from '../../../shared/models/api-results/specialty.api-result';
import { Specialty } from '../../../shared/models/entities/specialty.entity';
import { BankAccount } from '../../../shared/models/entities/writer.entity';
import { ContractResult } from '../../../shared/models/api-results/contract.api-result';
import { ListStudentSettingsResult } from '../../../shared/models/api-results/student-setting.api-result';
import { StudentSettingName } from '../../../shared/models/enums/student-settings.enum';
import { StudentSettingPayload } from '../../../shared/models/payloads/student-setting.payload';
import {
  AddSubscriptionResult,
  ListSubscriptionsResult,
  SubscriptionResult,
} from '../../../shared/models/api-results/subscription.api-result';
import { SSOAuthResult, SSOLoginType } from '../../../shared/models/interfaces/sso.interface';
import { Student } from '../../../shared/models/entities/student.entity';

export const findSessionToRecover = createAction('[AuthAction] Find Session to Recover');
export const noSessionToRecover = createAction('[AuthAction] No Session to Recover');
export const recoverSession = createAction('[AuthAction] Recover Session');
export const recoverSessionSucceed = createAction(
  '[AuthAction] Recover Session Succeed',
  props<{
    student: StudentResult;
    studentSettings: ListStudentSettingsResult;
    activeSubscriptions: ListSubscriptionsResult;
  }>()
);

export const login = createAction(
  '[AuthAction] Login',
  props<{ formValue: Partial<LoginFormValue>; rememberMe: boolean }>()
);

export const activateAccount = createAction(
  '[AuthAction] Activate Account',
  props<{ token: string }>()
);

export const SSOLoginWithSDK = createAction(
  '[AuthAction] SSO Login With SDK',
  props<{ provider: SSOProvider }>()
);
export const SSOLoginWithBackend = createAction(
  '[AuthAction] SSO Login With Backend',
  props<{
    provider: SSOProvider;
    result: {
      ssoAuthResult: SSOAuthResult;
      loginType: SSOLoginType;
    };
  }>()
);

export const loginSucceed = createAction(
  '[AuthAction] Login Succeed',
  props<{ result: LoginResult }>()
);

export const loginFailed = createAction('[AuthAction] Login Failed', props<{ error: ApiError }>());

export const getMyStudentProfileAfterLogin = createAction(
  '[AuthAction] Get my Student Profile After Login'
);
export const getMyStudentProfileAfterLoginSucceed = createAction(
  '[AuthAction] Get my Student Profile After Login Succeed',
  props<{
    student: StudentResult;
    studentSettings: ListStudentSettingsResult;
    activeSubscriptions: ListSubscriptionsResult;
  }>()
);

export const savePathToReachAfterLogin = createAction(
  '[AuthAction] Save Path to Reach After Login',
  props<{ path: string }>()
);

export const navigateAfterLoginSucceed = createAction('[AuthAction] Navigate After Login Succeed');

export const fillStudentInformationsAfterSSO = createAction(
  '[AuthAction] Fill Student Informations After SSO'
);

export const fillStudentInformationsAfterSSOSucceed = createAction(
  '[AuthAction] Fill Student Informations After SSO Succeed',
  props<{ result: LoginResult }>()
);

export const refreshToken = createAction(
  '[AuthAction] Refresh Token',
  props<{ refreshToken: string }>()
);
export const refreshTokenSucceed = createAction(
  '[AuthAction] Refresh Token Succeed',
  props<{ result: LoginResult }>()
);
export const refreshTokenFailed = createAction('[AuthAction] Refresh Token Failed');

export const logout = createAction('[AuthAction] Logout');

export const updateMyUsername = createAction(
  '[AuthAction] Update My Username',
  props<{ formValue: Partial<UserUsernameFormValue> }>()
);

export const updateMyEmail = createAction(
  '[AuthAction] Update My Email',
  props<{ formValue: Partial<UserEmailFormValue> }>()
);

export const updateMyUniversity = createAction(
  '[AuthAction] Update My University',
  props<{ formValue: Partial<StudentUniversityFormValue> }>()
);

export const updateMyUniversitySucceed = createAction(
  '[AuthAction] Update My University Succeed',
  props<{ graduationName: GraduationNameResult; university: UniversityResult }>()
);

export const updateMyAddressAndInformations = createAction(
  '[AuthAction] Update My Address and Informations',
  props<{ formValue: Partial<UserAddressAndInformationsFormValue> }>()
);

export const updateMyPicture = createAction(
  '[AuthAction] Update My Picture',
  props<{ result: UpdateUserPictureResult }>()
);

export const updateMyStudentSettingsSucceed = createAction(
  '[AuthAction] Update My StudentSettings Succeed',
  props<{ studentSettingName: StudentSettingName; value: string }>()
);

export const updateMyWriterRedactionInformations = createAction(
  '[AuthAction] Update My Redaction Informations',
  props<{ result: Partial<WriterResult> }>()
);

export const updateMyBankAccount = createAction(
  '[AuthAction] Update My Bank Account',
  props<{ result: Partial<BankAccount> }>()
);

export const associateSpecialtyToMe = createAction(
  '[AuthAction] Associate Specialty To Me',
  props<{ specialty: SpecialtyResult }>()
);

export const dissociateSpecialtyFromMe = createAction(
  '[AuthAction] Dissociate Specialty From Me',
  props<{ specialty: SpecialtyResult }>()
);

export const addContractToMe = createAction(
  '[AuthAction] Add Contract To Me',
  props<{ contract: ContractResult }>()
);

export const deleteContractFromMe = createAction(
  '[AuthAction] Delete Contract From Me',
  props<{ contract: ContractResult }>()
);

export const getMyWriterProfile = createAction('[AuthAction] Get my Writer Profile');

export const getMyWriterProfileSucceed = createAction(
  '[AuthAction] Get my Writer Profile Succeed',
  props<{ result: WriterResult }>()
);

export const getMyWriterProfileFailed = createAction('[AuthAction] Get my Writer Profile Failed');

export const addSubscriptionSucceed = createAction(
  '[AuthAction] addSubscriptionSucceed',
  props<{ result: SubscriptionResult }>()
);

export const updateMyActiveSubscriptions = createAction(
  '[AuthAction] Update My active subscriptions'
);

export const updateMyActiveSubscriptionsSucceed = createAction(
  '[AuthAction] Update My active subscriptions Succeed',
  props<{ activeSubscriptions: ListSubscriptionsResult }>()
);

export const triggerMaintenance = createAction(
  '[AuthAction] Trigger Maintenance',
  props<{ message: string }>()
);

export const impersonateUser = createAction(
  '[AuthAction] Impersonate User',
  props<{ student: StudentResult }>()
);

export const stopImpersonateUser = createAction(
  '[AuthAction] Stop Impersonate User',
  props<{ student: StudentResult }>()
);
