import { EcosSessionResult } from '../api-results/ecos-session.api-result';
import { ContentSessionType } from '../enums/session.enum';
import { ContentSession } from './content-session.entity';
import { EcosAction } from './ecos-correction-grid.entity';
import { ExamContent } from './exam-content.entity';
import { SessionEcosAction } from './session-ecos-action.entity';

export class EcosSession extends ContentSession {
  isCompleted: boolean;
  examContent: ExamContent;

  actions: SessionEcosAction[];
  matchedEcosActions: EcosAction[];

  constructor(result: EcosSessionResult, id: string) {
    super(result);
    this.id = id;
    this.isCompleted = result.isCompleted;

    if (result.examContent) {
      this.examContent = new ExamContent(result.examContent);
    }

    if (result.actions) {
      this.actions = result.actions.map((elt) => new SessionEcosAction(elt));
    }

    if (result.matchedEcosActions) {
      this.matchedEcosActions = result.matchedEcosActions.map((elt) => new EcosAction(elt));

      SessionEcosAction.buildEcosActionsFromCorrectionGrid(this.actions, this.matchedEcosActions);
    }
  }

  get type() {
    return ContentSessionType.ECOS;
  }

  get content() {
    return undefined;
  }

  get contentQuestions() {
    return undefined;
  }

  get maxGrade() {
    return this.examContent?.ecosMaxGrade;
  }

  get studentRate() {
    return this.examContent?.studentRate;
  }

  get courses() {
    return [];
  }

  toResult(): EcosSessionResult {
    return {
      ...super.toResult(),
      isCompleted: this.isCompleted,
      examContent: this.examContent?.toResult(),
      actions: this.actions?.map((elt) => elt.toResult()),
      // nnkitodo [v2later] todo
      matchedEcosActions: undefined,
    };
  }
}
