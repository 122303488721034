export enum StudentSettingName {
  ADDITIONAL_TIME_IN_EXAM = 'ADDITIONAL_TIME_IN_EXAM',
  CHALLENGE_MUSIC_ACTIVATED = 'CHALLENGE_MUSIC_ACTIVATED',
  COLOR_BLIND = 'COLOR_BLIND',
  DISPLAY_ANCRAGE_REVISIONS_POPUP = 'DISPLAY_ANCRAGE_REVISIONS_POPUP',
  DISPLAY_QUALITY_BUTTONS_IN_KNOWLEDGE_ANCHORING = 'DISPLAY_QUALITY_BUTTONS_IN_KNOWLEDGE_ANCHORING',
  FOLLOW_MODE = 'FOLLOW_MODE',
  HIDE_ITEMS_ON_EXAMS_NOT_DONE = 'HIDE_ITEMS_ON_EXAMS_NOT_DONE',
  RANDOM_ANSWERS_ORDER = 'RANDOM_ANSWERS_ORDER',
  SITE_COLOR_SCHEME = 'SITE_COLOR_SCHEME',
}

export enum StudentSettingType {
  BOOLEAN = 'BOOLEAN',
  FOLLOW_MODE = 'FOLLOW_MODE',
  SITE_COLOR_SCHEME = 'SITE_COLOR_SCHEME',
}

export enum StudentSettingFollowMode {
  NEEDS_NO_VALIDATION = 'NEEDS_NO_VALIDATION',
  NEEDS_VALIDATION = 'NEEDS_VALIDATION',
  PRIVATE = 'PRIVATE',
}

export enum StudentSettingSiteColorScheme {
  LIGHT_MODE = 'LIGHT_MODE',
  DARK_MODE = 'DARK_MODE',
  DEFAULT = 'DEFAULT',
}
