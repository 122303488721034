<mat-form-field [floatLabel]="'auto'">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControlName]="controlName"
    [autocomplete]="autocomplete"
    [type]="displayPassword ? 'text' : 'password'"
  />
  <svg-icon
    matSuffix
    (click)="toggleDisplayPassword()"
    [src]="
      displayPassword
        ? 'assets/images/svg-icons/icon-eye-slash.svg'
        : 'assets/images/svg-icons/icon-eye.svg'
    "
    [svgStyle]="{ 'width.px': '20' }"
    class="cursor-pointer"
  ></svg-icon>

  <mat-error
    *ngIf="group.controls[controlName].invalid"
    [innerHTML]="group.controls[controlName].errors | displayFormControlErrors"
    class="error-tooltip"
  ></mat-error>
</mat-form-field>
