<div
  class="w-full sm:w-[250px] lg:w-[320px] mr-0 sm:mr-8 md:mr-16 shrink-0 shadow flex flex-col items-stretch"
>
  <div class="gradient rounded-t px-6 lg:px-16 py-16 grid grid-cols-1 gap-y-10">
    <app-user-sunray
      [student]="student"
      [challengeScore]="globalChallengeScore?.challengeScores"
      [challengeSpecialtyScoreBadges]="challengeSpecialtyScoreBadges"
      [canChangePhoto]="studentId === (loggedStudent$ | async)?.id"
    ></app-user-sunray>

    <div class="h-16">
      <div class="flex space-x-4 text-11" *ngIf="followNumbers">
        <div
          class="badge-followers"
          (click)="
            followNumbers.totalStudentsFollowed > 0
              ? openModalListFollows(ModalListFollowsType.FOLLOWED)
              : null
          "
        >
          <strong>{{ followNumbers.totalStudentsFollowed }}</strong>
          <span>{{ 'twit.abonnements' | translate }}</span>
        </div>

        <div
          class="badge-followers"
          (click)="
            followNumbers.totalStudentsFollowers > 0
              ? openModalListFollows(ModalListFollowsType.FOLLOWERS)
              : null
          "
        >
          <strong>{{ followNumbers.totalStudentsFollowers }}</strong>
          <span>{{ 'twit.abonnes' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="space-y-6">
      <ng-container *ngIf="studentId === (loggedStudent$ | async)?.id">
        <div
          class="btn-nnki btn-nnki-white btn-nnki-100 border-0"
          *ngIf="followNumbers?.totalStudentsFollowersPending"
          [matBadge]="followNumbers.totalStudentsFollowersPending"
          matBadgeOverlap="true"
          matBadgeSize="small"
          (click)="openModalListFollows(ModalListFollowsType.PENDING)"
        >
          {{ 'friend.en_attente' | translate }}
        </div>

        <div
          *ngIf="!followNumbers?.totalStudentsFollowersPending"
          class="btn-nnki btn-nnki-dummy"
        ></div>

        <!-- nnkitodo [v2later] import facebook -->
        <!-- <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon-left btn-nnki-white btn-nnki-100 border-0 text-11 text-facebook"
          *ngIf="!isFacebookImporting"
          (click)="facebookImport()"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-facebook.svg"
            [svgStyle]="{ 'width.px': '21' }"
          ></svg-icon>
          <span>{{ 'settings.facebook_import' | translate }}</span>
        </div>

        <div class="spinner_100_container">
          <app-loader-dots *ngIf="isFacebookImporting"></app-loader-dots>
        </div> -->
      </ng-container>

      <ng-container *ngIf="studentId !== (loggedStudent$ | async)?.id">
        <ng-container *ngIf="student">
          <ng-container *ngIf="!student.followIsLoading">
            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-primary btn-nnki-100"
              *ngIf="!student.followedByMe"
              (click)="followService.follow(student)"
            >
              {{ 'twit.sabonner' | translate }}
            </div>

            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-white btn-nnki-100"
              *ngIf="student.followedByMe?.status === FollowStatus.PENDING"
              (click)="followService.unfollow(student)"
            >
              {{ 'friend.attente' | translate }}
            </div>

            <div
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-white btn-nnki-100"
              *ngIf="student.followedByMe?.status === FollowStatus.APPROVED"
              (click)="followService.unfollow(student)"
            >
              {{ 'twit.se_desabonner' | translate }}
            </div>
          </ng-container>

          <div class="spinner_100_container" *ngIf="student.followIsLoading">
            <app-loader-dots></app-loader-dots>
          </div>
        </ng-container>

        <div *ngIf="!student" class="btn-nnki btn-nnki-dummy"></div>

        <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-purple btn-nnki-icon-left btn-nnki-100 btn-nnki-uppercase btn-nnki-heavy"
          *ngIf="isApplicationService.premiumCanBeDisplayed$ | async"
          (click)="modalService.openModalChallenge(student)"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-challenge.svg"
            [svgStyle]="{ 'width.px': '22' }"
          ></svg-icon>
          {{ 'challenge.defier' | translate }}
        </div>

        <div
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-primary btn-nnki-icon-left btn-nnki-100 btn-nnki-uppercase btn-nnki-heavy"
          (click)="createTemporaryConversation(student)"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-chat.svg"
            [svgStyle]="{ 'width.px': '22' }"
          ></svg-icon>
          {{ 'twit.ecrire' | translate }}
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="grow bg-theme-white rounded-b py-10 px-8 sm:px-16"
    *ngIf="languageService.challengeEnabled"
  >
    <div class="text-16 font-extrabold mb-8">
      {{ 'challenge.palmares' | translate }}
    </div>

    <app-challenge-badges [studentId]="studentId" [inModale]="inModale"></app-challenge-badges>

    <div
      class="text-center text-site-main-one text-12 font-semibold cursor-pointer"
      (click)="openModalChallengeBadgeInfos()"
    >
      {{ 'challenge.plus_palmares' | translate }}
    </div>
  </div>
</div>

<div class="flex flex-col grow rounded bg-theme-white shadow">
  <div
    class="bg-theme-gray-seventeen rounded-t py-12 px-8 sm:px-12 flex items-center justify-between"
  >
    <div class="hidden sm:flex sm:space-x-4">
      <div>
        <div class="relative w-32 h-32">
          <div class="absolute top-0 bottom-0 right-0 left-0">
            <div id="svgProgress-{{ now }}"></div>
          </div>
          <div class="progress-value" *ngIf="globalProgress && applicationStats">
            <strong>{{
              (globalProgress.totalAnsweredQuestions / applicationStats.totalQuestions) * 100
                | number : '1.0-0' : 'fr'
            }}</strong
            >%
          </div>
        </div>
        <div class="progress-label mt-4">
          {{ 'filters.progression' | translate }}
        </div>
      </div>

      <div>
        <div class="relative w-32 h-32">
          <div class="absolute top-0 bottom-0 right-0 left-0">
            <div id="svgSuccess-{{ now }}"></div>
          </div>
          <div class="progress-value" *ngIf="globalProgress">
            <strong>{{ globalProgress.globalSuccessRate * 100 | number : '1.0-0' : 'fr' }}</strong
            >%
          </div>
        </div>
        <div class="progress-label mt-4">
          {{ 'training.reussite' | translate }}
        </div>
      </div>
    </div>

    <div
      class="grow ml-0 sm:ml-8 grid grid-cols-[min-content_1fr_min-content] md:grid-cols-[1fr_1fr_min-content] gap-6 items-center whitespace-nowrap"
    >
      <div class="victory-bar sm:hidden">
        <div class="victory-label">
          {{ 'filters.progression' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div
            class="progress_bar_in -purple"
            [style.width.%]="
              globalProgress && applicationStats
                ? (globalProgress.totalAnsweredQuestions / applicationStats.totalQuestions) * 100
                : 0
            "
          ></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="globalProgress && applicationStats">
            {{
              (globalProgress.totalAnsweredQuestions / applicationStats.totalQuestions) * 100
                | number : '1.0-0' : 'fr'
            }}%
          </ng-container>
        </div>
      </div>

      <div class="victory-bar sm:hidden">
        <div class="victory-label">
          {{ 'training.reussite' | translate }}
        </div>
        <div class="ecni_progress_bar">
          <div
            class="progress_bar_in -blue"
            [style.width.%]="globalProgress ? globalProgress.globalSuccessRate * 100 : 0"
          ></div>
        </div>
        <div class="victory-value">
          <ng-container *ngIf="globalProgress">
            {{ globalProgress.globalSuccessRate * 100 | number : '1.0-0' : 'fr' }}%
          </ng-container>
        </div>
      </div>

      <ng-container
        *ngIf="languageService.challengeEnabled && globalChallengeScore?.challengeScores"
      >
        <div class="victory-bar">
          <div class="victory-label flex">
            <span>{{ 'challenge.victoires' | translate }}</span>
            <svg-icon
              class="ml-4 text-site-main-one shrink-0"
              src="assets/images/svg-icons/icon-question-circle.svg"
              [svgStyle]="{ 'width.px': '14' }"
              i18n-matTooltip
              matTooltip="Le nombre total d'utilisateurs qui ont marqué plus de points que toi sur les défis auxquels tu as participé"
            ></svg-icon>
          </div>
          <div class="ecni_progress_bar">
            <div
              class="progress_bar_in -green"
              [style.width.%]="
                (globalChallengeScore
                  ? globalChallengeScore.challengeScores.victories /
                    (globalChallengeScore.challengeScores.victories +
                      globalChallengeScore.challengeScores.defeats)
                  : 0) * 100
              "
            ></div>
          </div>
          <div class="victory-value">
            <ng-container *ngIf="globalChallengeScore">
              {{
                (globalChallengeScore
                  ? globalChallengeScore.challengeScores.victories /
                    (globalChallengeScore.challengeScores.victories +
                      globalChallengeScore.challengeScores.defeats)
                  : 0) * 100 | number : '1.0-0' : 'fr'
              }}%
              <small
                >({{
                  globalChallengeScore.challengeScores.victories | number : '1.0-0' : 'fr'
                }})</small
              >
            </ng-container>
          </div>
        </div>

        <div class="victory-bar">
          <div class="victory-label flex">
            <span>{{ 'challenge.defaites' | translate }}</span>
            <svg-icon
              class="ml-4 text-site-main-one shrink-0"
              src="assets/images/svg-icons/icon-question-circle.svg"
              [svgStyle]="{ 'width.px': '14' }"
              i18n-matTooltip
              matTooltip="Le nombre total d'utilisateurs qui ont marqué moins de points que toi sur les défis auxquels tu as participé"
            ></svg-icon>
          </div>
          <div class="ecni_progress_bar">
            <div
              class="progress_bar_in -red"
              [style.width.%]="
                (globalChallengeScore
                  ? globalChallengeScore.challengeScores.defeats /
                    (globalChallengeScore.challengeScores.victories +
                      globalChallengeScore.challengeScores.defeats)
                  : 0) * 100
              "
            ></div>
          </div>
          <div class="victory-value">
            <ng-container *ngIf="globalChallengeScore">
              {{
                (globalChallengeScore
                  ? globalChallengeScore.challengeScores.defeats /
                    (globalChallengeScore.challengeScores.victories +
                      globalChallengeScore.challengeScores.defeats)
                  : 0) * 100 | number : '1.0-0' : 'fr'
              }}%
              <small
                >({{
                  globalChallengeScore.challengeScores.defeats | number : '1.0-0' : 'fr'
                }})</small
              >
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="grow bg-theme-white rounded-b overflow-hidden">
    <div class="py-8 px-6 sm:px-16 text-16 font-extrabold">
      {{ 'challenge.actualites' | translate }}
    </div>

    <div class="actualities-list">
      <app-challenge-actualities
        [studentId]="studentId"
        [inModale]="inModale"
      ></app-challenge-actualities>
    </div>
  </div>
</div>
