import { RateResult } from '../api-results/rate.api-result';
import { StudentResult } from '../api-results/student.api-result';
import { Student } from './student.entity';

export class Rate {
  id: string;
  rate: number;
  comment: string;
  owner: Student;

  constructor(result: RateResult) {
    this.id = result.id;
    this.rate = result.rate;
    this.comment = result.comment;

    if (result.owner) {
      this.owner = new Student(result.owner);
    }
  }

  toResult(): RateResult {
    return {
      id: this.id,
      rate: this.rate,
      comment: this.comment,
      owner: this.owner?.toResult(),
    };
  }
}
