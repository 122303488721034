export enum RememberingLevel {
  ZERO = 0,
  FAIL = 1,
  BAD = 2,
  PASS = 3,
  GOOD = 4,
  GREAT = 5,
}

export enum KnowledgeAnchoringPickStrategy {
  DEFAULT = 'DEFAULT',
  ONLY_REVISIONS = 'ONLY_REVISIONS',
  ONLY_NEW = 'ONLY_NEW',
}

export enum KnowledgeAnchoringPlannedRevisions {
  ALL = 'ALL',
  ONLY_LATE = 'ONLY_LATE',
  ONLY_TODAY = 'ONLY_TODAY',
}

export enum KnowledgeAnchoringPauseType {
  UNPLANIFIED = 'UNPLANIFIED',
  PLANIFIED = 'PLANIFIED',
}
