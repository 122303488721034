import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { Student } from '../../../models/entities/student.entity';

@Component({
  selector: 'app-friend-invite',
  templateUrl: './friend-invite.component.html',
  styleUrls: ['./friend-invite.component.scss'],
})
export class FriendInviteComponent implements OnInit {
  @Input() student: Student;

  constructor(public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
