import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface WriterMessageModalData {
  title: string;
  message: string;
}

export interface WriterMessageModalResult {
  confirm: boolean;
}

@Component({
  selector: 'app-writer-message-modal',
  templateUrl: './writer-message-modal.component.html',
  styleUrls: ['./writer-message-modal.component.scss'],
})
export class WriterMessageModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<WriterMessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: WriterMessageModalData
  ) {}

  ngOnInit(): void {}

  close(confirm: boolean) {
    const modalResult: WriterMessageModalResult = { confirm };
    this.dialogRef.close(modalResult);
  }
}
