import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ModalActions } from '../../utils/modal-actions';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { confirmWithTextInputValidator } from '../../validators/validators';

export interface ModalWarningData {
  title: string;
  content: string;
  buttonText: string;
  closeAction?: string;
  routerLink?: string[];
  textToInput?: string;
  isPasswordProtected?: boolean;
}

export interface ModalWarningResult {
  confirm: boolean;
  password: string;
}

@Component({
  selector: 'app-modal-warning',
  templateUrl: './modal-warning.component.html',
  styleUrls: ['./modal-warning.component.scss'],
})
export class ModalWarningComponent implements OnInit {
  confirmForm = this.formBuilder.group({
    text: [''],
    password: [''],
  });

  constructor(
    public dialogRef: MatDialogRef<ModalWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalWarningData,
    private router: Router,
    private formBuilder: NonNullableFormBuilder
  ) {}

  ngOnInit() {
    if (this.data.textToInput) {
      this.confirmForm.controls.text.setValidators([
        Validators.required,
        confirmWithTextInputValidator(this.data.textToInput),
      ]);
      this.confirmForm.controls.text.updateValueAndValidity();
    }
    if (this.data.isPasswordProtected) {
      this.confirmForm.controls.password.setValidators([Validators.required]);
      this.confirmForm.controls.password.updateValueAndValidity();
    }
  }

  close(confirm: boolean) {
    if (!this.confirmForm.valid) {
      return;
    }
    if (!this.data.closeAction) {
      const modalResult: ModalWarningResult = {
        confirm,
        password: this.confirmForm.value.password,
      };
      this.dialogRef.close(modalResult);
    } else {
      if (this.data.closeAction === ModalActions.actionNavigation) {
        this.router.navigate(this.data.routerLink);
        this.dialogRef.close();
      }
    }
  }
}
