<div
  appInfiniteScroll
  (needToScroll)="scroll()"
  [scrollContainer]="inModale ? 'modalUserProfile' : null"
  [scrollContent]="inModale ? 'modalInsideUserProfile' : 'appComponentContent'"
>
  <div class="mb-8">
    <app-error-tooltip
      *ngIf="componentStore.error$ | async as error"
      [error]="error"
    ></app-error-tooltip>

    <ng-container *ngIf="componentStore.entities$ | async as badges">
      <div class="flex items-center mb-6 text-14 font-extrabold" *ngFor="let badge of badges">
        <img
          src="assets/images/svg-illustrations/icon-challenge-{{ badge.level }}.svg"
          class="w-14 mr-3"
        />
        <div class="badge-discipline">{{ badge.specialty.name }}</div>
      </div>
    </ng-container>
  </div>
</div>
