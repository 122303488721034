import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  GraduationNameResult,
  ListGraduationNamesResult,
} from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversityCitiesResult } from '../../../shared/models/api-results/university-city.api-result';
import { ListUniversityCountriesResult } from '../../../shared/models/api-results/university-country.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { UniversityCountryPayload } from '../../../shared/models/payloads/university-country.payload';
import {
  ListExamTypesResult,
  UpdateExamTypeIconResult,
} from '../../../shared/models/api-results/exam-type.api-result';
import {
  AddExamTypePayload,
  UpdateExamTypeIconPayload,
  UpdateExamTypePayload,
} from '../../../shared/models/payloads/exam-type.payload';
import { convertToFormData } from '../../../shared/utils/api-transformations';

@Injectable({
  providedIn: 'root',
})
export class HttpExamTypeService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/exam_types/list_exam_types`)
      .pipe(map((res) => res as ListExamTypesResult));
  }

  wAdd(payload: AddExamTypePayload) {
    return this.http
      .post(`${environment.api_base_url}/exam_types/add_exam_type`, convertToFormData(payload))
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UpdateExamTypePayload) {
    return this.http.post(`${environment.api_base_url}/exam_types/${id}/update_exam_type`, payload);
  }

  wUpdateIcon(id: string, payload: UpdateExamTypeIconPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/exam_types/${id}/update_exam_type_icon`,
        convertToFormData(payload)
      )
      .pipe(map((res) => res as UpdateExamTypeIconResult));
  }
}
