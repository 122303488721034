import { State } from '@ngrx/store';
import { AddedEntity } from '../api-results/base.api-result';
import {
  AddFileStatementContentResult,
  StatementContentResult,
} from '../api-results/statement-content.api-results';
import { StatementContentType } from '../enums/statement-content.enums';
import {
  QuestionStatementContentEmbeddedPayload,
  StatementContentEmbeddedPayload,
  StatementContentFilePayload,
  StatementContentFormValue,
} from '../payloads/statement-content.payload';

export class StatementContent {
  id: string;
  title: string;
  description: string;
  type: StatementContentType;
  file: string;
  embedded: string;

  constructor(result: StatementContentResult) {
    this.id = result.id;
    this.title = result.title;
    this.description = result.description;
    this.type = result.type;
    this.file = result.file;
    this.embedded = result.embedded;
  }

  toFormValue(): StatementContentFormValue {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      type: this.type,
      file: {
        file: null,
        path: this.file,
      },
      embedded: this.embedded,
    };
  }

  toResult(): StatementContentResult {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      type: this.type,
      file: this.file,
      embedded: this.embedded,
    };
  }

  static fileAddedEntityToResult(
    payload: StatementContentFilePayload,
    addedEntity: AddFileStatementContentResult
  ): StatementContentResult {
    return {
      id: addedEntity.id,
      title: payload.title,
      description: payload.description,
      type: StatementContentType.FILE,
      file: addedEntity.file,
      embedded: undefined,
    };
  }

  static embeddedAddedEntityToResult(
    payload: StatementContentEmbeddedPayload,
    addedEntity: AddedEntity
  ): StatementContentResult {
    return {
      id: addedEntity.id,
      title: payload.title,
      description: payload.description,
      type: StatementContentType.EMBEDDED,
      file: undefined,
      embedded: payload.embedded,
    };
  }
}
