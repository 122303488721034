import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  ListConversationsResult,
  TotalUnreadConversationMessagesResult,
} from '../../../shared/models/api-results/conversation.api-result';
import { map } from 'rxjs';
import {
  AddConversationMessagePayload,
  AddConversationPayload,
} from '../../../shared/models/payloads/conversation.payload';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { ListConversationMessagesResult } from '../../../shared/models/api-results/conversation-message.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpConversationService {
  constructor(private http: HttpClient) {}

  list(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/conversations/me/list_conversations`, {
        params: prepareQueryParams(params),
      })
      .pipe(
        // nnkitodo [v2later typage] partout où on a fait du typage dans les entités, faire les cas particuliers dans les http service ?
        map((res) => res as ListConversationsResult)
      );
  }

  add(payload: AddConversationPayload) {
    return this.http
      .post(`${environment.api_base_url}/conversations/add_conversation`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  read(id: string) {
    return this.http.post(`${environment.api_base_url}/conversations/${id}/read_conversation`, {});
  }

  answer(payload: AddConversationMessagePayload) {
    return this.http
      .post(`${environment.api_base_url}/conversation_messages/add_conversation_message`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  leave(id: string) {
    return this.http.post(`${environment.api_base_url}/conversations/${id}/leave_conversation`, {});
  }

  listConversationMessages(id: string, params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/conversation_messages/list_conversation_messages/${id}`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListConversationMessagesResult));
  }

  countTotalUnreadConversationMessages() {
    return this.http
      .get(
        `${environment.api_base_url}/conversation_messages/me/get_total_unread_conversation_messages`
      )
      .pipe(map((res) => res as TotalUnreadConversationMessagesResult));
  }
}
