import { UniversityCityResult } from '../api-results/university-city.api-result';
import {
  UniversityCityFormValue,
  UniversityCityPayload,
} from '../payloads/university-city.payload';
import { UniversityCountry } from './university-country.entity';

export class UniversityCity {
  id: string;
  name: string;
  universityCountry: UniversityCountry;

  constructor(result: UniversityCityResult) {
    this.id = result.id;
    this.name = result.name;

    if (result.country) {
      this.universityCountry = new UniversityCountry(result.country);
    }
  }

  toFormValue(): UniversityCityFormValue {
    return {
      name: this.name,
      country: this.universityCountry,
    };
  }

  toResult(): UniversityCityResult {
    return {
      id: this.id,
      name: this.name,
      country: this.universityCountry?.toResult(),
    };
  }

  static updatedEntityToResult(
    payload: UniversityCityPayload,
    formValue: Partial<UniversityCityFormValue>
  ): Partial<UniversityCityResult> {
    return {
      name: payload.name,
      country: formValue.country.toResult(),
    };
  }
}
