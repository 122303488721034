import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  GraduationNameResult,
  ListGraduationNamesResult,
} from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversityCitiesResult } from '../../../shared/models/api-results/university-city.api-result';
import { ListUniversityCountriesResult } from '../../../shared/models/api-results/university-country.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { UniversityCountryPayload } from '../../../shared/models/payloads/university-country.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpUniversityCountryService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/university_countries/list_university_countries`)
      .pipe(map((res) => res as ListUniversityCountriesResult));
  }

  wAdd(payload: UniversityCountryPayload) {
    return this.http
      .post(`${environment.api_base_url}/university_countries/add_university_country`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UniversityCountryPayload) {
    return this.http.post(
      `${environment.api_base_url}/university_countries/${id}/update_university_country_name`,
      payload
    );
  }
}
