import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import * as AuthActions from '../state/auth-state/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private store: Store) {}

  // nnkitodo [v2later loader] Loader général le temps que le logged area component charge (comprendre pourquoi il met si longtemps)

  load(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(AuthActions.findSessionToRecover());

      this.store
        .select(AuthSelectors.initializing)
        .pipe(filter((initializing) => !initializing))
        .subscribe(
          () => resolve(true),
          () => resolve(true)
        );
    });
  }
}
