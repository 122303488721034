import * as Sentry from '@sentry/angular';

// nnkitodo [v2later] repasser sur ces erreurs
export function filterSentryEvent(event: Sentry.ErrorEvent): Sentry.ErrorEvent {
  if (event.exception && event.exception.values && event.exception.values.length) {
    event.exception.values.forEach((exception) => {
      if (exception.value) {
        const errorText = exception.value;

        if (errorText.search('Non-Error promise rejection captured with value: Timeout') !== -1) {
          event = null;
        }
        if (errorText.search('Timeout has occurred') !== -1) {
          event = null;
        }
        if (errorText.search(`undefined is not an object (evaluating 't.headers.keys')`) !== -1) {
          event = null;
        }
        if (errorText.search(`TypeError: t.disconnect is not a function`) !== -1) {
          event = null;
        }

        if (
          errorText.search('Non-Error exception captured with keys: body, headers, status') !== -1
        ) {
          event = null;
        }
        if (errorText.search('Non-Error exception captured with keys: details, error') !== -1) {
          event = null;
        }
        if (
          errorText.search(
            'Non-Error exception captured with keys: error, headers, message, name, ok'
          ) !== -1
        ) {
          event = null;
        }
        if (errorText.search('Object: {"status":0,"body":{},"headers":{}}') !== -1) {
          event = null;
        }
        if (errorText.search('Object: {"status":504,"body":null,"headers":{}}') !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot read property 'tagName' of null`) !== -1) {
          event = null;
        }

        if (errorText.search('The remote server machine does not exist or is unavailable') !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot read property 'readAt' of undefined`) !== -1) {
          event = null;
        }
        if (errorText.search('NetworkError') !== -1) {
          event = null;
        }
        if (errorText.search('No error message') !== -1) {
          event = null;
        }
        if (errorText.search('DataCloneError: The object can not be cloned.') !== -1) {
          event = null;
        }
        if (
          errorText.search('Argument 1 of Node.contains does not implement interface Node.') !== -1
        ) {
          event = null;
        }

        if (errorText.search('ERR_NO_PHPSESSID_HEADER') !== -1) {
          event = null;
        }

        if (errorText.search('_avast_submit') !== -1) {
          event = null;
        }

        if (errorText.search('ChunkLoadError') !== -1) {
          event = null;
        }

        if (errorText.search(`Unexpected token '<'`) !== -1) {
          event = null;
        }
        if (errorText.search(`expected expression, got '<'`) !== -1) {
          event = null;
        }
        if (errorText.search('Invalid or unexpected token') !== -1) {
          event = null;
        }
        if (errorText.search('Unexpected token <') !== -1) {
          event = null;
        }
        if (errorText.search('Syntax error') !== -1) {
          event = null;
        }
        if (errorText.search('Unexpected identifier') !== -1) {
          event = null;
        }

        if (errorText.search(`find variable: $`) !== -1) {
          event = null;
        }
        if (errorText.search(`'$' is not defined`) !== -1) {
          event = null;
        }
        if (errorText.search('$ is not defined') !== -1) {
          event = null;
        }
        if (errorText.search('gapi is not defined') !== -1) {
          event = null;
        }
        if (errorText.search(`Can't find variable: gapi`) !== -1) {
          event = null;
        }
        if (errorText.search(`Can't find variable: ProgressBar`) !== -1) {
          event = null;
        }
        if (errorText.search('ProgressBar is not defined') !== -1) {
          event = null;
        }
        if (errorText.search('google is not defined') !== -1) {
          event = null;
        }
        if (errorText.search(`'google' is undefined`) !== -1) {
          event = null;
        }
        if (errorText.search(`'google' is not defined`) !== -1) {
          event = null;
        }
        if (errorText.search(`Can't find variable: google`) !== -1) {
          event = null;
        }
        if (errorText.search('Container is not defined') !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot read property 'HTMLIFrameElement' of null`) !== -1) {
          event = null;
        }
        if (errorText.search('SecurityError') !== -1) {
          event = null;
        }
        if (errorText.search('ibPauseAllVideos') !== -1) {
          event = null;
        }
        if (errorText.search('ibFindAllVideos') !== -1) {
          event = null;
        }
        if (errorText.search('Incompatible receiver, Map required') !== -1) {
          event = null;
        }

        if (errorText.search('Zone.js has detected that ZoneAwarePromise') !== -1) {
          event = null;
        }
        if (errorText.search('In this configuration Angular requires Zone.js') !== -1) {
          event = null;
        }
        if (errorText.search('SecurityError: The operation is insecure.') !== -1) {
          event = null;
        }
        if (errorText.search('QuotaExceededError: The quota has been exceeded.') !== -1) {
          event = null;
        }
        if (errorText.search('Stripe is not defined') !== -1) {
          event = null;
        }
        if (errorText.search(`Can't find variable: Stripe`) !== -1) {
          event = null;
        }
        if (errorText.search(`illegal character`) !== -1) {
          event = null;
        }
        if (errorText.search(`evaluating 'localStorage.getItem'`) !== -1) {
          event = null;
        }
        if (errorText.search(`Pusher is not defined`) !== -1) {
          event = null;
        }
        if (errorText.search(`localStorage is null`) !== -1) {
          event = null;
        }
        if (errorText.search(`Failed to read the 'localStorage' property from 'Window'`) !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot set property [HTMLTemplateElement].innerHTML`) !== -1) {
          event = null;
        }
        if (errorText.search(`"google" is not defined`) !== -1) {
          event = null;
        }
        if (errorText.search(`can't access dead object`) !== -1) {
          event = null;
        }
        if (errorText.search(`macroTask 'setTimeout'`) !== -1) {
          event = null;
        }
        if (errorText.search(`Out of memory`) !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot redefine property: googletag`) !== -1) {
          event = null;
        }
        if (errorText.search(`NETWORK_ERR`) !== -1) {
          event = null;
        }
        if (errorText.search(`Invalid scheme`) !== -1) {
          event = null;
        }
        if (errorText.search(`Uncaught (in promise)`) !== -1) {
          event = null;
        }
        if (errorText.search(`UnexpectedResponseException`) !== -1) {
          event = null;
        }
        if (errorText.search(`The browser/environment lacks`) !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot declare a let variable twice`) !== -1) {
          event = null;
        }
        if (errorText.search(`Cannot declare a const variable twice`) !== -1) {
          event = null;
        }
        if (errorText.search(`startCleanup:`) !== -1) {
          event = null;
        }
        if (errorText.search(`Error: startCleanup`) !== -1) {
          event = null;
        }
        if (errorText.search(`Unexpected keyword 'const'`) !== -1) {
          event = null;
        }
        if (errorText.search(`Object: {"offset":`) !== -1) {
          event = null;
        }
        if (errorText.search(`e.prevAll`) !== -1) {
          event = null;
        }
        if (
          errorText.search(
            `Failed to execute 'scrollTo' on 'Window': 2 arguments required, but only 1 present.`
          ) !== -1
        ) {
          event = null;
        }
        if (errorText.search(`Transport destroyed`) !== -1) {
          event = null;
        }
        if (errorText.search(`must be absolutely positioned`) !== -1) {
          event = null;
        }
        if (errorText.search(`getBoundingClientRect`) !== -1) {
          event = null;
        }
        if (errorText.search(`Partial keyframes are not supported`) !== -1) {
          event = null;
        }
        if (errorText.search(`FBERROR: no resLogin received`) !== -1) {
          event = null;
        }
        if (errorText.search(`StatusBar does not have web implementation`) !== -1) {
          event = null;
        }
        if (
          errorText.search(`Cannot read properties of undefined (reading 'nativeElement')`) !== -1
        ) {
          event = null;
        }
        if (
          errorText.search(`undefined is not an object (evaluating 'this.root.nativeElement')`) !==
          -1
        ) {
          event = null;
        }
        if (errorText.search(`Cannot read property 'nativeElement' of undefined`) !== -1) {
          event = null;
        }
        if (
          errorText.search(`Cannot read properties of undefined (reading 'nativeElement')`) !== -1
        ) {
          event = null;
        }
        if (errorText.search(`this.dummyComponentsContainer is undefined`) !== -1) {
          event = null;
        }
        if (errorText.search(`Aucun ami à importer`) !== -1) {
          event = null;
        }
        if (
          errorText.search(
            `Non-Error promise rejection captured with value: Object Not Found Matching Id`
          ) !== -1
        ) {
          event = null;
        }
        if (errorText.search(`Http failure response for`) !== -1) {
          event = null;
        }
        if (errorText.search(`network error`) !== -1) {
          event = null;
        }
        // nnkitodo [v2later] problème sur capacitor ios visiblement, comprendre d'où ça vient et corriger (16k erreurs en qqs jours)
        if (errorText.search(`Error: not implemented`) !== -1) {
          event = null;
        }
        if (errorText.search(`Server returned code 502 with body`) !== -1) {
          event = null;
        }
        if (
          errorText.search(
            `Object captured as exception with keys: error, headers, message, name, ok`
          ) !== -1
        ) {
          event = null;
        }
        if (errorText.search(`Server returned code 504 with body`) !== -1) {
          event = null;
        }
        if (errorText.search(`Notifications are not allowed for this application`) !== -1) {
          event = null;
        }
        if (errorText.search(`Unable to display URL`) !== -1) {
          event = null;
        }
        if (errorText.search(`Unexpected server response (403) while retrieving PDF`) !== -1) {
          event = null;
        }
      }
    });
  }

  return event;
}
