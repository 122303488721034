export interface SSOAuthResult {
  authResponse: {
    userID: string;
    accessToken: string;
  };
}

export enum SSOLoginType {
  DEFAULT = 'DEFAULT',
  LIMITED = 'LIMITED',
}
