import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ListStudentSettingsResult } from '../../../shared/models/api-results/student-setting.api-result';
import { StudentSettingPayload } from '../../../shared/models/payloads/student-setting.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpStudentSettingService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/me/list_student_settings`)
      .pipe(map((res) => res as ListStudentSettingsResult));
  }

  update(id: string, payload: StudentSettingPayload) {
    return this.http.post(`${environment.api_base_url}/me/${id}/update_student_setting`, payload);
  }
}
