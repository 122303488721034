<div class="modal_content">
  <div class="modal-inside bg-theme-white">
    <div class="modal-circles">
      <div class="background -red"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <div class="circle-3"></div>

      <img src="assets/images/illustrations/warning.png" alt="" />
    </div>

    <div class="modal-text">
      <div class="modal-picto -picto-svg -red">
        <svg-icon
          src="assets/images/svg-icons/icon-warning.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title -red">
        {{ 'maintenance.titre' | translate }}
      </div>

      <div class="modal-theme">
        <p>
          <ng-container *ngIf="!data.message">
            {{ 'maintenance.text' | translate }}
          </ng-container>
          <ng-container *ngIf="data.message">
            {{ data.message }}
          </ng-container>
        </p>
      </div>
    </div>
  </div>
</div>
