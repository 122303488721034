import { Pipe, PipeTransform } from '@angular/core';
import { Offer } from '../models/entities/offer.entity';
import { SubscriptionTier } from '../models/enums/subscription.enums';

@Pipe({
  name: 'offersByTier',
})
export class OffersByTierPipe implements PipeTransform {
  transform(offers: Offer[]): {
    [SubscriptionTier.PREMIUM_PLUS]: Offer[];
    [SubscriptionTier.ELITE]: Offer[];
    // nnkitodo [v2later ecos]
    // [SubscriptionTier.ECOS]: Offer[];
  } {
    const offersByTier = {
      [SubscriptionTier.PREMIUM_PLUS]: [],
      [SubscriptionTier.ELITE]: [],
      // nnkitodo [v2later ecos]
      // [SubscriptionTier.ECOS]: [],
    };
    if (!offers) return undefined;
    offers
      .sort((a, b) => (a.price < b.price ? -1 : 1))
      .forEach((offer) => offersByTier[offer.tier].push(offer));
    return offersByTier;
  }
}
