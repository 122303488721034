import { Injectable } from '@angular/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Observable } from 'rxjs';
import { get } from 'scriptjs';
import { environment } from '../../../environments/environment';
import { IsApplicationService } from './is-application.service';
import { EncryptService } from './encrypt.service';
import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { SSOAuthResult, SSOLoginType } from '../../shared/models/interfaces/sso.interface';

@Injectable({
  providedIn: 'root',
})
export class SSOAppleService {
  constructor(
    private encryptService: EncryptService,
    private isApplicationService: IsApplicationService
  ) {}

  login(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    if (this.isApplicationService.isApplication()) {
      return this.loginFromApplication();
    } else {
      return this.loginFromDesktop();
    }
  }

  loginFromApplication(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    return new Observable((observer) => {
      const payload = {
        clientId: environment.appstore_service_id,
        redirectURI: `${environment.apple_redirect_uri}`,
        scopes: 'name email',
        state: this.encryptService.encrypt(new Date().getTime()),
        nonce: this.encryptService.encrypt(new Date().getTime()),
      };
      SignInWithApple.authorize(payload)
        .then((result: SignInWithAppleResponse) => {
          if (result?.response?.authorizationCode) {
            observer.next({
              ssoAuthResult: {
                authResponse: {
                  accessToken: result.response.authorizationCode,
                  userID: undefined,
                },
              },
              loginType: SSOLoginType.DEFAULT,
            });
          } else {
            observer.error($localize`errAPPLECO2-Erreur lors de la connexion`);
          }
        })
        .catch((error) => {
          console.error(error);
          observer.error($localize`errAPPLECO1-Erreur lors de la connexion`);
        });
    });
  }

  loginFromDesktop(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    return new Observable((observer) => {
      get(
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/fr_FR/appleid.auth.js',
        () => {
          AppleID.auth.init({
            clientId: environment.appstore_service_id,
            scope: 'name email',
            redirectURI: `${environment.apple_redirect_uri}`,
            state: this.encryptService.encrypt(new Date().getTime()),
            nonce: this.encryptService.encrypt(new Date().getTime()),
            usePopup: true,
          });
          try {
            AppleID.auth.signIn().then(
              (data) => {
                observer.next({
                  ssoAuthResult: {
                    authResponse: {
                      accessToken: data.authorization.code,
                      userID: undefined,
                    },
                  },
                  loginType: SSOLoginType.DEFAULT,
                });
              },
              (error) => {
                console.error(error);
                observer.error($localize`errAPPLECO2-Erreur lors de la connexion`);
              }
            );
          } catch (error) {
            console.error(error);
            observer.error($localize`errAPPLECO3-Erreur lors de la connexion`);
          }
        }
      );
    });
  }
}
