import { ChallengeInvitationResult } from '../api-results/challenge-invitation.api-result';
import { ChallengeInvitationStatus } from '../enums/challenge-invitation.enum';
import { Challenge } from './challenge.entity';
import { Student } from './student.entity';

export class ChallengeInvitation {
  student: Student;
  challenge: Challenge;
  status: ChallengeInvitationStatus;

  isAnswering = false;

  constructor(result: ChallengeInvitationResult) {
    if (result.student) this.student = new Student(result.student);
    if (result.challenge) this.challenge = new Challenge(result.challenge);
    this.status = result.status;
  }

  get students() {
    return [this.challenge.author];
  }
}
