export enum QuestionAnswerMethod {
  ONE = 'ONE',
  TCS = 'TCS',
  MANY_UNDEFINED = 'MANY_UNDEFINED',
  MANY_DEFINED_SHORT = 'MANY_DEFINED_SHORT',
  MANY_DEFINED_LONG = 'MANY_DEFINED_LONG',
  MATCH = 'MATCH',
  MATCHING_AREA = 'MATCHING_AREA',
}

export enum QuestionType {
  FOLDER = 'FOLDER',
  ISOLATED = 'ISOLATED',
}
