<ng-container *ngIf="(appIsInitializing$ | async) === false; else appInitializing">
  <div
    class="transition-opacity"
    [ngClass]="{ 'opacity-0 height-0 overflow-hidden': appIsLoading }"
    id="appComponentContent"
  >
    <router-outlet></router-outlet>
  </div>

  <div
    *ngIf="cookiesService.showCookies"
    class="fixed z-cookies top-0 bottom-0 left-0 right-0 bg-gray-twentyfive"
  >
    <div
      class="absolute bottom-0 left-0 right-0 bg-theme-white shadow pb-safeBottom pr-safeIntercom"
    >
      <div class="container--landing grid gap-8 grid-cols-1 sm:grid-cols-[auto_220px] py-16">
        <div>
          <div class="mb-12">
            <app-site-logo></app-site-logo>
          </div>
          <p class="text-18 min-h-[90px]" [innerHTML]="'rgpd.cookies' | translate"></p>
        </div>

        <div class="flex flex-col justify-start h-full">
          <button
            matRipple
            [matRippleCentered]="false"
            [matRippleUnbounded]="true"
            [matRippleRadius]="50"
            class="btn-nnki btn-nnki-primary"
            (click)="cookiesService.acceptCookies()"
          >
            {{ 'friend.accepter' | translate }}
          </button>

          <a
            matRipple
            [matRippleCentered]="false"
            [matRippleUnbounded]="true"
            [matRippleRadius]="50"
            class="btn-nnki btn-nnki-white mt-8"
            [routerLink]="['/legal/confidentiality']"
            >{{ 'rgpd.savoir' | translate }}</a
          >

          <div
            class="text-12 ml-auto mt-8 sm:mt-auto cursor-pointer"
            (click)="cookiesService.refuseCookies()"
          >
            {{ 'rgpd.necessaires' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #appInitializing>
  <div class="spinner_element_container">
    <app-loader-hashtag></app-loader-hashtag>
  </div>
</ng-template>

<div
  *ngIf="(internetConnectionService.isConnected$ | async) === false"
  class="fixed z-above left-0 right-safeIntercom bottom-safeBottom p-4 cursor-pointer"
  (click)="internetConnectionService.closeAlert()"
>
  <div class="relative rounded bg-red-one text-white text-center font-bold p-4 pr-12">
    <div class="absolute top-0 right-0 p-3">
      <svg-icon
        src="assets/images/svg-icons/icon-times-light.svg"
        [svgStyle]="{ 'width.px': '14' }"
      ></svg-icon>
    </div>
    <p>{{ 'error.internet' | translate }}</p>
  </div>
</div>

<div
  *ngIf="impersonateUser$ | async as impersonateUser"
  class="fixed z-above right-safeIntercom bottom-safeBottom p-4 cursor-pointer"
>
  <div class="relative rounded bg-red-one text-white text-center font-bold p-4 classic-grid">
    <p i18n>Connecté en tant que {{ impersonateUser.username }}</p>
    <app-button
      i18n-label
      label="Annuler"
      backgroundClass="bg-white"
      textClass="text-red-one"
      (click)="stopImpersonateUser()"
    ></app-button>
  </div>
</div>
