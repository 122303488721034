<span class="text" [class.-finished]="remainingSeconds <= 0">
  <ng-container *ngIf="remainingSeconds > 0">
    {{ 'examens.il_reste' | translate }}
    <span class="sm:hidden">
      {{ ceiledCountdown | number : '0.0-0' : 'fr' }}
      <ng-container *ngIf="remainingSeconds <= 60">
        {{ 'examens.min' | translate }}
      </ng-container>
      <ng-container *ngIf="remainingSeconds > 60">
        {{ 'examens.mins' | translate }}
      </ng-container>
    </span>
  </ng-container>
  <ng-container *ngIf="remainingSeconds <= 0">
    {{ 'training.temps_ecoule2' | translate }}
  </ng-container>
</span>
<div class="ecni_progress_bar">
  <div
    class="progress_bar_in"
    [class.-green]="remainingSeconds > 0"
    [class.-red]="remainingSeconds <= 0"
    [style.width.%]="percentTimeElapsed"
  ></div>
</div>
<span class="text hidden sm:block">
  <ng-container *ngIf="remainingSeconds > 0">
    {{ ceiledCountdown | number : '0.0-0' : 'fr' }}
    <ng-container *ngIf="remainingSeconds <= 60">
      {{ 'examens.min' | translate }}
    </ng-container>
    <ng-container *ngIf="remainingSeconds > 60">
      {{ 'examens.mins' | translate }}
    </ng-container>
  </ng-container>
</span>
