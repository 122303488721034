import { ChallengeSpecialtyScoreBadgeResult } from '../../../models/api-results/challenge-specialty-score-badge.api-result';
import { ChallengeSpecialtyScoreBadge } from '../../../models/entities/challenge-specialty-score-badge.entity';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, withLatestFrom, concatMap, iif } from 'rxjs';
import { ErrorService } from '../../../../core/services/error.service';
import { HttpChallengeSpecialtyScoreBadgeService } from '../../../../core/services/http/http-challenge-specialty-score-badge.service';
import {
  ListState,
  listInitialState,
  AbstractListComponentStore,
} from '../../../../core/state/base-component-stores/abstract-list.component-store';
import { FiltersFormValue, QueryData } from '../../../models/payloads/query-data.payload';

export interface ChallengeBadgesState extends ListState<ChallengeSpecialtyScoreBadgeResult> {
  studentId: string;
}

export const initialState: ChallengeBadgesState = {
  ...listInitialState,
  studentId: undefined,
  filtersFormValue: {
    ...listInitialState.filtersFormValue,
    // nnkitodo [v2later] système scroll plus propre (problème car 2 scrolls concurrents sur cette page)
    perPage: 100,
  },
};

@Injectable()
export class ChallengeBadgesStoreService extends AbstractListComponentStore<ChallengeBadgesState> {
  constructor(
    private store: Store,
    private errorService: ErrorService,
    private httpChallengeSpecialtyScoreBadgeService: HttpChallengeSpecialtyScoreBadgeService
  ) {
    super(initialState);
  }

  // SELECTORS

  entities$ = this.select((state) =>
    state.entities['hydra:member'].map((elt) => new ChallengeSpecialtyScoreBadge(elt))
  );
  studentId$ = this.select((state) => state.studentId);

  // ACTIONS

  init = this.updater((state, studentId: string) => {
    return {
      ...state,
      studentId,
    };
  });

  // EFFECTS

  listApiCall(filtersFormValue: FiltersFormValue) {
    return this.studentId$.pipe(
      filter((studentId) => !!studentId),
      take(1),
      withLatestFrom(this.store.select(AuthSelectors.loggedStudent)),
      concatMap(([studentId, loggedStudent]) =>
        iif(
          () => studentId === loggedStudent.id,
          this.httpChallengeSpecialtyScoreBadgeService.listMyChallengeSpecialtyScoreBadges(
            new QueryData(filtersFormValue, true)
          ),
          this.httpChallengeSpecialtyScoreBadgeService.listChallengeSpecialtyScoreBadgesOfStudent(
            studentId,
            new QueryData(filtersFormValue, true)
          )
        )
      )
    );
  }
}
