import { Injectable } from '@angular/core';
import {
  AbstractBaseComponentStore,
  BaseState,
  baseInitialState,
} from './abstract-base.component-store';
import { LoadingState } from '../../../shared/models/interfaces/call-state.interface';
import { FiltersFormValue, QueryData } from '../../../shared/models/payloads/query-data.payload';
import {
  ApiError,
  convertHttpErrorToApiError,
} from '../../../shared/models/api-results/error.api-result';
import {
  EMPTY,
  Observable,
  Subscription,
  debounceTime,
  filter,
  map,
  pipe,
  skip,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { tapResponse } from '@ngrx/component-store';
import { ListResult } from '../../../shared/models/api-results/base.api-result';

export interface ListState<T> extends BaseState {
  filtersFormValue: FiltersFormValue;
  entities: ListResult<T>;
  lastResponseLength: number;
}

export const listInitialState: ListState<any> = {
  ...baseInitialState,
  filtersFormValue: {
    page: 1,
    perPage: 20,
  },
  entities: {
    'hydra:member': [],
    'hydra:totalItems': undefined,
  },
  lastResponseLength: undefined,
};

@Injectable()
export abstract class AbstractListComponentStore<
  T extends ListState<any>
> extends AbstractBaseComponentStore<T> {
  // SELECTORS

  filtersFormValue$ = this.select((state) => state.filtersFormValue);
  abstract entities$: Observable<any[]>;

  totalItems$ = this.select((state) => state.entities?.['hydra:totalItems']);

  scrolling$ = this.select((state) => state.callState === LoadingState.SCROLLING);
  canScroll$ = this.select((state) => {
    if (state.callState === LoadingState.LOADING || state.callState === LoadingState.SCROLLING) {
      return false;
    }
    return state.lastResponseLength === state.filtersFormValue.perPage;
  });

  isFinishedScrolling$ = this.select((state) => {
    return state.lastResponseLength < state.filtersFormValue.perPage;
  });

  // ACTIONS

  initList = this.updater((state, filters: Partial<FiltersFormValue>) => {
    return {
      ...state,
      callState: LoadingState.LOADING,
      filtersFormValue: {
        ...state.filtersFormValue,
        ...filters,
        page: 1,
      },
      entities: {
        'hydra:member': [],
        'hydra:totalItems': undefined,
      },
      lastResponseLength: undefined,
    };
  });

  initScroll = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.SCROLLING,
      filtersFormValue: {
        ...state.filtersFormValue,
        page: state.filtersFormValue.page + 1,
      },
    };
  });

  listSucceed = this.updater((state, result: ListResult<T>) => {
    return {
      ...state,
      callState: LoadingState.LOADED,
      entities: {
        ...state.entities,
        'hydra:member': [...state.entities['hydra:member'], ...result['hydra:member']],
        'hydra:totalItems': result['hydra:totalItems'],
      },
      lastResponseLength: result['hydra:member'].length,
    };
  });

  // EFFECTS

  scroll = this.effect<void>(
    pipe(
      withLatestFrom(this.canScroll$),
      map(([empty, canScroll]) => {
        if (canScroll) {
          this.initScroll();
        } else {
          return EMPTY;
        }
      })
    )
  );

  list = this.effect((_) => {
    return this.filtersFormValue$.pipe(
      skip(1),
      debounceTime(500),
      switchMap((filtersFormValue) => this.handleList(filtersFormValue))
    );
  });

  updateList = this.effect<void>(
    pipe(
      tap(() => this.initLoad()),
      withLatestFrom(this.filtersFormValue$),
      switchMap(([empty, filtersFormValue]) => this.handleList(filtersFormValue))
    )
  );

  handleList(filtersFormValue: FiltersFormValue) {
    return this.listApiCall(filtersFormValue).pipe(
      tapResponse(
        (entities) => {
          this.listSucceed(entities);
        },
        (error) => {
          this.failed(convertHttpErrorToApiError(error));
        }
      )
    );
  }

  abstract listApiCall(filtersFormValue: FiltersFormValue): Observable<ListResult<any>>;
}
