import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import {
  AddPasswordPayload,
  CheckAvailableEmailPayload,
  CheckAvailableUsernamePayload,
  DeleteAccountPayload,
  DeleteSSOCredentialPayload,
  LoginPayload,
  RefreshTokenPayload,
  RegisterStudentWithEmailPayload,
  RegisterStudentWithSSOPayload,
  RegisterWriterPayload,
  RequestPasswordResetPayload,
  ResetPasswordPayload,
  SSOAppleLoginPayload,
  SSODefaultLoginPayload,
  SSOFacebookLimitedLoginPayload,
  UpdatePasswordPayload,
} from '../../../shared/models/payloads/auth.payload';
import {
  CheckAvailableCredentialResult,
  HasPasswordResult,
  ListSSOProvidersResult,
  LoginResult,
} from '../../../shared/models/api-results/auth.api-result';
import { SSOProvider } from '../../../shared/models/enums/auth.enums';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthService {
  public refreshAuthTokenEndpoint = `/token/refresh`;

  constructor(private http: HttpClient) {}

  login(payload: LoginPayload) {
    return this.http
      .post(`${environment.api_base_url}/token`, payload)
      .pipe(map((res) => res as LoginResult));
  }

  SSODefaultLogin(provider: SSOProvider, payload: SSODefaultLoginPayload) {
    return this.http
      .get(`${environment.api_base_url}/sso/sso_authentication/${provider}`, {
        params: payload as any,
      })
      .pipe(map((res) => res as LoginResult));
  }

  SSOAppleLogin(payload: SSOAppleLoginPayload) {
    return this.http
      .get(`${environment.api_base_url}/sso/sso_authentication/apple`, {
        params: payload as any,
      })
      .pipe(map((res) => res as LoginResult));
  }

  SSOFacebookLimitedLogin(payload: SSOFacebookLimitedLoginPayload) {
    return this.http
      .get(`${environment.api_base_url}/sso/sso_authentication/facebook_limited_login`, {
        params: payload as any,
      })
      .pipe(map((res) => res as LoginResult));
  }

  refreshAuthToken(payload: RefreshTokenPayload) {
    return this.http
      .post(`${environment.api_base_url}${this.refreshAuthTokenEndpoint}`, payload)
      .pipe(map((res) => res as LoginResult));
  }

  checkAvailableEmail(payload: CheckAvailableEmailPayload) {
    return this.http
      .get(`${environment.api_base_url}/credentials/check_available_email`, {
        params: payload as any,
      })
      .pipe(map((res) => res as CheckAvailableCredentialResult));
  }

  checkAvailableUsername(payload: CheckAvailableUsernamePayload) {
    return this.http
      .get(`${environment.api_base_url}/credentials/check_available_username`, {
        params: payload as any,
      })
      .pipe(map((res) => res as CheckAvailableCredentialResult));
  }

  registerWithEmail(payload: RegisterStudentWithEmailPayload) {
    return this.http.post(`${environment.api_base_url}/users/add_student_account`, payload);
  }

  registerWithSSO(payload: RegisterStudentWithSSOPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/users/student_fills_personal_information_from_sso`,
        payload
      )
      .pipe(map((res) => res as LoginResult));
  }

  registerWriter(payload: RegisterWriterPayload) {
    return this.http.post(`${environment.api_base_url}/users/add_writer_account`, payload);
  }

  activateAccount(token: string) {
    return this.http
      .get(`${environment.api_base_url}/users/activate_student_account`, { params: { token } })
      .pipe(map((res) => res as LoginResult));
  }

  hasPassword() {
    return this.http
      .get(`${environment.api_base_url}/credentials/me/credential_has_password`)
      .pipe(map((res) => res as HasPasswordResult));
  }

  addPassword(payload: AddPasswordPayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/me/add_my_credential_password`,
      payload
    );
  }

  updatePassword(payload: UpdatePasswordPayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/me/update_credential_password`,
      payload
    );
  }

  listSSOProviders() {
    return this.http
      .get(`${environment.api_base_url}/sso/me/list_available_sso_providers`)
      .pipe(map((res) => res as ListSSOProvidersResult));
  }

  deleteSSOProvider(payload: DeleteSSOCredentialPayload) {
    return this.http.post(`${environment.api_base_url}/sso/me/delete_sso_credential`, payload);
  }

  deleteAccount(payload: DeleteAccountPayload) {
    return this.http.post(`${environment.api_base_url}/credentials/me/delete_my_account`, payload);
  }

  requestPasswordReset(payload: RequestPasswordResetPayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/request_credential_password_change`,
      payload
    );
  }

  resetPassword(payload: ResetPasswordPayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/change_credential_password`,
      payload
    );
  }
}
