<div
  appInfiniteScroll
  (needToScroll)="scroll()"
  [scrollContainer]="inModale ? 'modalUserProfile' : null"
  [scrollContent]="inModale ? 'modalInsideUserProfile' : 'appComponentContent'"
>
  <app-error-tooltip
    *ngIf="componentStore.error$ | async as error"
    [error]="error"
  ></app-error-tooltip>

  <ng-container *ngIf="componentStore.entities$ | async as studentActivities">
    <ng-container *ngIf="(componentStore.loaded$ | async) && !studentActivities.length">
      <div class="p-8">
        {{ 'consensus.pas_resultats' | translate }}
      </div>
    </ng-container>

    <div class="grid grid-cols-[auto_1fr_auto_auto]">
      <ng-container *ngFor="let studentActivity of studentActivities">
        <div class="pl-6 sm:pl-16">
          <div
            class="nnki-rounded-background-image w-16"
            [style.background-image]="
              sanitizerService.imageProfile(studentActivity.student?.picture)
            "
          ></div>
        </div>

        <div>
          <div class="text-theme-gray-five text-12 font-semibold mb-2">
            {{ studentActivity.createdAt | amTimeAgo }}
          </div>

          <div
            class="text-14 font-semibold"
            (click)="redirectToContent(studentActivity)"
            [class.cursor-pointer]="studentActivity.type === StudentActivityType.EXAM_CONTENT"
          >
            <p [innerHTML]="studentActivity.content"></p>
          </div>
        </div>

        <div class="space-x-4 flex-row items-center justify-end">
          <img
            *ngIf="studentActivity.type === StudentActivityType.BADGE"
            src="assets/images/svg-illustrations/icon-challenge-{{
              studentActivity.badgeLevel
            }}.svg"
            class="h-[32px]"
          />
          <svg-icon
            *ngIf="studentActivity.type === StudentActivityType.VICTORY"
            src="assets/images/svg-illustrations/icon-trophy.svg"
            [svgStyle]="{ 'width.px': '25' }"
          ></svg-icon>
          <img
            *ngIf="studentActivity.specialty"
            [src]="studentActivity.specialty.icon"
            class="h-[32px] w-[32px] rounded-full"
          />
        </div>

        <div
          class="pr-6 sm:pr-16 flex-row items-center justify-end cursor-pointer"
          (click)="toggleLike(studentActivity)"
        >
          <ng-container *ngIf="!studentActivity.loadingLike">
            <span
              class="text-14 font-extrabold mr-3"
              *ngIf="studentActivity.totalStudentLikedActivity"
            >
              {{ studentActivity?.totalStudentLikedActivity }}
            </span>
            <svg-icon
              [class.text-red-one]="studentActivity.isLikedByMe"
              src="assets/images/svg-icons/{{
                studentActivity.isLikedByMe ? 'icon-heart-solid' : 'icon-heart-stroke'
              }}.svg"
              [svgStyle]="{ 'width.px': '23' }"
            ></svg-icon>
          </ng-container>

          <div *ngIf="studentActivity.loadingLike">
            <app-loader-dots></app-loader-dots>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <app-loader-infinite-scroll
    [loading$]="componentStore.loading$"
    [scrolling$]="componentStore.scrolling$"
  ></app-loader-infinite-scroll>
</div>
