<div appPanel (closePanelEmitter)="close.emit()"></div>

<div
  class="relative shadow w-full max-w-full sm:pt-20"
  [ngClass]="{
    'sm:w-[465px]': widthType === 'M',
    'sm:w-[740px]': widthType === 'L',
    'sm:w-[1100px]': widthType === 'XL'
  }"
>
  <!-- nnkitodo [v2later] attention quand on utilisera ça sur l'app : ça fait 2 h-bottom (un en css et un en html) -->
  <button
    mat-dialog-close
    class="cursor-pointer fixed sm:absolute top-safeTop sm:top-0 right-0 p-4 z-2"
    [ngClass]="{ 'text-theme-black sm:text-white': isWriterArea, 'text-white': !isWriterArea }"
  >
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div
    class="sm:rounded overflow-hidden min-h-screen sm:min-h-0 flex sm:block flex-col justify-start bg-theme-white w-screen sm:w-auto pb-safeBottom sm:pb-0 *:w-full *:sm:w-auto"
  >
    <div *ngIf="!isWriterArea" class="h-[114px] relative">
      <div
        class="absolute w-[150%] top-0 bottom-0 rounded-b-full -left-1/4 circles-{{ color }}"
        [ngClass]=""
      ></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <div class="circle-3"></div>
    </div>

    <div
      class="relative classic-grid justify-items-center p-4 sm:p-8"
      [class.-mt-12]="!isWriterArea"
    >
      <div
        *ngIf="!isWriterArea"
        class="border-[3px] border-theme-white shadow rounded-full overflow-hidden h-[64px] w-[64px] text-white flex items-center justify-center picto-{{
          color
        }}"
      >
        <!-- nnkitodo [v2later] icon pour quand on utilisera ce composant sur l'interface student -->
        <svg-icon
          src="assets/images/svg-icons/icon-notifications.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div *ngIf="isWriterArea" class="h-[20px] sm:hidden"></div>

      <div class="text-20 font-semibold text-center text-{{ color }}">
        <ng-content select="[modalTitle]"></ng-content>
      </div>

      <div class="w-full text-left">
        <ng-content></ng-content>
      </div>

      <div
        class="fixed sm:static bottom-0 left-0 right-0 shadow sm:shadow-none z-2 w-full pb-4 sm:pb-0"
      >
        <div
          [ngClass]="{
            'grid-cols-[repeat(auto-fit,minmax(200px,1fr))]': widthType === 'M',
            'grid-cols-[repeat(auto-fit,200px)] justify-end': widthType !== 'M'
          }"
          class="grid gap-4"
        >
          <ng-content select="[modalButtons]"></ng-content>
        </div>

        <!-- nnkitodo [v2later] attention quand on utilisera ça sur l'app : ça fait 2 h-bottom (un en css et un en html) -->
        <div class="h-safeBottom sm:hidden"></div>
      </div>

      <div class="h-[50px] sm:hidden"></div>
    </div>
  </div>
</div>
