import { Injectable } from '@angular/core';
import {
  CallState,
  LoadingState,
  getCallStateError,
} from '../../../shared/models/interfaces/call-state.interface';
import { ComponentStore } from '@ngrx/component-store';
import { ApiError } from '../../../shared/models/api-results/error.api-result';
import { ErrorService } from '../../services/error.service';

export interface BaseState {
  callState: CallState;
  updatePropertyCallState: CallState[];
}

export const baseInitialState: BaseState = {
  callState: LoadingState.INIT,
  updatePropertyCallState: [],
};

@Injectable()
export abstract class AbstractBaseComponentStore<T extends BaseState> extends ComponentStore<T> {
  // SELECTORS

  loading$ = this.select((state) => state.callState === LoadingState.LOADING);
  loaded$ = this.select((state) => state.callState === LoadingState.LOADED);
  error$ = this.select((state) => getCallStateError(state.callState));

  updatePropertyLoading$(property: string) {
    return this.select((state) => state.updatePropertyCallState[property] === LoadingState.LOADING);
  }

  updatePropertyLoaded$(property: string) {
    return this.select((state) => state.updatePropertyCallState[property] === LoadingState.LOADED);
  }

  updatePropertyError$(property: string) {
    return this.select((state) => getCallStateError(state.updatePropertyCallState[property]));
  }

  // ACTIONS

  initLoad = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.LOADING,
    };
  });

  succeed = this.updater((state) => {
    return {
      ...state,
      callState: LoadingState.LOADED,
    };
  });

  failed = this.updater((state, error: ApiError) => {
    return {
      ...state,
      callState: { error },
    };
  });

  initUpdateProperty = this.updater((state, property: string) => {
    return {
      ...state,
      updatePropertyCallState: {
        ...state.updatePropertyCallState,
        [property]: LoadingState.LOADING,
      },
    };
  });

  updatePropertySucceed = this.updater((state, property: string) => {
    return {
      ...state,
      updatePropertyCallState: {
        ...state.updatePropertyCallState,
        [property]: LoadingState.LOADED,
      },
    };
  });

  updatePropertyFailed = this.updater(
    (state, updateObjectFailed: { property: string; error: ApiError }) => {
      return {
        ...state,
        updatePropertyCallState: {
          ...state.updatePropertyCallState,
          [updateObjectFailed.property]: { error: updateObjectFailed.error },
        },
      };
    }
  );

  resetUpdateProperty = this.updater((state, property: string) => {
    return {
      ...state,
      updatePropertyCallState: {
        ...state.updatePropertyCallState,
        [property]: LoadingState.INIT,
      },
    };
  });
}
