import { FiltersFormValue, QueryData } from './query-data.payload';
import { FileFormValue } from './file.payload';

export interface SpecialtyFormValue {
  name: string;
  icon: FileFormValue;
}

export class UpdateSpecialtyPayload {
  name: string;

  constructor(formValue: Partial<SpecialtyFormValue>) {
    this.name = formValue.name;
  }
}

export class AddSpecialtyPayload extends UpdateSpecialtyPayload {
  icon: File;

  constructor(formValue: Partial<SpecialtyFormValue>) {
    super(formValue);
    if (formValue.icon) {
      this.icon = formValue.icon.file;
    }
  }
}

export class UpdateSpecialtyIconPayload {
  file: File;

  constructor(formValue: Partial<SpecialtyFormValue>) {
    this.file = formValue.icon.file;
  }
}

export interface SpecialtyFiltersFormValue extends FiltersFormValue {
  name?: string;
  containsCourses?: boolean;
  minCoursesIsolatedQuestions?: number;
}

export class SpecialtyQueryData extends QueryData {
  name?: string;
  containsCourses?: boolean;
  minCoursesIsolatedQuestions?: number;

  constructor(filtersFormValue: SpecialtyFiltersFormValue, partial?: boolean) {
    super(filtersFormValue, partial);
    this.page = null;
    this.perPage = null;
    this.name = filtersFormValue.name;
    this.containsCourses = filtersFormValue.containsCourses;
    this.minCoursesIsolatedQuestions = filtersFormValue.minCoursesIsolatedQuestions;
  }
}

export interface TrainingSpecialtyFiltersFormValue extends FiltersFormValue {
  hasPlannedRepetitions?: boolean | -1;
}

export const DEFAULT_TRAINING_SPECIALTIES_FILTERS_FORM_VALUE: TrainingSpecialtyFiltersFormValue = {
  perPage: 12,
  hasPlannedRepetitions: -1,
  sortBy: 'name',
  sortByOrder: 'asc',
};

export class TrainingSpecialtyQueryData extends QueryData {
  hasPlannedRepetitions?: boolean;

  constructor(filtersFormValue: TrainingSpecialtyFiltersFormValue) {
    super(filtersFormValue, true);
    this.hasPlannedRepetitions =
      filtersFormValue.hasPlannedRepetitions === -1
        ? undefined
        : filtersFormValue.hasPlannedRepetitions;
  }
}
