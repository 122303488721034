import { StudentSettingResult } from '../api-results/student-setting.api-result';
import {
  StudentSettingFollowMode,
  StudentSettingName,
  StudentSettingSiteColorScheme,
  StudentSettingType,
} from '../enums/student-settings.enum';

export class StudentSetting {
  id: string;
  name: StudentSettingName;
  private value: any;
  type: StudentSettingType;

  constructor(result: StudentSettingResult) {
    this.id = result.id;
    this.name = result.name;
    this.value = result.value;

    if (this.name === StudentSettingName.FOLLOW_MODE) {
      this.type = StudentSettingType.FOLLOW_MODE;
    } else if (this.name === StudentSettingName.SITE_COLOR_SCHEME) {
      this.type = StudentSettingType.SITE_COLOR_SCHEME;
    } else {
      this.type = StudentSettingType.BOOLEAN;
    }
  }

  get booleanValue() {
    if (this.type !== StudentSettingType.BOOLEAN) {
      return undefined;
    }
    if (this.value === 'false') return false;
    else if (this.value === 'true') return true;
  }

  get followModeValue() {
    if (this.type !== StudentSettingType.FOLLOW_MODE) {
      return undefined;
    }
    return this.value as StudentSettingFollowMode;
  }

  get siteColorSchemeValue() {
    if (this.type !== StudentSettingType.SITE_COLOR_SCHEME) {
      return undefined;
    }
    return this.value as StudentSettingSiteColorScheme;
  }
}
