<div class="grid grid-cols-1 justify-items-center">
  <app-round-cover-image
    [src]="student.picture"
    [size]="60"
    (click)="modalService.openModalStudentProfile(student.id)"
    class="cursor-pointer shrink-0"
  ></app-round-cover-image>

  <div class="text-center mt-4 max-w-full">
    <div
      (click)="modalService.openModalStudentProfile(student.id)"
      class="text-14 font-extrabold mb-2 truncate cursor-pointer"
    >
      {{ student.username }}
    </div>
    <div class="truncate text-11 font-semibold">
      {{ student.university.universityCity?.name }} -
      {{ student.graduationName.name }}
    </div>
  </div>
</div>

<div
  class="my-4 flex items-center"
  [ngClass]="{
    'flex-col space-y-2': type !== StudentThumbnailType.PENDING,
    'flex-row justify-center space-x-2': type === StudentThumbnailType.PENDING
  }"
>
  <ng-container *ngIf="student.id !== (loggedStudent$ | async)?.id">
    <ng-container *ngIf="type === StudentThumbnailType.DEFAULT">
      <ng-container *ngIf="!student.followIsLoading">
        <button
          *ngIf="!student.followedByMe"
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-primary h-12 text-11 w-44"
          (click)="followService.follow(student)"
        >
          {{ 'twit.sabonner' | translate }}
        </button>

        <button
          *ngIf="student.followedByMe?.status === FollowStatus.PENDING"
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-white h-12 text-11 w-44"
          (click)="followService.unfollow(student)"
        >
          {{ 'friend.attente' | translate }}
        </button>

        <button
          *ngIf="student.followedByMe?.status === FollowStatus.APPROVED"
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-white h-12 text-11 w-44"
          (click)="followService.unfollow(student)"
        >
          {{ 'twit.se_desabonner' | translate }}
        </button>
      </ng-container>

      <div class="spinner_100_container" *ngIf="student.followIsLoading">
        <app-loader-dots></app-loader-dots>
      </div>

      <button
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-purple btn-nnki-icon-left btn-nnki-uppercase h-12 text-11 w-44"
        *ngIf="isApplicationService.premiumCanBeDisplayed$ | async"
        (click)="modalService.openModalChallenge(student)"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-challenge.svg"
          [svgStyle]="{ 'width.px': '16' }"
        ></svg-icon>
        {{ 'challenge.defier' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === StudentThumbnailType.FOLLOWERS">
      <button
        *ngIf="student.followingMe?.status === FollowStatus.APPROVED"
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-primary h-12 text-11 w-44"
        (click)="followService.deleteFollow(student)"
        i18n
      >
        Supprimer
      </button>
    </ng-container>
    <ng-container *ngIf="type === StudentThumbnailType.PENDING">
      <ng-container *ngIf="!student.followIsLoading">
        <button
          *ngIf="student.followingMe?.status === FollowStatus.PENDING"
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon btn-nnki-white h-12 text-11 w-20 border-green-three text-green-three"
          (click)="followService.approveFollowRequest(student)"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-check-light.svg"
            [svgStyle]="{ 'width.px': '14' }"
          ></svg-icon>
        </button>
        <button
          *ngIf="student.followingMe?.status === FollowStatus.PENDING"
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          class="btn-nnki btn-nnki-icon btn-nnki-white h-12 text-11 w-20 border-red-one text-red-one"
          (click)="followService.rejectFollowRequest(student)"
        >
          <svg-icon
            src="assets/images/svg-icons/icon-times.svg"
            [svgStyle]="{ 'width.px': '12' }"
          ></svg-icon>
        </button>
      </ng-container>

      <div class="spinner_100_container" *ngIf="student.followIsLoading">
        <app-loader-dots></app-loader-dots>
      </div>
    </ng-container>
  </ng-container>
</div>
