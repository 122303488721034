<ng-container *ngIf="challengeSession.sessionsClosedByGrade[0]?.grade > 0">
  <svg-icon
    class="absolute -left-4 top-0"
    src="assets/images/svg-illustrations/icon-trophy.svg"
    [svgStyle]="{ 'width.px': '23' }"
  ></svg-icon>

  <div
    class="nnki-rounded-background-image w-14 mr-4 shrink-0"
    [style.background-image]="
      sanitizerService.imageProfile(challengeSession.sessionsClosedByGrade[0].student.picture)
    "
  ></div>

  <div class="truncate">
    <div class="truncate text-13 font-bold mb-1">
      {{ challengeSession.sessionsClosedByGrade[0].student.username }}
    </div>
    <div class="text-11">
      {{ challengeSession.sessionsClosedByGrade[0].grade | number : '1.0-2' : 'fr' }}
      {{ 'examens.pts' | translate }}
    </div>
  </div>
</ng-container>
