<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -xlarge">
  <button class="mat-modal-closer -xs-black" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div
    class="modal-inside pt-20 pb-safeBottom sm:pb-8 px-4 sm:px-8 bg-white"
    id="modalInsideUserProfile"
  >
    <app-user-profile
      *ngIf="loggedStudent$ | async"
      [studentId]="modalData.studentId"
      [inModale]="true"
    ></app-user-profile>
  </div>
</div>
