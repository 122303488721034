import { AfterViewInit, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseDirective } from './base.directive';

@Directive({
  selector: '[appInfiniteScroll]',
})
export class InfiniteScrollDirective extends BaseDirective implements AfterViewInit {
  @Input() scrollContainer: string;
  @Input() scrollContent = 'appComponentContent';
  @Input() inversedScroll = false;

  @Output() needToScroll = new EventEmitter();

  scrollTrigger = 200;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    timer(0, 500)
      .pipe(takeUntil(this.alive$))
      .subscribe(() => {
        if (this.shouldScroll()) {
          this.needToScroll.emit();
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.shouldScroll()) {
      this.needToScroll.emit();
    }
  }

  shouldScroll() {
    let scrollContainerComparison: number;

    if (this.scrollContainer) {
      if (!document.getElementById(this.scrollContainer)) {
        return false;
      }

      scrollContainerComparison =
        document.getElementById(this.scrollContainer).scrollTop +
        document.getElementById(this.scrollContainer).clientHeight;
    } else {
      scrollContainerComparison = window.scrollY + window.innerHeight;
    }

    const scrollContent = document.getElementById(this.scrollContent);
    if (!scrollContent) return false;

    if (!this.inversedScroll) {
      if (scrollContent.clientHeight - scrollContainerComparison <= this.scrollTrigger) {
        return true;
      }
    } else {
      if (
        scrollContainerComparison - document.getElementById(this.scrollContainer).clientHeight <=
        this.scrollTrigger
      ) {
        return true;
      }
    }
    return false;
  }
}
