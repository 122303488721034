// nnkitodo [v2later] mutualiser avec site update ?

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Dialog } from '@capacitor/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EnvironmentService } from './environment.service';

import { GoogleAnalyticsService } from './google-analytics.service';
import { IsApplicationService } from './is-application.service';
import { LanguageService } from './language.service';
import { HttpFrontAppVersionService } from './http/http-front-app-version.service';
import {
  FrontAppVersion,
  LastDeployedVersions,
} from '../../shared/models/entities/front-app-version.entity';

declare const cordova: any;
@Injectable({
  providedIn: 'root',
})
export class FrontAppVersionAppService {
  newMobileVersionAvailable = false;
  newBreakingVersionAvailable = false;
  newMobileVersionAvailableDismissed = false;

  lastDeployedVersions: LastDeployedVersions;

  constructor(
    private router: Router,
    private isApplicationService: IsApplicationService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private environmentService: EnvironmentService,
    private httpFrontAppVersionService: HttpFrontAppVersionService
  ) {}

  init() {
    if (this.isApplicationService.isApplication() && environment.production) {
      // check every 5 minutes if a new version is available / first check now
      timer(0, 60 * 5 * 1000).subscribe(() => {
        this.checkNewVersionAvailable();
      });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.newBreakingVersionAvailable) {
          // Gère le cas ou l'utilisateur revient du store sans avoir mis à jour l'app
          document.addEventListener('resume', () => {
            this.openVersionUpdateForce();
          });
          this.openVersionUpdateForce();
        } else if (this.newMobileVersionAvailable && !this.newMobileVersionAvailableDismissed) {
          this.openVersionUpdateWarn();
          this.newMobileVersionAvailableDismissed = true;
        }
      });
    }
  }

  checkNewVersionAvailable() {
    this.httpFrontAppVersionService.getLastDeployedVersions().subscribe((result) => {
      this.lastDeployedVersions = new LastDeployedVersions(result);
      if (
        this.lastDeployedVersions.lastBreakingVersion &&
        !this.environmentService.frontAppVersion.isUpToDate(
          this.lastDeployedVersions.lastBreakingVersion
        ) &&
        (!this.lastDeployedVersions.lastBreakingVersion.isUpToDate(
          this.lastDeployedVersions.lastMobileVersion
        ) ||
          this.lastDeployedVersions.lastBreakingVersion.displayName ===
            this.lastDeployedVersions.lastMobileVersion.displayName)
      ) {
        this.newBreakingVersionAvailable = true;
      } else if (
        this.lastDeployedVersions.lastMobileVersion &&
        !this.environmentService.frontAppVersion.isUpToDate(
          this.lastDeployedVersions.lastMobileVersion
        )
      ) {
        this.newMobileVersionAvailable = true;
      }
    });
  }

  openVersionUpdateWarn() {
    forkJoin([
      this.translate.get('version.new'),
      this.translate.get('version.nouvelle', {
        new: this.lastDeployedVersions.lastVersion.displayName,
        current: this.environmentService.stringifiedFrontAppVersion,
      }),
      this.translate.get('version.telecharger'),
      this.translate.get('version.plus_tard'),
    ]).subscribe(([trad1, trad2, trad3, trad4]) => {
      Dialog.confirm({
        title: trad1,
        message: trad2,
        okButtonTitle: trad3,
        cancelButtonTitle: trad4,
      }).then((confirmResult) => {
        if (confirmResult.value) {
          this.openMarket(false);
        } else {
          this.googleAnalyticsService.sendEvent(
            'refuse update app',
            'update',
            'from ' +
              this.environmentService.stringifiedFrontAppVersion +
              ' to ' +
              this.lastDeployedVersions.lastVersion.displayName
          );
        }
      });
    });
  }

  openVersionUpdateForce() {
    forkJoin([
      this.translate.get('version.new'),
      this.translate.get('version.nouvelle2', {
        new: this.lastDeployedVersions.lastVersion.displayName,
        current: this.environmentService.stringifiedFrontAppVersion,
      }),
      this.translate.get('version.telecharger'),
    ]).subscribe(([trad1, trad2, trad3]) => {
      Dialog.alert({
        title: trad1,
        message: trad2,
        buttonTitle: trad3,
      }).then(() => {
        this.openMarket(true);
      });
    });
  }

  openMarket(force: boolean) {
    let appId = '';
    if (this.isApplicationService.isAppAndroid()) {
      appId = this.languageService.googlePlayAppId;
    } else if (this.isApplicationService.isAppIos()) {
      appId = this.languageService.appStoreAppId;
    }

    this.googleAnalyticsService.sendEvent(
      'accept update app',
      'update',
      'from ' +
        this.environmentService.stringifiedFrontAppVersion +
        ' to ' +
        this.lastDeployedVersions.lastVersion.displayName
    );

    cordova.plugins.market.open(appId);

    if (force) {
      this.openVersionUpdateForce();
    }
  }
}
