import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  ChallengeScoreResult,
  GlobalChallengeScoreResult,
  ListChallengeScoresResult,
} from '../../../shared/models/api-results/challenge-score.api-result';
import { map } from 'rxjs';
import { ListSpecialtiesResult } from '../../../shared/models/api-results/specialty.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpChallengeScoreService {
  constructor(private http: HttpClient) {}

  getMyChallengeScores() {
    return this.http
      .get(`${environment.api_base_url}/challenge_scores/get_my_challenge_scores`)
      .pipe(map((res) => res as GlobalChallengeScoreResult));
  }

  getChallengeScoresOfStudent(id: string) {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_scores/get_challenge_scores_of_another_student/${id}`
      )
      .pipe(map((res) => res as GlobalChallengeScoreResult));
  }

  getChallengeScoreRanking() {
    return this.http
      .get(`${environment.api_base_url}/challenge_scores/list_challenge_scores_ranking`)
      .pipe(map((res) => res as ListChallengeScoresResult));
  }

  getChallengeSpecialtyScoreRanking() {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_specialty_scores/list_challenge_specialty_scores_ranking`
      )
      .pipe(map((res) => res as ListSpecialtiesResult));
  }
}
