import { SortDirection } from '@angular/material/sort';

export interface FiltersFormValue {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sortByOrder?: SortDirection;
}

export class QueryData {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sortByOrder?: SortDirection;
  partial?: boolean;

  constructor(filtersFormValue: FiltersFormValue, partial?: boolean) {
    this.page = filtersFormValue.page;
    this.perPage = filtersFormValue.perPage;
    this.search = filtersFormValue.search;
    this.sortBy = filtersFormValue.sortBy;
    this.sortByOrder = filtersFormValue.sortByOrder;
    this.partial = partial;
  }
}
