import {
  ExamContentCourseResult,
  UpdateExamContentCourseVideoPreviewResult,
} from '../api-results/exam-content-course.api-result';
import {
  ExamContentCourseFormValue,
  UpdateExamContentCoursePayload,
} from '../payloads/exam-content-course.payload';
import { ExamContentFormValue } from '../payloads/exam-content.payload';
import { Course } from './course.entity';

export class ExamContentCourse {
  id: string;
  courseVideo: string;
  correctionVideo: string;
  courseVideoPreview: string;
  courses: Course[];
  totalViews: number;

  constructor(result: ExamContentCourseResult) {
    this.id = result.id;
    this.courseVideo = result.courseVideo;
    this.correctionVideo = result.correctionVideo;
    this.courseVideoPreview = result.courseVideoPreview;

    this.courseVideoPreview = result.courseVideoPreview
      ? result.courseVideoPreview
      : 'assets/assets-site/images/logos/logo_1024.png';

    if (result.courses) this.courses = result.courses.map((elt) => new Course(elt));
    this.totalViews = result.totalViews;
  }

  // nnkitodo [v2later] exam content course form value ce serait mieux
  toFormValue(): ExamContentCourseFormValue {
    return {
      courseVideo: this.courseVideo,
      correctionVideo: this.correctionVideo,
      courseVideoPreview: {
        file: null,
        path: this.courseVideoPreview,
      },
    };
  }

  toResult(): ExamContentCourseResult {
    return {
      id: this.id,
      courseVideo: this.courseVideo,
      correctionVideo: this.correctionVideo,
      courseVideoPreview: this.courseVideoPreview,
      courses: this.courses?.map((elt) => elt.toResult()),
      totalViews: this.totalViews,
    };
  }

  static updatedEntityToResult(
    payload: UpdateExamContentCoursePayload,
    updateVideoPreviewResult: UpdateExamContentCourseVideoPreviewResult
  ): Partial<ExamContentCourseResult> {
    return {
      courseVideo: payload.courseVideo,
      correctionVideo: payload.correctionVideo,
      courseVideoPreview: updateVideoPreviewResult.courseVideoPreview,
    };
  }
}
