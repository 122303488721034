import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalMaintenanceData {
  message: string;
}
@Component({
  selector: 'app-modal-maintenance',
  templateUrl: './modal-maintenance.component.html',
  styleUrls: ['./modal-maintenance.component.scss'],
})
export class ModalMaintenanceComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalMaintenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalMaintenanceData
  ) {}

  ngOnInit(): void {}
}
