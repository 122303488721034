import { throwError } from 'rxjs';

export interface ApiError {
  code?: number;
  message: string;
}

export function convertHttpErrorToApiError(error) {
  if (error.error?.code !== undefined && error.error?.message) {
    return {
      code: error.error.code,
      message: error.error.message,
    };
  } else if (error.error?.status !== undefined && error.error?.detail) {
    return {
      code: error.error.status,
      message: error.error.detail,
    };
  } else if (error.status !== undefined && error.error?.['hydra:description']) {
    return {
      code: error.status,
      message: error.error['hydra:description'].replace('\n', '<br>'),
    };
  } else if (error.error?.status !== undefined && error.error?.detail) {
    return {
      code: error.error.status,
      message: error.error.detail,
    };
  } else if (error.status !== undefined && error.message) {
    return {
      code: error.status,
      message: error.message,
    };
  } else if (error) {
    return {
      code: '',
      message: error,
    };
  } else {
    return {
      code: '',
      message: $localize`Une erreur s'est produite`,
    };
  }
}

export function throw404Error() {
  return throwError(() => {
    return {
      status: 404,
      message: `Not found`,
    };
  });
}
