export const environment = {
  production: true,
  env: 'prod',
  use_hash_for_routing: false,
  tinyMCEApiKey: 'sar0ftdfre1o8w5eb6c0khfmgj2q3m3g9zerjkxbi2rcicsw',

  site_id: 'ecni',
  site_name: 'EDN',
  defaultLanguage: 'fr',
  locale: 'fr-FR',
  api_base_url: 'https://api-v2.edn.fr/api',
  stripe_api_key: 'pk_live_sZKVlzGQFhTVwJp7vAaOKonS',
  google_web_id: '712294402346-0kbo2dldtkfeanibkgaii3dnd70i3no3.apps.googleusercontent.com',
  pusher_id: '67a54a11c90a6f15336a',
  fb_id: '1415786885322762',
  google_play_id: 'fr.ecni.app',
  appstore_id: 'id915067389',
  appstore_service_id: 'fr.ecni.service',
  apple_redirect_uri: 'https://www.edn.fr',
  pushChannel: 'ecni_push_channel',
  pushName: 'EDN',
  intercomAppId: 'kq47xsqo',
  intercomHelpdeskUrl: 'https://intercom.help/ecni',
  facebook_url: 'https://www.facebook.com/ECNi.fr',
  instagram_url: 'https://www.instagram.com/edn__fr/',
  tiktok_url: 'https://www.tiktok.com/@edn.fr',
  telegram_url: '',
  domain: 'www.edn.fr',
  adwordsKey: undefined,
  v2ReleaseDate: '2025-01-21 12:00:00',
};
