import { Injectable } from '@angular/core';
import packageJson from '../../../../package.json';
import { FrontAppVersionResult } from '../../shared/models/api-results/front-app-version.api-result';
import { FrontAppVersion } from '../../shared/models/entities/front-app-version.entity';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  get frontAppVersion() {
    const currentParsedInstalledVersion = packageJson.version.split('.');
    return new FrontAppVersion({
      major: Number(currentParsedInstalledVersion[0]),
      minor: Number(currentParsedInstalledVersion[1]),
      patch: Number(currentParsedInstalledVersion[2]),
    } as FrontAppVersionResult);
  }

  get stringifiedFrontAppVersion() {
    return packageJson.version;
  }

  get tinyMCEKey() {
    return environment.tinyMCEApiKey;
  }
}
