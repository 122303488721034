import { IsolatedQuestionsSessionResult } from '../api-results/isolated-questions-session.api-result';
import { ContentSessionType } from '../enums/session.enum';
import { ContentSession } from './content-session.entity';
import { ExamContentQuestion } from './exam-content-question.entity';
import { ExamContent } from './exam-content.entity';
import { Folder } from './folder.entity';
import { SessionQuestion } from './session-question.entity';

export class IsolatedQuestionsSession extends ContentSession {
  isCompleted: boolean;
  examContent: ExamContent;

  constructor(result: IsolatedQuestionsSessionResult) {
    super(result);
    this.isCompleted = result.isCompleted;

    if (result.examContent) {
      this.examContent = new ExamContent(result.examContent);

      if (result.isolatedQuestionsSessionQuestionAnswers) {
        this.questions = result.isolatedQuestionsSessionQuestionAnswers.map(
          (elt) => new SessionQuestion(elt)
        );
        SessionQuestion.buildQuestionsFromSession(
          this.questions,
          this.examContent.examContentQuestions
        );
      }
    }
  }

  get type() {
    return ContentSessionType.ISOLATED_QUESTIONS;
  }

  get content() {
    return undefined;
  }

  get contentQuestions() {
    return this.examContent?.examContentQuestions;
  }

  get maxGrade() {
    return this.examContent?.isolatedQuestionsMaxGrade;
  }

  get studentRate() {
    return this.examContent?.studentRate;
  }

  get courses() {
    return this.examContent?.courses;
  }

  toResult(): IsolatedQuestionsSessionResult {
    return {
      ...super.toResult(),
      id: this.id,
      isCompleted: this.isCompleted,
      examContent: this.examContent?.toResult(),
    };
  }
}
