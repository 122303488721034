<!-- nnkitodo [v2later opti] trouver un moyen de centraliser les ripples -->
<button
  matRipple
  [matRippleCentered]="false"
  [matRippleUnbounded]="true"
  [matRippleRadius]="50"
  [type]="type"
  class="btn-nnki w-full"
  [ngClass]="[
    backgroundClass,
    borderClass,
    textClass,
    heightClass,
    !label ? 'w-min' : '',
    uppercase ? 'uppercase' : ''
  ]"
  *ngIf="!loading"
  [disabled]="disabled"
>
  <span *ngIf="label">{{ label }}</span>
  <!-- nnkitodo [v2later icon] remplacer par material ? ou mieux ranger -->
  <app-svg-icon
    *ngIf="icon"
    [icon]="icon"
    [isMaterial]="isMaterial"
    [isV2Icon]="isV2Icon"
    [width]="iconWidth"
    [ngClass]="{ 'ml-6': label }"
  ></app-svg-icon>
</button>
<div class="min-h-[40px] w-full flex justify-center items-center" *ngIf="loading">
  <app-loader-dots></app-loader-dots>
</div>
