import { GraduationName } from '../entities/graduation-name.entity';
import { UniversityCity } from '../entities/university-city.entity';
import { UniversityCountry } from '../entities/university-country.entity';
import { University } from '../entities/university.entity';
import { SSOProvider } from '../enums/auth.enums';

export interface LoginFormValue {
  login: string;
  password: string;
  rememberMe: boolean;
}

export class LoginPayload {
  login: string;
  password: string;

  constructor(formValue: Partial<LoginFormValue>) {
    this.login = formValue.login.trim();
    this.password = formValue.password.trim();
  }
}

export class SSODefaultLoginPayload {
  access_token: string;

  constructor(accessToken: string) {
    this.access_token = accessToken;
  }
}

export class SSOFacebookLimitedLoginPayload {
  authentication_token: string;

  constructor(accessToken: string) {
    this.authentication_token = accessToken;
  }
}

export class SSOAppleLoginPayload {
  code: string;
  source: 'mobile' | 'web';

  constructor(code: string, isApplication: boolean) {
    this.code = code;
    this.source = isApplication ? 'mobile' : 'web';
  }
}

export class RefreshTokenPayload {
  refreshToken: string;

  constructor(refreshToken: string) {
    this.refreshToken = refreshToken;
  }
}

export class CheckAvailableEmailPayload {
  email: string;

  constructor(email: string) {
    this.email = email.trim();
  }
}

export class CheckAvailableUsernamePayload {
  username: string;

  constructor(username: string) {
    this.username = username.trim();
  }
}

export interface RegisterCredentialsFormValue {
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface RegisterNamesFormValue {
  username: string;
  firstName: string;
  lastName: string;
}

export interface RegisterInfosFormValue {
  universityCountry: UniversityCountry;
  universityCity: UniversityCity;
  university: University;
  graduationName: GraduationName;
  hasAcceptedTermsOfUse: boolean;
  hasAcceptedGDPR: boolean;
  isMailAllowed: boolean;
}

export interface RegisterUserFormValue {
  credentialsFormValue: Partial<RegisterCredentialsFormValue>;
  namesFormValue: Partial<RegisterNamesFormValue>;
  infosFormValue: Partial<RegisterInfosFormValue>;
}

export class RegisterStudentWithSSOPayload {
  username: string;
  universityId: string;
  graduationNameId: string;
  isMailAllowed: boolean;

  constructor(formGroup: Partial<RegisterUserFormValue>) {
    this.username = formGroup.namesFormValue.username.trim();
    this.universityId = formGroup.infosFormValue.university.id;
    this.graduationNameId = formGroup.infosFormValue.graduationName.id;
    this.isMailAllowed = formGroup.infosFormValue.isMailAllowed;
  }
}

export abstract class RegisterUserPayload extends RegisterStudentWithSSOPayload {
  password: string;
  firstName: string;
  lastName: string;

  constructor(formGroup: Partial<RegisterUserFormValue>) {
    super(formGroup);
    this.password = formGroup.credentialsFormValue.password;
    this.firstName = formGroup.namesFormValue.firstName.trim();
    this.lastName = formGroup.namesFormValue.lastName.trim();
  }
}

export class RegisterStudentWithEmailPayload extends RegisterUserPayload {
  email: string;

  constructor(formGroup: Partial<RegisterUserFormValue>) {
    super(formGroup);
    this.email = formGroup.credentialsFormValue.email.trim();
  }
}

export class RegisterWriterPayload extends RegisterUserPayload {
  authToken: string;

  constructor(formGroup: Partial<RegisterUserFormValue>, authToken: string) {
    super(formGroup);
    this.authToken = authToken;
  }
}

export interface RequestPasswordResetFormValue {
  email: string;
}

export class RequestPasswordResetPayload {
  email: string;

  constructor(formValue: RequestPasswordResetFormValue) {
    this.email = formValue.email;
  }
}

export interface ResetPasswordFormValue {
  newPassword: string;
  newPasswordConfirm: string;
}

export class ResetPasswordPayload {
  authToken: string;
  newPassword: string;

  constructor(formValue: ResetPasswordFormValue, authToken: string) {
    this.authToken = authToken;
    this.newPassword = formValue.newPassword;
  }
}

export interface AddOrUpdatePasswordFormValue {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export class AddPasswordPayload {
  password: string;

  constructor(formValue: AddOrUpdatePasswordFormValue) {
    this.password = formValue.newPassword;
  }
}

export class UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;

  constructor(formValue: AddOrUpdatePasswordFormValue) {
    this.oldPassword = formValue.oldPassword;
    this.newPassword = formValue.newPassword;
  }
}

export class DeleteSSOCredentialPayload {
  provider: SSOProvider;
  password: string;

  constructor(provider: SSOProvider, password: string) {
    this.provider = provider;
    this.password = password;
  }
}

export class DeleteAccountPayload {
  password: string;

  constructor(password: string) {
    this.password = password;
  }
}
