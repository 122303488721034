import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SanitizerService } from '../../../../core/services/sanitizer.service';
import { ChallengeActualitiesStoreService } from './challenge-actualities.component-store';
import { StudentActivity } from '../../../models/entities/student-activity.entity';
import { StudentActivityType } from '../../../models/enums/student-activity.enums';

@Component({
  selector: 'app-challenge-actualities',
  templateUrl: './challenge-actualities.component.html',
  styleUrls: ['./challenge-actualities.component.scss'],
  providers: [ChallengeActualitiesStoreService],
})
export class ChallengeActualitiesComponent implements OnInit {
  @Input() studentId;
  @Input() inModale = false;

  StudentActivityType = StudentActivityType;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    public sanitizerService: SanitizerService,
    public componentStore: ChallengeActualitiesStoreService
  ) {}

  ngOnInit(): void {
    this.componentStore.init(this.studentId);
    this.componentStore.initList({});
  }

  scroll() {
    this.componentStore.scroll();
  }

  redirectToContent(studentActivity: StudentActivity) {
    if (studentActivity.type === StudentActivityType.EXAM_CONTENT) {
      this.router.navigate([
        '/app/exams/examens/',
        studentActivity.examContent.exam.id,
        'preview',
        'exam-content',
        studentActivity.examContent.id,
      ]);
      this.matDialog.closeAll();
    }
  }

  toggleLike(studentActivity: StudentActivity) {
    if (!studentActivity.loadingLike) {
      if (!studentActivity.isLikedByMe) {
        this.componentStore.like(studentActivity);
      } else {
        this.componentStore.unlike(studentActivity);
      }
    }
  }
}
