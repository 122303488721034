import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { ListStudentActivitiesResult } from '../../../shared/models/api-results/student-activity.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';

@Injectable({
  providedIn: 'root',
})
export class HttpStudentActivityService {
  constructor(private http: HttpClient) {}

  list(studentId: string, params: QueryData) {
    return this.http
      .get(
        `${environment.api_base_url}/student_activities/list_student_activities_of_student/${studentId}`,
        {
          params: prepareQueryParams(params),
        }
      )
      .pipe(map((res) => res as ListStudentActivitiesResult));
  }

  listMine(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/student_activities/me/list_my_student_activities`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListStudentActivitiesResult));
  }

  addExamContentStudentActivity(id: string) {
    return this.http
      .post(
        `${environment.api_base_url}/student_activities/add_student_activity/exam_content/${id}`,
        {}
      )
      .pipe(map((res) => res as AddedEntity));
  }

  like(id: string) {
    return this.http.post(
      `${environment.api_base_url}/student_liked_activities/${id}/add_student_liked_activity`,
      {}
    );
  }

  unlike(id: string) {
    return this.http.post(
      `${environment.api_base_url}/student_liked_activities/${id}/delete_student_liked_activity`,
      {}
    );
  }
}
