import { DeviceTokenType, DeviceTokenApplicationType } from '../enums/device-token.enum';

export class AddDeviceTokenPayload {
  token: string;
  type: DeviceTokenType;
  applicationType: DeviceTokenApplicationType;

  constructor(token: string, type: DeviceTokenType) {
    this.token = token;
    this.type = type;
    this.applicationType = DeviceTokenApplicationType.STUDENT;
  }
}
