import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FollowFiltersFormValue,
  FollowPayload,
  FollowQueryData,
  HandleFollowRequestPayload,
} from '../../../shared/models/payloads/follow.payload';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  FollowNumbersResult,
  FollowResult,
  ListFollowedStudentsResult,
} from '../../../shared/models/api-results/follow.api-result';
import { ListStudentsResult } from '../../../shared/models/api-results/student.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';

@Injectable({
  providedIn: 'root',
})
export class HttpFollowService {
  constructor(private http: HttpClient) {}

  follow(payload: FollowPayload) {
    return this.http
      .post(`${environment.api_base_url}/me/following/follow`, payload)
      .pipe(map((res) => res as FollowResult));
  }

  unfollow(payload: FollowPayload) {
    return this.http.post(`${environment.api_base_url}/me/following/unfollow`, payload);
  }

  approveFollowRequest(payload: HandleFollowRequestPayload) {
    return this.http.post(
      `${environment.api_base_url}/me/followers/approve_follow_request`,
      payload
    );
  }

  rejectFollowRequest(payload: HandleFollowRequestPayload) {
    return this.http.post(
      `${environment.api_base_url}/me/followers/revoke_follow_request`,
      payload
    );
  }

  listFollowedByMe(params: FollowQueryData) {
    return this.http
      .get(`${environment.api_base_url}/me/list_my_followed_students`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFollowedStudentsResult));
  }

  listFollowedByStudent(id: string, params: FollowQueryData) {
    return this.http
      .get(`${environment.api_base_url}/${id}/list_followed_students`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFollowedStudentsResult));
  }

  listMyFollowers(params: FollowQueryData) {
    return this.http
      .get(`${environment.api_base_url}/me/list_followers`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFollowedStudentsResult));
  }

  listStudentFollowers(id: string, params: FollowQueryData) {
    return this.http
      .get(`${environment.api_base_url}/${id}/list_student_followers`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListFollowedStudentsResult));
  }

  isFollowedByMe(id: string) {
    return this.http
      .get(`${environment.api_base_url}/follows/following_status/${id}`)
      .pipe(map((res) => res as FollowResult));
  }

  getMyFollowNumbers() {
    return this.http
      .get(`${environment.api_base_url}/me/follows/get_my_follow_numbers`)
      .pipe(map((res) => res as FollowNumbersResult));
  }

  getFollowNumbers(id: string) {
    return this.http
      .get(`${environment.api_base_url}/follows/${id}/get_follow_numbers`)
      .pipe(map((res) => res as FollowNumbersResult));
  }
}
