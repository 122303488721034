import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  KnowledgeAnchoringLastRepetitionDateResult,
  KnowledgeAnchoringPlanningResult,
  KnowledgeAnchoringStatisticsResult,
  WKnowledgeAnchoringRepetitionResult,
  WKnowledgeAnchoringStatisticssResult,
} from '../../../shared/models/api-results/knowledge-anchoring.api-result';
import { map } from 'rxjs';
import {
  MakeRepetitionPayload,
  PauseKnowledgeAnchoringPayload,
} from '../../../shared/models/payloads/knowledge-anchoring.payload';
import { ListResult } from '../../../shared/models/api-results/base.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpKnowledgeAnchoringService {
  constructor(private http: HttpClient) {}

  makeRepetition(id: string, payload: MakeRepetitionPayload) {
    return this.http.post(
      `${environment.api_base_url}/knowledge_anchoring/questions/${id}/make_repetition`,
      payload
    );
  }

  addRepetitionFromCustom(id: string, payload: MakeRepetitionPayload) {
    return this.http.post(
      `${environment.api_base_url}/knowledge_anchoring/questions/${id}/add_repetition`,
      payload
    );
  }

  getStats() {
    return this.http
      .get(`${environment.api_base_url}/knowledge_anchoring/get_my_statistics`)
      .pipe(map((res) => res as KnowledgeAnchoringStatisticsResult));
  }

  getPlanning() {
    return this.http
      .get(`${environment.api_base_url}/me/knowledge_anchoring/get_my_knowledge_anchoring_planning`)
      .pipe(map((res) => res as KnowledgeAnchoringPlanningResult));
  }

  getLastRepetitionDate() {
    return this.http
      .get(`${environment.api_base_url}/me/knowledge_anchoring/get_my_last_repetition_date`)
      .pipe(map((res) => res as KnowledgeAnchoringLastRepetitionDateResult));
  }

  resetKnowledgeAnchoring() {
    return this.http.post(`${environment.api_base_url}/knowledge_anchoring/reset_progression`, {});
  }

  pauseKnowledgeAnchoring(payload: PauseKnowledgeAnchoringPayload) {
    return this.http.post(`${environment.api_base_url}/knowledge_anchoring/make_break`, payload);
  }

  updateKnowledgeAnchoringBreak(id: string, payload: PauseKnowledgeAnchoringPayload) {
    return this.http.post(
      `${environment.api_base_url}/knowledge_anchoring/${id}/update_break`,
      payload
    );
  }

  stopKnowledgeAnchoringBreak(id: string) {
    return this.http.post(`${environment.api_base_url}/knowledge_anchoring/${id}/stop_break`, {});
  }

  cancelKnowledgeAnchoringBreak(id: string) {
    return this.http.post(`${environment.api_base_url}/knowledge_anchoring/${id}/cancel_break`, {});
  }

  wGetGlobalStats() {
    return this.http
      .get(`${environment.api_base_url}/knowledge_anchoring/get_global_repetitions_statistics`)
      .pipe(map((res) => res as ListResult<WKnowledgeAnchoringStatisticssResult>));
  }

  wGetUserStats(userId: string) {
    return this.http
      .get(
        `${environment.api_base_url}/knowledge_anchoring/${userId}/get_user_repetitions_statistics`
      )
      .pipe(map((res) => res as ListResult<WKnowledgeAnchoringStatisticssResult>));
  }

  wGetQuestionStats(questionId: string) {
    return this.http
      .get(
        `${environment.api_base_url}/knowledge_anchoring/${questionId}/get_question_repetitions_statistics`
      )
      .pipe(map((res) => res as ListResult<WKnowledgeAnchoringStatisticssResult>));
  }

  wGetQuestionByUserStats(userId: string, questionId: string) {
    return this.http
      .get(
        `${environment.api_base_url}/knowledge_anchoring/${questionId}/get_repetitions_statistics_for_question_and_user/${userId}`
      )
      .pipe(map((res) => res as ListResult<WKnowledgeAnchoringRepetitionResult>));
  }
}
