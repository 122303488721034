import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApplicationStatsResult } from '../../../shared/models/api-results/application-stats.api-result';
import { map } from 'rxjs';
import { ListExamTypesResult } from '../../../shared/models/api-results/exam-type.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpApplicationStatsService {
  constructor(private http: HttpClient) {}

  getApplicationStats() {
    return this.http
      .get(`${environment.api_base_url}/users/get_numbers_about_application_content_and_uses`)
      .pipe(map((res) => res as ApplicationStatsResult));
  }

  countPublishedExamsGroupedByType() {
    return this.http
      .get(`${environment.api_base_url}/exam_types/list_exam_type_total_published_exam`)
      .pipe(map((res) => res as ListExamTypesResult));
  }
}
