import { createReducer, on } from '@ngrx/store';
import { initialGlobalEntitiesState } from './global-entities.state';
import * as GlobalEntitiesActions from './global-entities.actions';
import { getCountriesArray } from '../../../shared/constants/countries';

export const globalEntitiesReducer = createReducer(
  initialGlobalEntitiesState,

  on(GlobalEntitiesActions.listGraduationNamesSucceed, (state, action) => ({
    ...state,
    graduationNames: action.result,
  })),

  on(GlobalEntitiesActions.listUniversityCountriesSucceed, (state, action) => ({
    ...state,
    universityCountries: action.result,
  })),

  on(GlobalEntitiesActions.listUniversityCitiesSucceed, (state, action) => ({
    ...state,
    universityCities: action.result,
  })),

  on(GlobalEntitiesActions.listUniversitiesSucceed, (state, action) => ({
    ...state,
    universities: action.result,
  })),

  on(GlobalEntitiesActions.listExamTypesSucceed, (state, action) => ({
    ...state,
    examTypes: action.result,
  })),

  on(GlobalEntitiesActions.listCountries, (state, action) => ({
    ...state,
    countries: getCountriesArray(action.defaultLanguage),
  }))
);
