import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IsApplicationService } from '../../../../core/services/is-application.service';
import { LinkService } from '../../../../core/services/link.service';

import {
  ModalListImagesApplicationComponent,
  ModalQuestionImagesApplicationData,
} from '../../../modals/modal-list-images-application/modal-list-images-application.component';
import {
  ModalListImagesComponent,
  ModalListImagesData,
} from '../../../modals/modal-list-images/modal-list-images.component';
import { StatementContent } from '../../../models/entities/statement-content.entity';
import { StatementContentType } from '../../../models/enums/statement-content.enums';

@Component({
  selector: 'app-question-images',
  templateUrl: './question-images.component.html',
  styleUrls: ['./question-images.component.scss'],
})
export class QuestionImagesComponent implements OnInit {
  @Input() pictureStatementContents: StatementContent[];

  constructor(
    private isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    private linkService: LinkService
  ) {}

  ngOnInit(): void {}

  openModalImage(index: number) {
    if (!this.isApplicationService.isApplication()) {
      const modalData: ModalListImagesData = {
        images: this.pictureStatementContents.map((statementContent) => statementContent.file),
        currentImageIndex: index,
      };
      this.matDialog.open(ModalListImagesComponent, {
        maxWidth: '100vw',
        data: modalData,
      });
    } else {
      if (this.pictureStatementContents.length === 1) {
        this.linkService.openBlank(this.pictureStatementContents[0].file);
      } else {
        const modalData: ModalQuestionImagesApplicationData = {
          images: this.pictureStatementContents.map((statementContent) => statementContent.file),
        };
        this.matDialog.open(ModalListImagesApplicationComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      }
    }
  }
}
