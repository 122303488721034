import { GraduationName } from '../entities/graduation-name.entity';
import { UniversityCity } from '../entities/university-city.entity';
import { UniversityCountry } from '../entities/university-country.entity';
import { University } from '../entities/university.entity';
import { SubscriptionTier } from '../enums/subscription.enums';
import { FiltersFormValue, QueryData } from './query-data.payload';

export interface StudentUniversityFormValue {
  universityCountry: UniversityCountry;
  universityCity: UniversityCity;
  university: University;
  graduationName: GraduationName;
}

export class StudentUniversityPayload {
  universityId: string;
  graduationNameId: string;

  constructor(formValue: Partial<StudentUniversityFormValue>) {
    this.universityId = formValue.university.id;
    this.graduationNameId = formValue.graduationName.id;
  }
}

export interface WStudentFiltersFormValue extends FiltersFormValue {
  currentSubscriptionTier?: SubscriptionTier;
  graduationName?: GraduationName;
  university?: University;
  isActive?: boolean;
  lockedAt?: boolean;
}

export class WStudentQueryData extends QueryData {
  currentSubscriptionTier: SubscriptionTier;
  'graduationName.id'?: string;
  'university.id'?: string;
  isActive?: boolean;
  isLocked: boolean;

  constructor(filtersFormValue: WStudentFiltersFormValue, partial: boolean) {
    super(filtersFormValue, partial);
    this.currentSubscriptionTier = filtersFormValue.currentSubscriptionTier;
    this['graduationName.id'] = filtersFormValue.graduationName?.id;
    this['university.id'] = filtersFormValue.university?.id;
    this.isActive = filtersFormValue.isActive;
    this.isLocked = filtersFormValue.lockedAt;
  }
}

export interface StudentFiltersFormValue extends FiltersFormValue {
  username?: string;
}

export class StudentQueryData extends QueryData {
  'credential.username'?: string;

  constructor(filtersFormValue: StudentFiltersFormValue) {
    super(filtersFormValue);
    this['credential.username'] = filtersFormValue.username;
  }
}
