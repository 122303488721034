import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { Specialty } from '../../../models/entities/specialty.entity';

@Component({
  selector: 'app-discipline-challenge',
  templateUrl: './discipline-challenge.component.html',
  styleUrls: ['./discipline-challenge.component.scss'],
})
export class DisciplineChallengeComponent implements OnInit {
  @Input() specialty: Specialty;
  @Input() selected: boolean;

  constructor(public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
