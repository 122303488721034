import { Injectable } from '@angular/core';

import { Camera, CameraResultType } from '@capacitor/camera';
import { finalize } from 'rxjs/operators';

// nnkitodo [v2later] mutualiser avec partie writer

import { HttpUserService } from './http/http-user.service';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../core/state/auth-state/auth.actions';
import { UserPicturePayload } from '../../shared/models/payloads/user.payload';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  loadingUpload = false;

  constructor(
    private store: Store,
    private errorService: ErrorService,
    private httpUserService: HttpUserService
  ) {}

  uploadAvatarDesktop(event) {
    const fileList: FileList = (event.target as HTMLInputElement).files;
    if (fileList.length > 0) {
      this.loadingUpload = true;
      const file: File = fileList[0];

      this.httpUserService
        .updateMyPicture(
          new UserPicturePayload({
            path: undefined,
            file,
          })
        )
        .pipe(
          finalize(() => {
            this.loadingUpload = false;
          })
        )
        .subscribe({
          next: (result) => {
            this.store.dispatch(AuthActions.updateMyPicture({ result }));
          },
          error: (error) => {
            this.errorService.toastError(error);
          },
        });
    }
  }

  uploadAvatarMobile() {
    this.loadingUpload = true;

    Camera.getPhoto({
      quality: 50,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      width: 800,
      height: 800,
    }).then(
      (picture) => {
        var arr = picture.dataUrl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const file = new File([u8arr], 'picture', { type: mime });

        this.httpUserService
          .updateMyPicture(
            new UserPicturePayload({
              path: undefined,
              file,
            })
          )
          .pipe(
            finalize(() => {
              this.loadingUpload = false;
            })
          )
          .subscribe({
            next: (result) => {
              this.store.dispatch(AuthActions.updateMyPicture({ result }));
            },
            error: (error) => {
              this.errorService.toastError(error);
            },
          });
      },
      (err) => {
        console.error(err);
        this.loadingUpload = false;
      }
    );
  }

  deleteAvatar() {
    this.loadingUpload = true;
    this.httpUserService
      .deleteMyPicture()
      .pipe(
        finalize(() => {
          this.loadingUpload = false;
        })
      )
      .subscribe({
        next: () => {
          this.store.dispatch(AuthActions.updateMyPicture({ result: { picture: null } }));
        },
        error: (error) => {
          this.errorService.toastError(error);
        },
      });
  }
}
