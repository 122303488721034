import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { OfferResult } from '../api-results/offer.api-result';
import { SubscriptionTier } from '../enums/subscription.enums';
import { OfferFormValue, UpdateOfferPayload } from '../payloads/offer.payload';
import moment from 'moment';

export class Offer {
  id: string;
  name: string;
  tier: SubscriptionTier;
  price: number;
  durationInDays: number;
  availablePaymentInstallments: number[];
  isCustom: boolean;

  startAt: Date;
  endAt: Date;
  createdAt: Date;

  constructor(result: OfferResult) {
    this.id = result.id;
    this.name = result.name;
    if (this.name === 'Custom offer') this.name = '';
    this.tier = result.tier;
    this.price = result.price;
    this.durationInDays = result.durationInDays;
    this.availablePaymentInstallments = result.availablePaymentInstallments;
    this.isCustom = result.isCustom;

    if (result.startAt) {
      this.startAt = moment(result.startAt).toDate();
    }
    if (result.endAt) {
      this.endAt = moment(result.endAt).toDate();
    }
    if (result.createdAt) {
      this.createdAt = moment(result.createdAt).toDate();
    }
  }

  get displayName() {
    return `${this.name} (${this.durationInMonths} ${$localize`mois`})`;
  }

  get durationInMonths() {
    return Math.round(this.durationInDays / 30.5);
  }

  toFormValue(): OfferFormValue {
    return {
      name: this.name,
      tier: this.tier,
      price: this.price,
      durationInMonths: this.durationInMonths,
      availablePaymentInstallments: this.availablePaymentInstallments.filter(
        (installment) => installment > 1
      ),
      startAt: this.startAt,
      endAt: this.endAt,
    };
  }

  toResult(): OfferResult {
    return {
      id: this.id,
      name: this.name,
      tier: this.tier,
      price: this.price,
      durationInDays: this.durationInDays,
      availablePaymentInstallments: this.availablePaymentInstallments,
      isCustom: this.isCustom,
      startAt: this.startAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
      endAt: this.endAt ? moment(this.endAt).format(API_DATETIME_FORMAT) : null,
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
    };
  }

  static updatedEntityToResult(payload: UpdateOfferPayload): Partial<OfferResult> {
    return {
      name: payload.name,
      price: payload.price,
      durationInDays: payload.durationInDays,
      availablePaymentInstallments: payload.availablePaymentInstallments,
      isCustom: payload.isCustom,
      startAt: payload.startAt,
      endAt: payload.endAt,
    };
  }
}
