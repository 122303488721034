// nnkitodo [v2later i18n] gérer default language avec i18n
// nnkitodo [v2later] vérifier que tout est utile

export class Country {
  code: string;
  name: string;
}

export function getCountriesArray(defaultLanguage: string): Country[] {
  const countriesList = getCountriesList(defaultLanguage);
  return Object.keys(countriesList)
    .map((key) => {
      return {
        code: key,
        name: countriesList[key],
      };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));
}

export function getCountryName(defaultLanguage: string, countryCode: string): string {
  const countriesList = getCountriesList(defaultLanguage);
  return countriesList[countryCode] ? countriesList[countryCode] : countryCode;
}

export function getCountryByName(defaultLanguage: string, countryName: string) {
  if (!countryName) return null;
  const countriesArray = getCountriesArray(defaultLanguage);
  return countriesArray.find((country) => country.name.toLowerCase() === countryName.toLowerCase());
}

export function getCountriesList(defaultLanguage: string) {
  if (defaultLanguage === 'fr') {
    return {
      AF: 'Afghanistan',
      AX: "Iles d'Aland",
      AL: 'Albanie',
      DZ: 'Algérie',
      AS: 'Samoa américaine',
      AD: 'Andorre',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctique',
      AG: 'Antigua-et-Barbuda',
      AR: 'Argentine',
      AM: 'Arménie',
      AW: 'Aruba',
      AU: 'Australie',
      AT: 'Autriche',
      AZ: 'Azerbaïdjan',
      BS: 'Bahamas',
      BH: 'Bahreïn',
      BD: 'Bangladesh',
      BB: 'Barbade',
      BY: 'Bélarus',
      BE: 'Belgique',
      BZ: 'Belize',
      BJ: 'Bénin',
      BM: 'Bermudes',
      BT: 'Bhoutan',
      BO: 'Bolivie',
      BA: 'Bosnie-Herzégovine',
      BW: 'Botswana',
      BV: 'Ile Bouvet',
      BR: 'Brésil',
      IO: "Territoire britannique de l'océan Indien",
      BN: 'Brunei Darussalam',
      BG: 'Bulgarie',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambodge',
      CM: 'Cameroun',
      CA: 'Canada',
      CV: 'Cap-Vert',
      KY: 'Iles Caimans',
      CF: 'République centrafricaine',
      TD: 'Tchad',
      CL: 'Chili',
      CN: 'Chine',
      CX: 'Ile Christmas',
      CC: 'Iles Cocos (Keeling)',
      CO: 'Colombie',
      KM: 'Comores',
      CG: 'Congo',
      CD: 'Congo, République démocratique du',
      CK: 'Iles Cook',
      CR: 'Costa Rica',
      CI: "Cote D'Ivoire",
      HR: 'Croatie',
      CU: 'Cuba',
      CY: 'Chypre',
      CZ: 'République tchèque',
      DK: 'Danemark',
      DJ: 'Djibouti',
      DM: 'Dominique',
      DO: 'République dominicaine',
      EC: 'Equateur',
      EG: 'Egypte',
      SV: 'El Salvador',
      GQ: 'Guinée équatoriale',
      ER: 'Erythrée',
      EE: 'Estonie',
      ET: 'Ethiopie',
      FK: 'Iles Malouines',
      FO: 'Iles Féroé',
      FJ: 'Fiji',
      FI: 'Finlande',
      FR: 'France',
      GF: 'Guyane française',
      PF: 'Polynésie française',
      TF: 'Terres australes françaises',
      GA: 'Gabon',
      GM: 'Gambie',
      GE: 'Géorgie',
      DE: 'Allemagne',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Grèce',
      GL: 'Groenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernesey',
      GN: 'Guinée',
      GW: 'Guinée-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Iles Heard et McDonald',
      VA: 'Vatican',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hongrie',
      IS: 'Islande',
      IN: 'Inde',
      ID: 'Indonésie',
      IR: 'Iran',
      IQ: 'Irak',
      IE: 'Irlande',
      IM: 'Ile de Man',
      IL: 'Israel',
      IT: 'Italie',
      JM: 'Jamaique',
      JP: 'Japon',
      JE: 'Jersey',
      JO: 'Jordanie',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KR: 'Corée du Sud',
      KP: 'Corée du Nord',
      KW: 'Koweit',
      KG: 'Kirghizistan',
      LA: 'Laos',
      LV: 'Lettonie',
      LB: 'Liban',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libye',
      LI: 'Liechtenstein',
      LT: 'Lituanie',
      LU: 'Luxembourg',
      MO: 'Macao',
      MK: 'Macédoine',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaisie',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malte',
      MH: 'Iles Marshall',
      MQ: 'Martinique',
      MR: 'Mauritanie',
      MU: 'Maurice',
      YT: 'Mayotte',
      MX: 'Mexique',
      FM: 'Micronésie, États fédérés de',
      MD: 'Moldavie',
      MC: 'Monaco',
      MN: 'Mongolie',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Maroc',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibie',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Pays-Bas',
      AN: 'Netherlands Antilles',
      NC: 'Nouvelle-Calédonie',
      NZ: 'Nouvelle-Zélande',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Ile Norfolk',
      MP: 'Mariannes du Nord',
      NO: 'Norvège',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestine',
      PA: 'Panama',
      PG: 'Papouasie-Nouvelle-Guinée',
      PY: 'Paraguay',
      PE: 'Pérou',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Pologne',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Reunion',
      RO: 'Roumanie',
      RU: 'Russie',
      RW: 'Rwanda',
      BL: 'Saint Barthelemy',
      SH: 'Sainte-Hélène',
      KN: 'Saint-Kitts-et-Nevis',
      LC: 'Sainte-Lucie',
      MF: 'Saint Martin',
      PM: 'Saint Pierre And Miquelon',
      VC: 'Saint-Vincent-et-les-Grenadines',
      WS: 'Samoa',
      SM: 'Saint-Marin',
      ST: 'Sao Tomé-et-Principe',
      SA: 'Arabie saoudite',
      SN: 'Senegal',
      RS: 'Serbie',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapour',
      SK: 'Slovaquie',
      SI: 'Slovénie',
      SB: 'Iles Salomon',
      SO: 'Somalie',
      ZA: 'Afrique du Sud',
      GS: 'Géorgie du Sud et les iles Sandwich du Sud',
      ES: 'Espagne',
      LK: 'Sri Lanka',
      SD: 'Soudan',
      SR: 'Suriname',
      SJ: 'Svalbard et ile de Jan Mayen',
      SZ: 'Swaziland',
      SE: 'Suède',
      CH: 'Suisse',
      SY: 'Syrie',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzanie',
      TH: 'Thaïlande',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinité-et-Tobago',
      TN: 'Tunisie',
      TR: 'Turquie',
      TM: 'Turkmenistan',
      TC: 'Iles Turks et Caicos',
      TV: 'Tuvalu',
      UG: 'Ouganda',
      UA: 'Ukraine',
      AE: 'Emirats arabes unis',
      GB: 'Royaume-Uni',
      US: 'Etats-Unis',
      UM: 'Iles mineures éloignées des États-Unis',
      UY: 'Uruguay',
      UZ: 'Ouzbékistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      VG: 'Iles Vierges britanniques',
      VI: 'Iles Vierges américaines',
      WF: 'Iles Wallis-et-Futuna',
      EH: 'Sahara Occidental',
      YE: 'Yemen',
      ZM: 'Zambie',
      ZW: 'Zimbabwe',
      XX: 'Autres',
    };
  } else if (defaultLanguage === 'it') {
    return {
      AF: 'Afghanistan',
      AX: 'Isole Aland',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'Samoa americane',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antartico',
      AG: 'Antigua e Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaigian',
      BS: 'Bahamas',
      BH: 'Bahrein',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Bélarus',
      BE: 'Belgio',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia Erzegovinia',
      BW: 'Botswana',
      BV: 'Isola Bouvet',
      BR: 'Bresile',
      IO: "Territorio britanico dell'oceano Indiano",
      BN: 'Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Cambogia',
      CM: 'Camerun',
      CA: 'Canada',
      CV: 'Capo Verde',
      KY: 'Isole Cayman',
      CF: 'Repubblica centrafricana',
      TD: 'Ciad',
      CL: 'Cile',
      CN: 'Cina',
      CX: 'Isola di Natale',
      CC: 'Isole Cocos (Keeling)',
      CO: 'Colombia',
      KM: 'Comore',
      CG: 'Congo',
      CD: 'Congo, Repubblica democratica del Congo',
      CK: 'Isole Cook',
      CR: 'Costa Rica',
      CI: "Costa d'Avorio",
      HR: 'Croazia',
      CU: 'Cuba',
      CY: 'Cipro',
      CZ: 'Repubblica ceca',
      DK: 'Danimarca',
      DJ: 'Gibuti',
      DM: 'Dominica',
      DO: 'Repubblica dominicana',
      EC: 'Equatore',
      EG: 'Egitto',
      SV: 'El Salvador',
      GQ: 'Guinea equatoriale',
      ER: 'Eritrea',
      EE: 'Estonia',
      ET: 'Etiopia',
      FK: 'Isole Falkland',
      FO: 'Isole Faroe',
      FJ: 'Figi',
      FI: 'Finlandia',
      FR: 'Francia',
      GF: 'Guyana francese',
      PF: 'Polinesia francese',
      TF: 'Terre australi francesi',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germania',
      GH: 'Ghana',
      GI: 'Gibilterra',
      GR: 'Grecia',
      GL: 'Groenlandia',
      GD: 'Grenada',
      GP: 'Guadalupa',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernnsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Isole Heard e McDonald',
      VA: 'Sante Sede (Città del Vaticano)',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Ungheria',
      IS: 'Islanda',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran',
      IQ: 'Iraq',
      IE: 'Irlanda',
      IM: 'Isola di Man',
      IL: 'Israele',
      IT: 'Italia',
      JM: 'Giamaica',
      JP: 'Giappone',
      JE: 'Jersey',
      JO: 'Giordania',
      KZ: 'Kazakistan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KR: 'Corea del Sud',
      KP: 'Corea del Nord',
      KW: 'Koweit',
      KG: 'Kirghizistan',
      LA: 'Laos',
      LV: 'Lettonia',
      LB: 'Libano',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libia',
      LI: 'Liechtenstein',
      LT: 'Lituania',
      LU: 'Lussemburgo',
      MO: 'Macao',
      MK: 'Macedonia',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldive',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Isole Marshall',
      MQ: 'Martinica',
      MR: 'Mauritania',
      MU: 'Maurizio',
      YT: 'Mayotte',
      MX: 'Messico',
      FM: 'Micronesia, Stati federali di',
      MD: 'Moldavia',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Marocco',
      MZ: 'Mozambico',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Paesi Bassi',
      AN: 'Antille Olandesi',
      NC: 'Nuova Caledonia',
      NZ: 'Nuova Zelanda',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Isola Norfolk',
      MP: 'Marianne settentrionali',
      NO: 'Norvegia',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestina',
      PA: 'Panama',
      PG: 'Papua Nuova Guinea',
      PY: 'Paraguay',
      PE: 'Perù',
      PH: 'Filippine',
      PN: 'Pitcairn',
      PL: 'Pologna',
      PT: 'Portogallo',
      PR: 'Porto Rico',
      QA: 'Qatar',
      RE: 'Riunione',
      RO: 'Romania',
      RU: 'Russia',
      RW: 'Ruanda',
      BL: 'Saint Barthelemy',
      SH: "Sant'Elena",
      KN: 'Saint Kitts e Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin',
      PM: 'Saint-Pierre e Miquelon',
      VC: 'Saint Vincent e Grenadine',
      WS: 'Samoa',
      SM: 'Saint-Marin',
      ST: 'Sao Tomé e Principe',
      SA: 'Arabia Saudita',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SK: 'Slovacchia',
      SI: 'Slovenia',
      SB: 'Isole Salomone',
      SO: 'Somalia',
      ZA: 'Sudafrica',
      GS: 'Georgia del Sud e Isole Sandwich Meridionali',
      ES: 'Spagna',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard e isole di Jan Mayen',
      SZ: 'Swaziland',
      SE: 'Svezia',
      CH: 'Svizzera',
      SY: 'Siria',
      TW: 'Taiwan',
      TJ: 'Tagikistan',
      TZ: 'Tanzania',
      TH: 'Tailandia',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad e Tobago',
      TN: 'Tunisia',
      TR: 'Turchia',
      TM: 'Turkmenistan',
      TC: 'Isole Turks e Caicos',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ucraina',
      AE: 'Emirati arabi uniti',
      GB: 'Regno Unito',
      US: 'Stati Uniti',
      UM: 'Isole minori esterne degli Stati Uniti',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      VG: 'Isole Vergini britanniche',
      VI: 'Isole Vergini americane',
      WF: 'Isole Wallis e Futuna',
      EH: 'Sahara Occidentale',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
      XX: 'Altri',
    };
  } else if (defaultLanguage === 'es') {
    return {
      AF: 'Afganistán',
      AX: 'Islas Aland',
      AL: 'Albania',
      DZ: 'Argelia',
      AS: 'Samoa americanas',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguila',
      AQ: 'Antártico',
      AG: 'Antigua y Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaiyán',
      BS: 'Bahamas',
      BH: 'Baréin',
      BD: 'Bangladés',
      BB: 'Barbados',
      BY: 'Bielorrusia',
      BE: 'Bélgica',
      BZ: 'Belize',
      BJ: 'Benín',
      BM: 'Bermuda',
      BT: 'Bután',
      BO: 'Bolivia',
      BA: 'Bosnia Herzegovina',
      BW: 'Botsuana',
      BV: 'Isla Bouvet',
      BR: 'Brasil',
      IO: 'Territorio Británico del Océano Índico',
      BN: 'Brunéi Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      KH: 'Camboya',
      CM: 'Camerún',
      CA: 'Canadá',
      CV: 'Cabo Verde',
      KY: 'Islas Cayman',
      CF: 'Repubblica centrafricana',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Isla de Navidad',
      CC: 'Islas Cocos (Keeling)',
      CO: 'Colombia',
      KM: 'Comoras',
      CG: 'Congo',
      CD: 'Congo, República Democrática del Congo',
      CK: 'Islas Cook',
      CR: 'Costa Rica',
      CI: 'Costa de Marfil',
      HR: 'Croacia',
      CU: 'Cuba',
      CY: 'Chipre',
      CZ: 'República ceca',
      DK: 'Dinamarca',
      DJ: 'Gibuti',
      DM: 'Dominica',
      DO: 'República  dominicana',
      EC: 'Ecuador',
      EG: 'Egipto',
      SV: 'El Salvador',
      GQ: 'Guinea ecuatorial',
      ER: 'Eritrea',
      EE: 'Estonia',
      ET: 'Etiopía',
      FK: 'Islas Malvinas',
      FO: 'Islas Feroe',
      FJ: 'Fiyi',
      FI: 'Finlandia',
      FR: 'Francia',
      GF: 'Guyana Francesa',
      PF: 'Polinesia Francesa',
      TF: 'Tierras australes francesas',
      GA: 'Gabón',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Alemania',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Grecia',
      GL: 'Groenlandia',
      GD: 'Grenada',
      GP: 'Guadalupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bisau',
      GY: 'Guyana',
      HT: 'Haití',
      HM: 'Islas Heard y McDonald',
      VA: 'Santa Sede (Ciudad del Vaticano)',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungría',
      IS: 'Islandia',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Irán',
      IQ: 'Irak',
      IE: 'Irlanda',
      IM: 'Isla de Man',
      IL: 'Israel',
      IT: 'Italia',
      JM: 'Jamaica',
      JP: 'Japón',
      JE: 'Jersey',
      JO: 'Jordania',
      KZ: 'Kazajistán',
      KE: 'KenIa',
      KI: 'Kiribati',
      KR: 'Corea del Sud',
      KP: 'Corea del Nord',
      KW: 'Kuwait',
      KG: 'Kirguistán',
      LA: 'Laos',
      LV: 'Letonia',
      LB: 'Líbano',
      LS: 'Lesoto',
      LR: 'Liberia',
      LY: 'Libia',
      LI: 'Liechtenstein',
      LT: 'Lituania',
      LU: 'Luxemburgo',
      MO: 'Macao',
      MK: 'Macedonia',
      MG: 'Madagascar',
      MW: 'Malaui',
      MY: 'Malasia',
      MV: 'Maldivas',
      ML: 'Malí',
      MT: 'Malta',
      MH: 'Islas Marshall',
      MQ: 'Martinica',
      MR: 'Mauritania',
      MU: 'Mauricio',
      YT: 'Mayotte',
      MX: 'México',
      FM: 'Micronesia, Estados Federados de',
      MD: 'Moldavia',
      MC: 'Mónaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Marocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Países Bajos',
      AN: 'Antillas Holandesas',
      NC: 'Nueva Caledonia',
      NZ: 'Nueva Zelanda',
      NI: 'Nicaragua',
      NE: 'Níger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Isla Norfolk',
      MP: 'Marianas del norte',
      NO: 'Noruega',
      OM: 'Omán',
      PK: 'Pakistán',
      PW: 'Palaos',
      PS: 'Palestina',
      PA: 'Panamá',
      PG: 'Papúa Nueva Guinea',
      PY: 'Paraguay',
      PE: 'Perú',
      PH: 'Filipinas',
      PN: 'Pitcairn',
      PL: 'Pologna',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RE: 'Reunión',
      RO: 'Romania',
      RU: 'Rusia',
      RW: 'Ruanda',
      BL: 'Saint Barthelemy',
      SH: 'Santa Elena',
      KN: 'San Cristóbal y Nieves',
      LC: 'Santa Lucia',
      MF: 'San Martín',
      PM: 'San Pedro y Miquelón',
      VC: 'San Vicente y las Granadinas',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Santo Tomé y Príncipe',
      SA: 'Arabia Saudita',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leona',
      SG: 'Singapur',
      SK: 'Eslovaquia',
      SI: 'Eslovenia',
      SB: 'Islas Salomón',
      SO: 'Somalia',
      ZA: 'Sudafrica',
      GS: 'Islas Georgias del Sur y Sandwich Meridionales',
      ES: 'España',
      LK: 'Sri Lanka',
      SD: 'Sudán',
      SR: 'Surinam',
      SJ: 'Svalbard e isla de Jan Mayen',
      SZ: 'Suazilandia',
      SE: 'Suecia',
      CH: 'Suiza',
      SY: 'Siria',
      TW: 'Taiwán ',
      TJ: 'Tayikistán',
      TZ: 'Tanzania',
      TH: 'Tailandia',
      TL: 'Timor Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad y Tobago',
      TN: 'Túnez',
      TR: 'Turquía',
      TM: 'Turkmenistán',
      TC: 'Isole Turks e Caicos',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ucrania',
      AE: 'Emiratos Árabes Unidos',
      GB: 'Reino Unido',
      US: 'Estados Unidos',
      UM: 'Islas Ultramarinas Menores de EE.UU.',
      UY: 'Uruguay',
      UZ: 'Uzbekistán',
      VU: 'Vanuatu',
      VE: 'Venezuela',
      VN: 'Vietnam',
      VG: 'Islas Vírgenes Británicas ',
      VI: 'Islas Vírgenes Americanas',
      WF: 'Islas Wallis y Futuna',
      EH: 'Sahara Occidental',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabue',
      XX: 'Otro',
    };
  }
}
