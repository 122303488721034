import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { timer } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';
import { BaseComponent } from '../../../components/inherited/base/base.component';

import { Store } from '@ngrx/store';
import { HttpChallengeScoreService } from '../../../../core/services/http/http-challenge-score.service';
import { ChallengeSession } from '../../../models/entities/challenge-session.entity';
import { HttpChallengeSpecialtyScoreBadgeService } from '../../../../core/services/http/http-challenge-specialty-score-badge.service';
import { ChallengeSpecialtyScoreBadge } from '../../../models/entities/challenge-specialty-score-badge.entity';
import { GlobalChallengeScore } from '../../../models/entities/challenge-score.entity';
import { QueryData } from '../../../models/payloads/query-data.payload';

@Component({
  selector: 'app-intro-candidate-challenge',
  templateUrl: './intro-candidate-challenge.component.html',
  styleUrls: ['./intro-candidate-challenge.component.scss'],
})
export class IntroCandidateChallengeComponent extends BaseComponent implements OnInit {
  @Input() challengeSession: ChallengeSession;

  @Output() launchEmitter = new EventEmitter();
  @Output() exitEmitter = new EventEmitter();

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  globalChallengeScore: GlobalChallengeScore;
  challengeSpecialtyScoreBadges: ChallengeSpecialtyScoreBadge[];

  animationState = {
    discipline: false,
    versus: false,
    versusHeartbeat: false,
    user: false,
    challenger0: false,
    challenger1: false,
    challenger2: false,
    moreChallengers: false,
    panelLeft: false,
    panelRight: false,
    button: false,
  };

  isLoading = false;
  firstLoading = true;

  constructor(
    private store: Store,
    private httpChallengeScoreService: HttpChallengeScoreService,
    private httpChallengeSpecialtyScoreBadgeService: HttpChallengeSpecialtyScoreBadgeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.httpChallengeScoreService.getMyChallengeScores().subscribe((res) => {
      this.globalChallengeScore = new GlobalChallengeScore(res);
    });

    this.httpChallengeSpecialtyScoreBadgeService
      .listMyChallengeSpecialtyScoreBadges(new QueryData({ page: 1, perPage: 3 }, true))
      .subscribe((res) => {
        this.challengeSpecialtyScoreBadges = res['hydra:member'].map(
          (elt) => new ChallengeSpecialtyScoreBadge(elt)
        );
      });

    this.challengeSession.students.forEach((student) => {
      this.httpChallengeScoreService.getChallengeScoresOfStudent(student.id).subscribe((res) => {
        student.globalChallengeScore = new GlobalChallengeScore(res);
      });

      this.httpChallengeSpecialtyScoreBadgeService
        .listChallengeSpecialtyScoreBadgesOfStudent(
          student.id,
          new QueryData({ page: 1, perPage: 3 }, true)
        )
        .subscribe((res) => {
          student.challengeSpecialtyScoreBadges = res['hydra:member'].map(
            (elt) => new ChallengeSpecialtyScoreBadge(elt)
          );
        });
    });
  }

  launchAnimationsEntry() {
    this.firstLoading = false;
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.panelLeft = true;
          this.animationState.panelRight = true;
          this.animationState.button = true;
          return timer(100);
        }),
        concatMap(() => {
          this.animationState.discipline = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.user = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.versus = true;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.challenger0 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.challenger1 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.challenger2 = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.moreChallengers = true;
          return timer(150);
        }),
        concatMap(() => {
          this.animationState.versusHeartbeat = true;
          return timer(2000);
        }),
        concatMap(() => {
          this.launchAnimationsExit();
          return timer(150);
        }),
        take(1)
      )
      .subscribe();
  }

  launchAnimationsExit() {
    this.isLoading = true;
    this.launchEmitter.emit();
    timer(0)
      .pipe(
        concatMap(() => {
          this.animationState.versus = false;
          this.animationState.discipline = false;
          this.animationState.button = false;
          return timer(300);
        }),
        concatMap(() => {
          this.animationState.panelLeft = false;
          this.animationState.panelRight = false;
          this.animationState.versusHeartbeat = false;
          return timer(300);
        }),
        concatMap(() => {
          this.exitEmitter.emit();
          return timer(300);
        }),
        take(1)
      )
      .subscribe();
  }
}
