import { createReducer, on } from '@ngrx/store';
import { LoadingState } from '../../../shared/models/interfaces/call-state.interface';
import * as AuthActions from './auth.actions';
import { initialAuthState } from './auth.state';
import { Subscription } from '../../../shared/models/entities/subscription.entity';

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.noSessionToRecover, (state) => ({
    ...state,
    initializing: false,
  })),

  on(AuthActions.recoverSessionSucceed, (state, action) => ({
    ...state,
    initializing: false,
    student: action.student,
    activeSubscriptions: action.activeSubscriptions,
    studentSettings: action.studentSettings,
  })),

  on(AuthActions.login, AuthActions.SSOLoginWithSDK, AuthActions.activateAccount, (state) => ({
    ...state,
    student: undefined,
    studentSettings: undefined,
    loginState: LoadingState.LOADING,
  })),

  on(AuthActions.getMyStudentProfileAfterLogin, (state) => ({
    ...state,
    loginState: LoadingState.LOADING,
  })),

  on(AuthActions.getMyStudentProfileAfterLoginSucceed, (state, action) => ({
    ...state,
    student: action.student,
    studentSettings: action.studentSettings,
    activeSubscriptions: action.activeSubscriptions,
    loginState: LoadingState.LOADED,
  })),

  on(AuthActions.savePathToReachAfterLogin, (state, action) => ({
    ...state,
    pathToReachAfterLogin: action.path,
  })),

  on(AuthActions.navigateAfterLoginSucceed, (state) => ({
    ...state,
    pathToReachAfterLogin: undefined,
  })),

  on(AuthActions.loginFailed, (state, action) => ({
    ...state,
    loginState: { error: action.error },
  })),

  on(AuthActions.fillStudentInformationsAfterSSO, (state, action) => ({
    ...state,
    initializing: false,
  })),

  on(AuthActions.refreshToken, (state) => ({
    ...state,
    refreshingToken: true,
  })),

  on(AuthActions.refreshTokenSucceed, (state, action) => ({
    ...state,
    refreshingToken: false,
  })),

  on(AuthActions.refreshTokenFailed, (state) => ({
    ...state,
    refreshingToken: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    student: undefined,
    studentSettings: undefined,
    activeSubscriptions: undefined,
    writer: undefined,
    impersonateUser: undefined,
  })),

  on(AuthActions.updateMyUniversitySucceed, (state, action) => ({
    ...state,
    student: {
      ...state.student,
      graduationName: action.graduationName,
      university: action.university,
    },
  })),

  on(AuthActions.updateMyUsername, (state, action) => ({
    ...state,
    student: {
      ...state.student,
      username: action.formValue.username,
    },
    writer: state.writer
      ? {
          ...state.writer,
          username: action.formValue.username,
        }
      : undefined,
  })),

  on(AuthActions.updateMyEmail, (state, action) => ({
    ...state,
    student: {
      ...state.student,
      email: action.formValue.email,
    },
    writer: state.writer
      ? {
          ...state.writer,
          email: action.formValue.email,
        }
      : undefined,
  })),

  on(AuthActions.updateMyAddressAndInformations, (state, action) => ({
    ...state,
    student: {
      ...state.student,
      firstName: action.formValue.firstName,
      lastName: action.formValue.lastName,
      address: {
        ...state.student.address,
        street: action.formValue.street,
        zipCode: action.formValue.zipCode,
        city: action.formValue.city,
        country: action.formValue.country?.code,
      },
      phoneNumber: action.formValue.phoneNumber,
    },
    writer: state.writer
      ? {
          ...state.writer,
          firstName: action.formValue.firstName,
          lastName: action.formValue.lastName,
          address: {
            ...state.student.address,
            street: action.formValue.street,
            zipCode: action.formValue.zipCode,
            city: action.formValue.city,
            country: action.formValue.country?.code,
          },
          phoneNumber: action.formValue.phoneNumber,
        }
      : undefined,
  })),

  on(AuthActions.updateMyPicture, (state, action) => ({
    ...state,
    student: {
      ...state.student,
      picture: action.result.picture,
    },
    writer: state.writer
      ? {
          ...state.writer,
          picture: action.result.picture,
        }
      : undefined,
  })),

  on(AuthActions.updateMyStudentSettingsSucceed, (state, action) => ({
    ...state,
    studentSettings: {
      ...state.studentSettings,
      'hydra:member': state.studentSettings['hydra:member'].map((setting) => {
        if (setting.name === action.studentSettingName)
          return {
            ...setting,
            value: action.value,
          };
        else return setting;
      }),
    },
  })),

  on(AuthActions.updateMyWriterRedactionInformations, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      socialSecurityNumber: action.result.socialSecurityNumber,
    },
  })),

  on(AuthActions.updateMyBankAccount, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      bankAccount: {
        ...state.writer.bankAccount,
        ...action.result,
      },
    },
  })),

  on(AuthActions.associateSpecialtyToMe, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      specialties: [...state.writer.specialties, action.specialty],
    },
  })),

  on(AuthActions.dissociateSpecialtyFromMe, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      specialties: state.writer.specialties.filter(
        (specialty) => specialty.id !== action.specialty.id
      ),
    },
  })),

  on(AuthActions.addContractToMe, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      contracts: [...state.writer.contracts, action.contract],
    },
  })),

  on(AuthActions.deleteContractFromMe, (state, action) => ({
    ...state,
    writer: {
      ...state.writer,
      contracts: state.writer.contracts.filter((contract) => contract.id !== action.contract.id),
    },
  })),

  on(AuthActions.getMyWriterProfileSucceed, (state, action) => ({
    ...state,
    writer: action.result,
  })),

  on(AuthActions.addSubscriptionSucceed, (state, action) => ({
    ...state,
    activeSubscriptions: {
      ...state.activeSubscriptions,
      'hydra:totalItems': state.activeSubscriptions['hydra:totalItems'] + 1,
      'hydra:member': [...state.activeSubscriptions['hydra:member'], action.result],
    },
  })),

  on(AuthActions.updateMyActiveSubscriptionsSucceed, (state, action) => ({
    ...state,
    activeSubscriptions: action.activeSubscriptions,
  })),

  on(AuthActions.impersonateUser, (state, action) => ({
    ...state,
    impersonateUser: action.student,
  })),

  on(AuthActions.stopImpersonateUser, (state) => ({
    ...state,
    impersonateUser: undefined,
  }))
);
