import { FolderSessionResult } from '../api-results/folder-session.api-result';
import { ContentSessionType } from '../enums/session.enum';
import { ContentSession } from './content-session.entity';
import { ExamContentSession } from './exam-content-session.entity';
import { Folder } from './folder.entity';
import { SessionQuestion } from './session-question.entity';

export class FolderSession extends ContentSession {
  isCompleted: boolean;
  folder: Folder;
  rank: number;

  index: number;

  examContentSession: ExamContentSession;

  constructor(result: FolderSessionResult, examContentSession?: ExamContentSession) {
    super(result);
    this.isCompleted = result.isCompleted;

    if (result.folder) {
      this.folder = new Folder(result.folder);
      this.id = this.folder?.id;

      if (result.folderSessionQuestionAnswers) {
        this.questions = result.folderSessionQuestionAnswers.map((elt) => new SessionQuestion(elt));
        SessionQuestion.buildQuestionsFromSession(this.questions, this.folder.folderQuestions);
      }
    }

    this.rank = result.myRank;

    if (examContentSession) {
      this.setIndex(examContentSession);
    }

    if (result.examContentSession)
      this.examContentSession = new ExamContentSession(result.examContentSession);
  }

  get type() {
    return ContentSessionType.FOLDER;
  }

  get content() {
    return this.folder;
  }

  get contentQuestions() {
    return this.folder?.folderQuestions;
  }

  get maxGrade() {
    return this.folder?.maxGrade;
  }

  get studentRate() {
    return this.folder?.studentRate;
  }

  get courses() {
    return this.folder?.courses;
  }

  toResult(): FolderSessionResult {
    return {
      ...super.toResult(),
      isCompleted: this.isCompleted,
      folder: this.folder?.toResult(),
      myRank: this.rank,
      examContentSession: this.examContentSession?.toResult(),
    };
  }

  setIndex(examContentSession: ExamContentSession) {
    this.index = examContentSession.folderSessions.findIndex(
      (folderSession) => folderSession.folder.id === this.folder.id
    );
  }
}
