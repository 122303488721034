import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { App } from '@capacitor/app';

import { BackButtonState } from '../../shared/models/interfaces/back-button-state.interface';

import { IsApplicationService } from './is-application.service';

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  routerUrls: string[];
  panelsOpened: BackButtonState[];

  closePanel$ = new Subject<number>();

  constructor(
    private router: Router,
    private isApplicationService: IsApplicationService,
    private ngZone: NgZone
  ) {}

  init() {
    if (this.isApplicationService.isApplication()) {
      this.panelsOpened = [];
      this.routerUrls = [];

      this.initPushRouterState();

      App.addListener('backButton', () => {
        this.handleBackButton();
      });
    }
  }

  handleBackButton() {
    this.ngZone.run(() => {
      // si au moins un panel est ouvert : fermer le dernier panel
      if (this.panelsOpened.length) {
        this.closeLastPanel();
      }
      // sinon : naviguer en arrière
      else {
        this.navigateBack();
      }
    });
  }

  closeLastPanel() {
    const lastPanel = this.panelsOpened[this.panelsOpened.length - 1];
    this.closePanel$.next(lastPanel.creationDate);
  }

  navigateBack() {
    // si on a assez d'historique pour revenir en arrière
    if (this.routerUrls.length >= 2) {
      // supprimer le dernier url visité
      this.routerUrls.pop();
      // naviguer vers le dernier url et supprimer celui ci
      const url = this.routerUrls.pop();
      this.router.navigateByUrl(url);
    }
    // pas d'historique, on ferme l'app
    else {
      App.exitApp();
    }
  }

  initPushRouterState() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.routerUrls.push(event.urlAfterRedirects);

        if (this.routerUrls.length > 20) {
          this.routerUrls.shift();
        }
      });
  }

  pushFilterState(creationDate: number) {
    this.panelsOpened.push({
      creationDate,
    });
  }

  removePanelFromState(creationDate: number) {
    this.panelsOpened = this.panelsOpened.filter((panel) => {
      return panel.creationDate !== creationDate;
    });
  }
}
