import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ListStudentsResult,
  StudentResult,
} from '../../../shared/models/api-results/student.api-result';
import {
  StudentQueryData,
  StudentUniversityPayload,
  WStudentQueryData,
} from '../../../shared/models/payloads/student.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { UserInformationsPayload } from '../../../shared/models/payloads/user.payload';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { ListEcniUserTypeaheadResult } from '../../../shared/models/api-results/ecni-user.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpStudentService {
  constructor(private http: HttpClient) {}

  getMyStudentProfile() {
    return this.http
      .get(`${environment.api_base_url}/users/me/student_profile`)
      .pipe(map((res) => res as StudentResult));
  }

  updateMyUniversity(payload: StudentUniversityPayload) {
    return this.http.post(`${environment.api_base_url}/users/me/update_my_university`, payload);
  }

  getStudent(id: string) {
    return this.http
      .get(`${environment.api_base_url}/users/${id}/display_student_profile`)
      .pipe(map((res) => res as StudentResult));
  }

  list(params: StudentQueryData) {
    return this.http
      .get(`${environment.api_base_url}/users/search_students`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListStudentsResult));
  }

  // nnkitodo [v2later] vérifier que ce endpoint liste tous les students, y compris les writers
  wTypeaheadList(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/users/list_students_typehead`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListEcniUserTypeaheadResult));
  }

  wGet(id: string) {
    return this.http
      .get(`${environment.api_base_url}/users/${id}/student_information_detail`)
      .pipe(map((res) => res as StudentResult));
  }

  wList(params: WStudentQueryData) {
    return this.http
      .get(`${environment.api_base_url}/users/list_students_details`, {
        params: prepareQueryParams(params),
      })
      .pipe(
        map((res: any) => {
          res['hydra:member'] = res['hydra:member'].map((studentResult) => {
            studentResult.currentSubscriptions = studentResult.currentSubscriptions.map(
              (subscriptionResult) => {
                subscriptionResult.offer = {
                  tier: subscriptionResult.offerTier,
                };
                return subscriptionResult;
              }
            );
            return studentResult;
          });
          return res;
        }),
        map((res) => res as ListStudentsResult)
      );
  }

  wUpdateStudentUniversity(id: string, payload: StudentUniversityPayload) {
    return this.http.post(
      `${environment.api_base_url}/users/${id}/update_student_university`,
      payload
    );
  }
}
