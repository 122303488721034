import { EventEmitter, Injectable } from '@angular/core';

import { timer, BehaviorSubject, Subscription, of } from 'rxjs';

import { HttpChallengeService } from './http/http-challenge.service';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { Store } from '@ngrx/store';
import { AvailableChallengesResult } from '../../shared/models/api-results/challenge.api-result';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  checkTimeSubscription: Subscription;
  availableChallenges$ = new BehaviorSubject<AvailableChallengesResult>(null);
  reloadChallengeList = new EventEmitter();

  constructor(private store: Store, private httpChallengeService: HttpChallengeService) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateAvailableChallenges();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.availableChallenges$.next(null);
      }
    });
  }

  updateAvailableChallenges() {
    this.httpChallengeService.countAvailableChallenges().subscribe((res) => {
      this.availableChallenges$.next(res);
    });
  }
}
