import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-field-password',
  templateUrl: './form-field-password.component.html',
  styleUrls: ['./form-field-password.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class FormFieldPasswordComponent implements OnInit {
  @Input() label: string;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() autocomplete: string;

  displayPassword = false;

  constructor() {}

  ngOnInit(): void {}

  toggleDisplayPassword() {
    this.displayPassword = !this.displayPassword;
  }
}
