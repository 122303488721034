import { Component, Input, OnInit } from '@angular/core';
import { ChallengeBadgesStoreService } from './challenge-badges.component-store';

@Component({
  selector: 'app-challenge-badges',
  templateUrl: './challenge-badges.component.html',
  styleUrls: ['./challenge-badges.component.scss'],
  providers: [ChallengeBadgesStoreService],
})
export class ChallengeBadgesComponent implements OnInit {
  @Input() studentId;
  @Input() inModale = false;

  constructor(public componentStore: ChallengeBadgesStoreService) {}

  ngOnInit(): void {
    this.componentStore.init(this.studentId);
    this.componentStore.initList({});
  }

  scroll() {
    this.componentStore.scroll();
  }
}
