import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-initial',
  templateUrl: './loader-initial.component.html',
  styleUrls: ['./loader-initial.component.scss'],
})
export class LoaderInitialComponent implements OnInit {
  @Input() smallLoader = false;

  constructor() {}

  ngOnInit(): void {}
}
