import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { coordinatesToString, stringToCoordinates } from '../../utils/correction-utils';
import { getRandomInt } from '../../utils/letters-numbers-transformations';
import { AddedEntity } from '../api-results/base.api-result';
import { CorrectionResult } from '../api-results/correction.api-result';
import { CorrectionStatus, CorrectionType } from '../enums/correction.enums';
import {
  AddCoordinateCorrectionPayload,
  CorrectionFormValue,
  AddTextCorrectionPayload,
  UpdateTextCorrectionPayload,
  UpdateCoordinateCorrectionPayload,
  AddTCSCorrectionPayload,
  UpdateTCSCorrectionPayload,
} from '../payloads/correction.payload';
import moment from 'moment';

export class Correction {
  id: string;
  type: CorrectionType;
  status: CorrectionStatus;

  content: string;
  coordinates: CorrectionCoordinates[];
  coefficient: number;

  priority: number;

  createdAt: Date;
  updatedAt: Date;

  percentSelected: number;

  randomIndex = getRandomInt();
  isSelected = false;

  constructor(result: CorrectionResult) {
    this.id = result.id;
    this.type = result.type;
    this.content = result.content;
    this.status = result.status;
    if (result.coordinates) this.coordinates = stringToCoordinates(result.coordinates);
    this.coefficient = result.coefficient;

    this.priority = result.priority;

    if (result.createdAt) this.createdAt = moment(result.createdAt).toDate();
    if (result.updatedAt) this.updatedAt = moment(result.updatedAt).toDate();

    if (result.percentSelected !== undefined && result.percentSelected !== null)
      this.percentSelected = result.percentSelected;
  }

  get isCorrect() {
    return this.status === CorrectionStatus.WIN || this.status === CorrectionStatus.MANDATORY;
  }

  get displayContent() {
    if (this.status === CorrectionStatus.MANDATORY) {
      return `(${$localize`Indispensable`}) ${this.content}`;
    } else if (this.status === CorrectionStatus.ELIMINATORY) {
      return `(${$localize`Inacceptable`}) ${this.content}`;
    }
    return this.content;
  }

  // nnkitodo [v2later] j'avais enlevé ceci, il y a surement une raison?
  setStatus(status: CorrectionStatus) {
    this.status = status;
  }

  // nnkitodo [v2later] supprimer ceci et remplacer par stringToCoordinates
  setCoordinates(coordinates: string) {
    if (coordinates) {
      this.coordinates = stringToCoordinates(coordinates);
    }
  }

  setCoefficient(coefficient: number) {
    this.coefficient = coefficient;
  }

  toFormValue(): CorrectionFormValue {
    return {
      id: this.id,
      content: this.content,
      coordinates: coordinatesToString(this.coordinates),
      coefficient: this.coefficient,
      status: this.status,
      percentSelected: this.percentSelected,
    };
  }

  toResult(): CorrectionResult {
    return {
      id: this.id,
      type: this.type,
      status: this.status,
      content: this.content,
      coordinates: coordinatesToString(this.coordinates),
      coefficient: this.coefficient,
      priority: this.priority,
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
      updatedAt: this.updatedAt ? moment(this.updatedAt).format(API_DATETIME_FORMAT) : null,
      percentSelected: this.percentSelected,
    };
  }

  static addedTextCorrectionToResult(
    payload: AddTextCorrectionPayload,
    addedEntity: AddedEntity
  ): CorrectionResult {
    return {
      id: addedEntity.id,
      type: CorrectionType.TEXT,
      status: payload.status,
      content: payload.content,
      coordinates: null,
      coefficient: null,
      priority: payload.priority,
      updatedAt: undefined,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      percentSelected: null,
    };
  }

  static addedTCSCorrectionToResult(
    payload: AddTCSCorrectionPayload,
    addedEntity: AddedEntity
  ): CorrectionResult {
    return {
      id: addedEntity.id,
      type: CorrectionType.TCS,
      status: payload.status,
      content: payload.content,
      coordinates: null,
      coefficient: payload.coefficient,
      priority: payload.priority,
      updatedAt: undefined,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      percentSelected: null,
    };
  }

  static addedCoordinateCorrectionToResult(
    payload: AddCoordinateCorrectionPayload,
    addedEntity: AddedEntity
  ): CorrectionResult {
    return {
      id: addedEntity.id,
      type: CorrectionType.TEXT,
      status: CorrectionStatus.WIN,
      content: null,
      coordinates: payload.coordinates,
      coefficient: null,
      priority: undefined,
      updatedAt: undefined,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      percentSelected: null,
    };
  }

  static updatedTextCorrectionToResult(
    id: string,
    payload: UpdateTextCorrectionPayload
  ): Partial<CorrectionResult> {
    return {
      id,
      status: payload.status,
      content: payload.content,
      updatedAt: moment(new Date()).format(API_DATETIME_FORMAT),
    };
  }

  static updatedTCSCorrectionToResult(
    id: string,
    payload: UpdateTCSCorrectionPayload
  ): Partial<CorrectionResult> {
    return {
      id,
      status: payload.status,
      content: payload.content,
      coefficient: payload.coefficient,
      updatedAt: moment(new Date()).format(API_DATETIME_FORMAT),
    };
  }

  static updatedCoordinateCorrectionToResult(
    id: string,
    payload: UpdateCoordinateCorrectionPayload
  ): Partial<CorrectionResult> {
    return {
      id,
      coordinates: payload.coordinates,
      updatedAt: moment(new Date()).format(API_DATETIME_FORMAT),
    };
  }
}

export interface CorrectionCoordinates {
  x: number;
  y: number;
}

// nnkitodo [v2later] supprimer ceci et remplacer par stringToCoordinates
export function convertStringToCorrectionCoordinates(
  stringCoordinates: string
): CorrectionCoordinates {
  if (!stringCoordinates) return null;
  const coordinates = stringCoordinates.split(':');
  if (coordinates.length !== 2) return null;
  return {
    x: Number(coordinates[0]),
    y: Number(coordinates[1]),
  };
}
