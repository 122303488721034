import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  ApiError,
  convertHttpErrorToApiError,
} from '../../shared/models/api-results/error.api-result';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toastr: ToastrService) {}

  // nnkitodo [v2later] utiliser partout et catcher les erreurs globalement ?
  toastError(error: any) {
    const apiError = convertHttpErrorToApiError(error);
    this.toastr.error($localize`Erreur ${apiError.code}`, apiError.message);
  }
}
