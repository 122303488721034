import { GraduationNameResult } from '../api-results/graduation-name.api-result';
import {
  GraduationNameFormValue,
  GraduationNamePayload,
} from '../payloads/graduation-name.payload';

export class GraduationName {
  id: string;
  name: string;

  constructor(result: GraduationNameResult) {
    this.id = result.id;
    this.name = result.name;
  }

  toFormValue(): GraduationNameFormValue {
    return {
      name: this.name,
    };
  }

  toResult(): GraduationNameResult {
    return {
      id: this.id,
      name: this.name,
    };
  }

  static updatedEntityToResult(payload: GraduationNamePayload): Partial<GraduationNameResult> {
    return {
      name: payload.name,
    };
  }
}
