import { Pipe, PipeTransform } from '@angular/core';
import { Student } from '../../models/entities/student.entity';

@Pipe({
  name: 'filterLoggedUser',
})
export class FilterLoggedUserPipe implements PipeTransform {
  transform(value: Student[], loggedUserId: string): Student[] {
    return value.filter((user) => user.id !== loggedUserId);
  }
}
