import moment from 'moment';
import {
  KnowledgeAnchoringBreakResult,
  KnowledgeAnchoringLastRepetitionDateResult,
  KnowledgeAnchoringPlanningResult,
  KnowledgeAnchoringStatisticsResult,
  WKnowledgeAnchoringRepetitionResult,
  WKnowledgeAnchoringStatisticssResult,
} from '../api-results/knowledge-anchoring.api-result';
import { RememberingLevel } from '../enums/knowledge-anchoring.enums';

export class KnowledgeAnchoringStatistics {
  totalPublishedOnTrainingQuestions: number;
  totalAnchoredQuestions: number;
  totalAnchoringInProgressQuestions: number;
  totalRejectedQuestions: number;

  constructor(result: KnowledgeAnchoringStatisticsResult) {
    this.totalPublishedOnTrainingQuestions = result.totalPublishedOnTrainingQuestions;
    this.totalAnchoredQuestions = result.totalAnchoredQuestions;
    this.totalAnchoringInProgressQuestions = result.totalAnchoringInProgressQuestions;
    this.totalRejectedQuestions = result.totalRejectedQuestions;
  }

  get totalProcessedQuestions() {
    return (
      this.totalAnchoredQuestions +
      this.totalAnchoringInProgressQuestions +
      this.totalRejectedQuestions
    );
  }
}

export class KnowledgeAnchoringPlanning {
  totalRepetitionsLate: number;
  totalRepetitionsPlannedToday: number;
  totalRepetitionsPlannedTomorrow: number;
  totalRepetitionsPlannedLaterThisWeek: number;
  anchoringBreak: KnowledgeAnchoringBreak;

  constructor(result: KnowledgeAnchoringPlanningResult) {
    this.totalRepetitionsLate = result.totalRepetitionsLate;
    this.totalRepetitionsPlannedToday = result.totalRepetitionsPlannedToday;
    this.totalRepetitionsPlannedTomorrow = result.totalRepetitionsPlannedTomorrow;
    this.totalRepetitionsPlannedLaterThisWeek = result.totalRepetitionsPlannedLaterThisWeek;
    if (result.anchoringBreak)
      this.anchoringBreak = new KnowledgeAnchoringBreak(result.anchoringBreak);
  }

  get totalRevisionsTodo() {
    if (this.anchoringBreak?.current || this.anchoringBreak?.recomputing) return 0;
    return this.totalRepetitionsLate + this.totalRepetitionsPlannedToday;
  }
}

export class KnowledgeAnchoringBreak {
  id: string;
  startAt: Date;
  endAt: Date;
  current: boolean;
  recomputing: boolean;

  constructor(result: KnowledgeAnchoringBreakResult) {
    this.id = result.id;
    if (result.startAt) this.startAt = moment(result.startAt).toDate();
    if (result.endAt) this.endAt = moment(result.endAt).toDate();
    this.current = result.current;
    this.recomputing = result.recomputing;
  }
}

export class KnowledgeAnchoringLastRepetitionDate {
  lastAnsweredAt: Date;

  constructor(result: KnowledgeAnchoringLastRepetitionDateResult) {
    if (result.lastAnsweredAt) this.lastAnsweredAt = moment(result.lastAnsweredAt).toDate();
  }
}

export class WKnowledgeAnchoringStatistics {
  progress: number;
  rememberingLevelZeroCount: number;
  rememberingLevelFailCount: number;
  rememberingLevelBadCount: number;
  rememberingLevelPassCount: number;
  rememberingLevelGoodCount: number;
  rememberingLevelGreatCount: number;

  constructor(result: WKnowledgeAnchoringStatisticssResult) {
    this.progress = result.progress;
    this.rememberingLevelZeroCount = result.rememberingLevelZeroCount;
    this.rememberingLevelFailCount = result.rememberingLevelFailCount;
    this.rememberingLevelBadCount = result.rememberingLevelBadCount;
    this.rememberingLevelPassCount = result.rememberingLevelPassCount;
    this.rememberingLevelGoodCount = result.rememberingLevelGoodCount;
    this.rememberingLevelGreatCount = result.rememberingLevelGreatCount;
  }
}

export class WKnowledgeAnchoringRepetition {
  answeredAt: Date;
  resetAt: Date;
  shouldBeAnsweredAt: Date;
  interval: number;
  rememberingLevel: RememberingLevel;
  progress: number;
  difficulty: number;
  retrievability: number;
  stability: number;

  constructor(result: WKnowledgeAnchoringRepetitionResult) {
    if (result.answeredAt) this.answeredAt = moment(result.answeredAt).toDate();
    if (result.resetAt) this.resetAt = moment(result.resetAt).toDate();
    if (result.shouldBeAnsweredAt)
      this.shouldBeAnsweredAt = moment(result.shouldBeAnsweredAt).toDate();
    this.rememberingLevel = result.rememberingLevel;
    this.interval = result.interval;
    this.difficulty = result.difficulty;
    this.progress = result.progress;
    this.retrievability = result.retrievability;
    this.stability = result.stability;
  }
}
