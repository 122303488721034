import { SeoService } from './core/services/seo.service';
import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { locale } from 'moment';
import { filter, take, takeUntil } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { AppPushNotificationsService } from './core/services/app-push-notifications.service';
import { FrontAppVersionAppService } from './core/services/front-app-version-app.service';
import { BackButtonService } from './core/services/back-button.service';
import { ChallengeService } from './core/services/challenge.service';
import { ColorService } from './core/services/color.service';
import { CookiesService } from './core/services/cookies.service';
import { InternetConnectionService } from './core/services/internet-connection.service';
import { IsApplicationService } from './core/services/is-application.service';
import { LanguageService } from './core/services/language.service';
import { ConversationService } from './core/services/conversation.service';
import { FrontAppVersionSiteService } from './core/services/front-app-version-site.service';
import { Store } from '@ngrx/store';
import * as AuthSelectors from './core/state/auth-state/auth.selectors';
import * as AuthActions from './core/state/auth-state/auth.actions';
import { FeedbackService } from './core/services/feedback.service';
import { screenWidthConstants } from './shared/utils/screen-sizes';
import * as Sentry from '@sentry/angular';
import { KnowledgeAnchoringService } from './core/services/knowledge-anchoring.service';
import { WebsiteNotificationsService } from './core/services/website-notifications.service';
import { Intercom, update } from '@intercom/messenger-js-sdk';
import { EnvironmentService } from './core/services/environment.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalAcceptNotificationsComponent } from './shared/modals/modal-accept-notifications/modal-accept-notifications.component';

// nnkitodo [v2later] pourquoi ?
locale(environment.locale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  appIsInitializing$ = this.store.select(AuthSelectors.initializing);
  appIsLoading = true;

  impersonateUser$ = this.store.select(AuthSelectors.impersonateUser);

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private appPushNotificationsService: AppPushNotificationsService,
    private backButtonService: BackButtonService,
    private challengeService: ChallengeService,
    private colorService: ColorService,
    private conversationService: ConversationService,
    public cookiesService: CookiesService,
    private environmentService: EnvironmentService,
    private feedbackService: FeedbackService,
    private frontAppVersionAppService: FrontAppVersionAppService,
    private frontAppVersionSiteService: FrontAppVersionSiteService,
    public internetConnectionService: InternetConnectionService,
    private isApplicationService: IsApplicationService,
    private knowledgeAnchoringService: KnowledgeAnchoringService,
    public languageService: LanguageService,
    private seoService: SeoService,
    private websiteNotificationsService: WebsiteNotificationsService
  ) {
    this.translate.setDefaultLang(environment.defaultLanguage);

    // nnkitodo [v2later] utile ?
    Sentry.getCurrentScope().setTag('locale', environment.defaultLanguage);

    Intercom({
      app_id: environment.intercomAppId,
      custom_launcher_selector: '#intercom_mobile_launcher',
      hide_default_launcher: this.hideDefaultIntercomLauncher,
      language_override: environment.defaultLanguage,
      version: `${this.isApplicationService.isApplication() ? 'App' : 'Desktop'} ${
        this.environmentService.stringifiedFrontAppVersion
      }`,
      current_platform: this.isApplicationService.isApplication()
        ? this.isApplicationService.platform
        : 'web',
    });

    if (this.isApplicationService.isApplication()) {
      SplashScreen.hide();
      StatusBar.setBackgroundColor({
        color: this.colorService.getSiteMainColor(),
      });

      // handle deeplinks
      App.addListener('appUrlOpen', (data: any) => {
        this.ngZone.run(() => {
          const slug = data.url.split(environment.domain).pop();
          if (slug) {
            this.router.navigateByUrl(slug);
          }
        });
      });
    }

    this.appPushNotificationsService.init();
    this.backButtonService.init();
    this.challengeService.init();
    this.conversationService.init();
    this.cookiesService.init();
    this.colorService.init();
    this.feedbackService.init();
    this.frontAppVersionAppService.init();
    this.frontAppVersionSiteService.init();
    this.internetConnectionService.init();
    this.knowledgeAnchoringService.init();
    this.websiteNotificationsService.init();

    this.appIsInitializing$
      .pipe(
        filter((initializing) => !initializing),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.appIsLoading = false;
        }, 1000);
      });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      update({});
      this.computeIntercomDisplay();
    });

    // nnkitodo [v2later] il faudrait un moyen de récupérer si la sub en cours est en PAYMENT_FAILED, pour afficher le warning
    //   if (this.loginState === LOGIN_STATE.LOGGED) {
    //     if (!this.userService.loggedUserValue.paid) {
    //       forkJoin([
    //         this.translate.get('root.mensualite'),
    //         this.translate.get('root.mensualite2'),
    //         this.translate.get('root.parametres'),
    //       ]).subscribe(([trad1, trad2, trad3]) => {
    //         const modalData: ModalWarningData = {
    //           title: trad1,
    //           content: trad2,
    //           buttonText: trad3,
    //           closeAction: ModalActions.actionNavigation,
    //           routerLink: ['/app/settings'],
    //         };

    //           this.matDialog.open(ModalWarningComponent, {
    //             maxWidth: '100vw',
    //             data: modalData,
    //           });
    //         });
    //       }
    //     }
    // });
  }

  ngOnInit() {
    this.seoService.init();
  }

  // for intercom : when messenger is added to the dom, we need to change its bottom/top css properties for the iphone notch
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.isApplicationService.isApplication()) {
        const safeTop = getComputedStyle(document.documentElement).getPropertyValue(
          '--safe-area-inset-top'
        );
        const safeBottom = getComputedStyle(document.documentElement).getPropertyValue(
          '--safe-area-inset-bottom'
        );
        setInterval(() => {
          const intercomContainer = document.getElementById('intercom-container');
          if (intercomContainer) {
            const intercomMessengerFrame = document.getElementsByClassName(
              'intercom-messenger-frame'
            );
            if (intercomMessengerFrame.length) {
              (
                intercomMessengerFrame[0] as any
              ).style.cssText = `background-color: ${this.colorService.getSiteMainColor()}`;
              const iframe = intercomMessengerFrame[0].firstChild;
              if (iframe) {
                (
                  iframe as any
                ).style.cssText = `top: ${safeTop} !important; bottom: ${safeBottom} !important; height: calc(100% - ${safeTop} - ${safeBottom}) !important`;
              }
            }
          }
        }, 1000);
      }
    }, 5000);
    // nnkitodo [v2later] on peut aussi tenter ça à la place ?
    // if (this.isApplicationService.isApplication()) {
    //   const safeTop = getComputedStyle(document.documentElement).getPropertyValue(
    //     '--safe-area-inset-top'
    //   );
    //   const safeBottom = getComputedStyle(document.documentElement).getPropertyValue(
    //     '--safe-area-inset-bottom'
    //   );
    //   window.intercomSettings = {
    //     app_id: 'abc123',
    //     alignment: 'left',
    //     horizontal_padding: 20,
    //     vertical_padding: Math.max(safeTop, safeBottom),

    //     z_index: -15
    //   };
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.computeIntercomDisplay();
  }

  get hideDefaultIntercomLauncher() {
    return (
      this.isApplicationService.isMobileDevice ||
      window.innerWidth < screenWidthConstants.screenMD ||
      this.router.url.search('/app/admin') !== -1
    );
  }

  computeIntercomDisplay() {
    update({ hide_default_launcher: this.hideDefaultIntercomLauncher });
  }

  stopImpersonateUser() {
    this.impersonateUser$.pipe(take(1)).subscribe((student) => {
      this.store.dispatch(AuthActions.stopImpersonateUser({ student: student.toResult() }));
    });
  }
}
