import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { get } from 'scriptjs';

import { environment } from '../../../environments/environment';

import { IsApplicationService } from './is-application.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SSOAuthResult, SSOLoginType } from '../../shared/models/interfaces/sso.interface';

@Injectable({
  providedIn: 'root',
})
export class SSOGoogleService {
  constructor(private isApplicationService: IsApplicationService) {}

  login(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    if (this.isApplicationService.isApplication()) {
      return this.loginFromApplication();
    } else {
      return this.loginFromDesktop();
    }
  }

  loginFromApplication(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    return new Observable((observer) => {
      GoogleAuth.initialize().then(
        () => {
          GoogleAuth.signIn().then(
            (googleUser) => {
              if (googleUser?.authentication?.accessToken) {
                observer.next({
                  ssoAuthResult: {
                    authResponse: {
                      accessToken: googleUser.authentication.accessToken,
                      userID: undefined,
                    },
                  },
                  loginType: SSOLoginType.DEFAULT,
                });
              } else {
                observer.error($localize`errGGLCO3-Erreur lors de la connexion`);
              }
            },
            (err) => {
              console.error(err);
              observer.error($localize`errGGLCO1-Erreur lors de la connexion`);
            }
          );
        },
        (err) => {
          console.error(err);
          observer.error($localize`errGGLCO2-Erreur lors de la connexion`);
        }
      );
    });
  }

  loginFromDesktop(): Observable<{
    ssoAuthResult: SSOAuthResult;
    loginType: SSOLoginType;
  }> {
    return new Observable((observer) => {
      get('https://accounts.google.com/gsi/client', () => {
        const client = google.accounts.oauth2.initTokenClient({
          client_id: environment.google_web_id,
          scope: 'email profile',
          callback: (tokenResponse) => {
            observer.next({
              ssoAuthResult: {
                authResponse: {
                  accessToken: tokenResponse.access_token,
                  userID: undefined,
                },
              },
              loginType: SSOLoginType.DEFAULT,
            });
          },
          error_callback: (err) => {
            console.error(err);
            observer.error(err.message);
          },
        });

        client.requestAccessToken();
      });
    });
  }

  logoutFromApplication() {
    GoogleAuth.initialize().then(() => {
      GoogleAuth.signOut();
    });
  }
}
