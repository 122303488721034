import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  UserAddressPayload,
  UserEmailPayload,
  UserInformationsPayload,
  UserIsActivePayload,
  UserIsLockedPayload,
  UserPicturePayload,
  UserUsernamePayload,
} from '../../../shared/models/payloads/user.payload';
import { convertToFormData } from '../../../shared/utils/api-transformations';
import { map } from 'rxjs';
import { UpdateUserPictureResult } from '../../../shared/models/api-results/ecni-user.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpUserService {
  constructor(private http: HttpClient) {}

  updateMyInformations(payload: UserInformationsPayload) {
    return this.http.post(`${environment.api_base_url}/users/me/update_my_information`, payload);
  }

  updateMyAddress(payload: UserAddressPayload) {
    return this.http.post(`${environment.api_base_url}/users/me/update_my_address`, payload);
  }

  wUpdateMyUsername(payload: UserUsernamePayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/me/request_update_my_credential_username`,
      payload
    );
  }

  updateMyEmail(payload: UserEmailPayload) {
    return this.http.post(
      `${environment.api_base_url}/credentials/me/request_change_my_credential_email`,
      payload
    );
  }

  confirmMyEmailUpdate(token: string) {
    return this.http.get(
      `${environment.api_base_url}/credentials/confirm_credential_email_change`,
      { params: { token } }
    );
  }

  updateMyPicture(payload: UserPicturePayload) {
    return this.http
      .post(
        `${environment.api_base_url}/users/me/add_my_credential_picture`,
        convertToFormData(payload)
      )
      .pipe(map((res) => res as UpdateUserPictureResult));
  }

  deleteMyPicture() {
    return this.http.post(`${environment.api_base_url}/users/me/delete_my_credential_picture`, {});
  }

  wDelete(id: string) {
    return this.http.post(`${environment.api_base_url}/credentials/${id}/delete_account`, {});
  }

  wUpdateUserIsLocked(id: string, payload: UserIsLockedPayload) {
    return this.http.post(`${environment.api_base_url}/users/${id}/update_locked`, payload);
  }

  wUpdateUserIsActive(id: string, payload: UserIsActivePayload) {
    return this.http.post(
      `${environment.api_base_url}/users/${id}/update_credential_is_active`,
      payload
    );
  }

  wUpdateUserUsername(id: string, payload: UserUsernamePayload) {
    return this.http.post(
      `${environment.api_base_url}/users/${id}/update_credential_username`,
      payload
    );
  }

  wUpdateUserEmail(id: string, payload: UserEmailPayload) {
    return this.http.post(`${environment.api_base_url}/credentials/${id}/change_email`, payload);
  }

  wUpdateUserPicture(id: string, payload: UserPicturePayload) {
    return this.http
      .post(
        `${environment.api_base_url}/users/${id}/add_credential_picture`,
        convertToFormData(payload)
      )
      .pipe(map((res) => res as UpdateUserPictureResult));
  }

  wDeleteUserPicture(id: string) {
    return this.http.post(
      `${environment.api_base_url}/users/${id}/delete_credential_picture_file`,
      {}
    );
  }

  wUpdateUserInformations(id: string, payload: UserInformationsPayload) {
    return this.http.post(
      `${environment.api_base_url}/users/${id}/update_user_information`,
      payload
    );
  }

  wUpdateUserAddress(id: string, payload: UserAddressPayload) {
    return this.http.post(`${environment.api_base_url}/users/${id}/update_user_address`, payload);
  }
}
