import { HttpParams } from '@capacitor/core';
import { QueryData } from '../models/payloads/query-data.payload';

export function prepareQueryParams(params: QueryData) {
  return stripEmptyQueryParams(convertSortBy({ ...params })) as HttpParams;
}

export function stripEmptyQueryParams(params: Object) {
  for (let i in params) {
    if (params[i] === '' || params[i] === null || params[i] === undefined) {
      delete params[i];
    }
  }
  return params;
}

function convertSortBy(params: any) {
  if (params.sortBy) {
    if (!params.sortByOrder) params.sortByOrder = 'asc';
    params[`sortBy[${params.sortBy}]`] = params.sortByOrder;
  }
  delete params.sortBy;
  delete params.sortByOrder;
  return params;
}

export function convertToFormData(payload: any) {
  const formData = new FormData();
  for (let key in payload) {
    if (!Array.isArray(payload[key])) {
      formData.append(key, payload[key]);
    } else {
      payload[key].forEach((val) => {
        formData.append(`${key}[]`, val);
      });
    }
  }
  return formData;
}
