import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AvailableChallengesResult } from '../../../shared/models/api-results/challenge.api-result';
import { ChallengePayload } from '../../../shared/models/payloads/challenge.payload';
import { ContentQuestionResult } from '../../../shared/models/api-results/content-question.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpChallengeService {
  constructor(private http: HttpClient) {}

  countAvailableChallenges() {
    return this.http
      .get(`${environment.api_base_url}/challenges/me/count_available_challenges`)
      .pipe(map((res) => res as AvailableChallengesResult));
  }

  add(payload: ChallengePayload) {
    return this.http.post(`${environment.api_base_url}/challenges/add_challenge`, payload).pipe(
      map(
        (res) =>
          res as {
            id: string;
            challengeQuestions: ContentQuestionResult[];
          }
      )
    );
  }
}
