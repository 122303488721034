import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'list',
})
export class ListPipe implements PipeTransform {
  constructor() {}

  public transform(value: any[], field: string): string {
    return value.map((elt) => elt[field]).join(', ');
  }
}
