import { DecimalPipe } from '@angular/common';
import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { timer, BehaviorSubject } from 'rxjs';
import { filter, finalize, take, takeUntil } from 'rxjs/operators';
import { PusherService } from '../../../core/services/pusher.service';
import { SoundService } from '../../../core/services/sound.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import { ModalWarningComponent, ModalWarningData } from '../modal-warning/modal-warning.component';

import { IntroCandidateChallengeComponent } from './intro-candidate-challenge/intro-candidate-challenge.component';
import { HttpChallengeSessionService } from '../../../core/services/http/http-challenge-session.service';
import { ChallengeSession } from '../../models/entities/challenge-session.entity';
import { SessionStatus, SessionType } from '../../models/enums/session.enum';
import { ChallengeQuestion } from '../../models/entities/challenge-question.entity';
import { IntroQuestionChallengeComponent } from './intro-question-challenge/intro-question-challenge.component';
import { QuestionChallengeComponent } from './question-challenge/question-challenge.component';
import { CHALLENGE_INITIAL_TIMER_DURATION } from '../../constants/sessions';
import { EndChallengeComponent } from './end-challenge/end-challenge.component';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { ErrorService } from '../../../core/services/error.service';
import { ChallengeService } from '../../../core/services/challenge.service';
import { ContentQuestionResult } from '../../models/api-results/content-question.api-result';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { Store } from '@ngrx/store';
import { PluginListenerHandle } from '@capacitor/core';
import { App } from '@capacitor/app';

export interface ModalCandidateChallengeData {
  challengeId: string;
  challengeQuestions: ContentQuestionResult[];
}

@Component({
  selector: 'app-modal-candidate-challenge',
  templateUrl: './modal-candidate-challenge.component.html',
  styleUrls: ['./modal-candidate-challenge.component.scss'],
  providers: [DecimalPipe],
})
export class ModalCandidateChallengeComponent extends BaseComponent implements OnInit, OnDestroy {
  modalMode: 'intro' | 'question-intro' | 'question' | 'end' = 'intro';

  challengeSession: ChallengeSession;

  challengeIsReady = new BehaviorSubject<boolean>(false);
  sessionIsOpened = false;
  initialRemainingTime: number;
  initialTimestamp: number;
  remainingSeconds: number;

  percentTimeElapsed$ = new BehaviorSubject<number>(0);

  currentChallengeQuestion: ChallengeQuestion;

  appResumeEventListener: PluginListenerHandle;

  @ViewChild(IntroCandidateChallengeComponent)
  introCandidateChallenge: IntroCandidateChallengeComponent;
  @ViewChild(IntroQuestionChallengeComponent)
  introQuestionChallenge: IntroQuestionChallengeComponent;
  @ViewChild(QuestionChallengeComponent) questionChallengeForm: QuestionChallengeComponent;
  @ViewChild(EndChallengeComponent) endChallenge: EndChallengeComponent;

  @HostBinding('attr.id') modalId = 'modalCandidateChallenge';

  constructor(
    public dialogRef: MatDialogRef<ModalCandidateChallengeComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalCandidateChallengeData,
    private decimalPipe: DecimalPipe,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService,
    private challengeService: ChallengeService,
    private errorService: ErrorService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private pusherService: PusherService,
    private soundService: SoundService,
    private httpChallengeSessionService: HttpChallengeSessionService
  ) {
    super();
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.createAppResumeListener();

    this.httpChallengeSessionService.get(this.modalData.challengeId).subscribe((res) => {
      this.challengeSession = new ChallengeSession(res);

      setTimeout(() => {
        this.introCandidateChallenge.launchAnimationsEntry();
      });
    });

    this.soundService.isChallengeMusicActivated().subscribe((challengeMusicActivated) => {
      if (challengeMusicActivated) {
        this.soundService.initSound(
          'challenge-music',
          'assets/sounds/dr_house_theme.mp3',
          true,
          0.6
        );
        this.soundService.initSound(
          'challenge-right-answer',
          'assets/sounds/right_answer.mp3',
          false
        );
        this.soundService.initSound(
          'challenge-wrong-answer',
          'assets/sounds/wrong_answer.mp3',
          false
        );
        this.soundService.initSound('challenge-applause', 'assets/sounds/applause.mp3', false);
        this.soundService.playSound('challenge-music');
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.appResumeEventListener) {
      this.appResumeEventListener.remove();
    }

    if (this.sessionIsOpened) {
      this.sessionIsOpened = false;

      this.httpChallengeSessionService
        .pause(this.modalData.challengeId)
        .pipe(
          finalize(() => {
            this.pusherService.unsubscribe();
          })
        )
        .subscribe(() => {
          this.toastr.success(this.translate.instant('examens.pause_ok'));
          this.googleAnalyticsService.sendEvent(
            'pause',
            'challenge',
            this.challengeSession.challenge.id
          );
        });
    }

    this.soundService.stopSound('challenge-music');
  }

  async createAppResumeListener() {
    this.appResumeEventListener = await App.addListener('resume', () => {
      this.ngZone.run(() => {
        if (this.sessionIsOpened && this.challengeSession) {
          this.httpChallengeSessionService.get(this.challengeSession.challenge.id).subscribe({
            next: (challengeSession) => {
              if (challengeSession.status === SessionStatus.PAUSED) {
                this.httpChallengeSessionService
                  .resume(this.challengeSession.challenge.id)
                  .subscribe({
                    error: (err) => {
                      this.errorService.toastError(err);
                      this.sessionIsOpened = false;
                      this.exitSession();
                    },
                  });
              } else if (challengeSession.status === SessionStatus.CLOSED) {
                this.errorService.toastError($localize`Erreur lors de la reprise de l'examen`);
                this.sessionIsOpened = false;
                this.exitSession();
              }
            },
            error: () => {
              this.errorService.toastError($localize`Erreur lors de la reprise de l'examen`);
              this.sessionIsOpened = false;
              this.exitSession();
            },
          });
        }
      });
    });
  }

  close() {
    let canClose = false;
    if (this.sessionIsOpened) {
      if (confirm(this.translate.instant('quit.confirm'))) {
        canClose = true;
      }
    } else {
      canClose = true;
    }
    if (canClose) {
      this.dialogRef.close();
    }
  }

  initSession() {
    this.remainingSeconds = this.challengeSession.remainingTime;
    this.initialRemainingTime = this.remainingSeconds;
    if (this.challengeSession.status === SessionStatus.OPEN) {
      if (this.modalData.challengeQuestions) {
        this.challengeSession.setChallengeQuestions(this.modalData.challengeQuestions);
        this.challengeSession.setChallengeQuestionAnswers([]);
        this.startSession();
      } else {
        this.httpChallengeSessionService.pause(this.modalData.challengeId).subscribe({
          next: () => {
            this.resumeSession();
          },
          error: () => {
            this.errorService.toastError($localize`Erreur lors de la reprise de l'examen`);
            this.exitSession();
          },
        });
      }
      this.googleAnalyticsService.sendEvent(
        'start',
        'challenge',
        this.challengeSession.challenge.id
      );
    } else if (this.challengeSession.status === SessionStatus.PAUSED) {
      this.resumeSession();
    } else if (this.challengeSession.status === SessionStatus.CLOSED) {
      this.errorService.toastError($localize`Erreur lors de la reprise de l'examen`);
      this.exitSession();
    }
  }

  resumeSession() {
    this.httpChallengeSessionService.resume(this.modalData.challengeId).subscribe((res) => {
      this.challengeSession.setChallengeQuestions(res.challengeQuestions);
      this.challengeSession.setChallengeQuestionAnswers(res.challengeSessionQuestionAnswers);
      this.googleAnalyticsService.sendEvent(
        'resume',
        'challenge',
        this.challengeSession.challenge.id
      );
      this.startSession();
    });
  }

  startSession() {
    this.initialTimestamp = new Date().getTime();
    timer(0, 1000)
      .pipe(takeUntil(this.alive$))
      .subscribe(() => {
        this.updateRemainingSeconds();
      });

    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(take(1))
      .subscribe((loggedStudent) => {
        this.pusherService.subscribe(
          SessionType.CHALLENGE,
          this.modalData.challengeId,
          loggedStudent.id
        );

        this.sessionIsOpened = true;
        this.challengeIsReady.next(true);
      });
  }

  exitSession() {
    this.challengeService.reloadChallengeList.emit();
    this.close();
  }

  updateRemainingSeconds() {
    this.remainingSeconds =
      this.initialRemainingTime - (new Date().getTime() - this.initialTimestamp) / 1000;

    this.percentTimeElapsed$.next(
      (CHALLENGE_INITIAL_TIMER_DURATION - Math.max(this.remainingSeconds, 0)) /
        CHALLENGE_INITIAL_TIMER_DURATION
    );
    if (this.remainingSeconds <= 0 && this.sessionIsOpened) {
      // this.openTimesUpModal();
    }
  }

  launchChallenge() {
    this.challengeIsReady
      .pipe(
        filter((challengeIsReady) => challengeIsReady),
        take(1)
      )
      .subscribe(() => {
        this.launchNextChallengeQuestionIntro();
      });
  }

  launchNextChallengeQuestionIntro() {
    this.currentChallengeQuestion = this.challengeSession.challengeQuestions.find(
      (challengeQuestion) => !challengeQuestion.question.isAnswered
    );

    if (!this.currentChallengeQuestion) {
      this.closeSession(true);
    } else {
      this.modalMode = 'question-intro';
      setTimeout(() => {
        this.introQuestionChallenge.launchAnimationsEntry();
      });
    }
  }

  launchCurrentChallengeQuestionForm() {
    this.modalMode = 'question';
    setTimeout(() => {
      this.questionChallengeForm.launchAnimationsEntry();
    });
  }

  closeSession(showResult: boolean) {
    this.sessionIsOpened = false;
    this.httpChallengeSessionService
      .close(this.modalData.challengeId)
      .pipe(
        finalize(() => {
          this.pusherService.unsubscribe();
        })
      )
      .subscribe(() => {
        this.googleAnalyticsService.sendEvent(
          'close',
          'challenge',
          this.challengeSession.challenge.id
        );

        if (showResult) {
          this.launchEndChallenge();
        }
      });
  }

  launchEndChallenge() {
    this.modalMode = 'end';
  }

  openTimesUpModal() {
    this.closeSession(false);
    const modalData: ModalWarningData = {
      title: this.translate.instant('examens.temps_ecoule2'),
      content:
        this.translate.instant('examens.temps_total') +
        ' : ' +
        this.decimalPipe.transform(CHALLENGE_INITIAL_TIMER_DURATION / 60, '0.0-0') +
        ' ' +
        this.translate.instant('examens.minutes') +
        '<br><br>' +
        this.translate.instant('examens.temps_restant') +
        ' : ' +
        this.decimalPipe.transform(0, '0.0-0') +
        ' ' +
        this.translate.instant('examens.minutes') +
        '<br><br>' +
        this.translate.instant('examens.temps_ecoule3'),
      buttonText: this.translate.instant('examens.voir_resultats'),
    };
    const dialogRef = this.matDialog.open(ModalWarningComponent, {
      maxWidth: '100vw',
      data: modalData,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.launchEndChallenge();
    });
  }
}
