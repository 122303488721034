import {
  FolderQuestionResult,
  SimplifiedFolderQuestionResult,
} from '../api-results/folder-question.api-result';
import { FolderResult } from '../api-results/folder.api-result';
import { ContentQuestion } from './content-question.entity';
import { Folder } from './folder.entity';
import { Question } from './question.entity';

export class FolderQuestion extends ContentQuestion {
  folder: Folder;

  constructor(result: FolderQuestionResult) {
    super(result);
    if (result.folder) this.folder = new Folder(result.folder);
  }

  toResult(): FolderQuestionResult {
    return {
      id: this.id,
      priority: this.priority,
      question: this.question?.toResult(),
      folder: this.folder?.toResult(),
    };
  }
}
