<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -large">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white" id="modalInsideListFriends">
    <div class="modal-circles -noImage">
      <div class="background -green"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>

    <div
      appInfiniteScroll
      scrollContainer="modalListFriends"
      scrollContent="modalInsideListFriends"
      (needToScroll)="scroll()"
      class="modal-text"
    >
      <div class="modal-picto -picto-svg -green">
        <svg-icon
          src="assets/images/svg-icons/icon-my-ecni.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title">
        <ng-container *ngIf="componentStore.totalEntities$ | async as totalEntities">
          {{ totalEntities }} {{ titleModale }}
        </ng-container>
        <app-text-content
          *ngIf="(componentStore.totalEntities$ | async) === undefined"
          [dummyHeight]="27"
        ></app-text-content>
      </div>
      <ng-container *ngIf="componentStore.entities$ | async as followedStudents">
        <div class="grid-container width100 modal-grid-container">
          <app-student-thumbnail
            *ngFor="let followedStudent of followedStudents"
            [student]="followedStudent.student"
            [type]="studentThumbnailType"
          ></app-student-thumbnail>
        </div>
      </ng-container>

      <app-loader-infinite-scroll
        [loading$]="componentStore.loading$"
        [scrolling$]="componentStore.scrolling$"
        [smallLoader]="true"
      ></app-loader-infinite-scroll>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-primary btn-nnki-uppercase btn-nnki-uppercase"
          (click)="close()"
        >
          {{ 'util.fermer' | translate }}
        </button>
      </div>

      <div class="h-[60px] sm:hidden"></div>
    </div>
  </div>
</div>
