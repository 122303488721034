import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { CustomExam } from '../models/entities/custom-exam.entity';
import { SessionContentType } from '../models/enums/session.enum';

export function dateAfterValidator(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    return control.value < date ? { dateNotAfter: { date } } : null;
  };
}

export function atLeastOneSupportValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const isWebsiteSupported: boolean = form.get('isWebsiteSupported').value;
    const isMailSupported: boolean = form.get('isMailSupported').value;
    const isPushSupported: boolean = form.get('isPushSupported').value;

    if (!isWebsiteSupported && !isMailSupported && !isPushSupported) {
      return { noSupport: true };
    }
    return null;
  };
}

export function confirmWithTextInputValidator(text: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.trim() !== text) return { textInputNotTheSame: true };
    return null;
  };
}

export function arrayMaxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.length > maxLength) return { arrayMaxLength: maxLength };
    return null;
  };
}

export function iframeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const iframe = control.value.match(/<iframe(.*?)<\/iframe>/);
    if (!iframe) return { iframe: true };
    return null;
  };
}

export function customExamQuestionsValidator(totalQuestions: number): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const customExamType: SessionContentType = form.get('customExamType').value;
    if (customExamType === SessionContentType.NONE) return null;

    if (customExamType === SessionContentType.SPECIALTIES) {
      const totalSpecialtiesQuestions = form
        .get('specialties')
        .value.reduce(
          (acc, specialty) =>
            acc + (specialty.totalQuestions ? Number(specialty.totalQuestions) : 0),
          0
        );
      if (totalSpecialtiesQuestions !== totalQuestions) return { totalQuestions: true };
    }

    if (customExamType === SessionContentType.COURSES) {
      const totalCoursesQuestions = form
        .get('courses')
        .value.reduce(
          (acc, course) => acc + (course.totalQuestions ? Number(course.totalQuestions) : 0),
          0
        );
      if (totalCoursesQuestions !== totalQuestions) return { totalQuestions: true };
    }

    return null;
  };
}
