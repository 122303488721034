import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  GraduationNameResult,
  ListGraduationNamesResult,
} from '../../../shared/models/api-results/graduation-name.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { GraduationNamePayload } from '../../../shared/models/payloads/graduation-name.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpGraduationNameService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/graduation_names/list_graduation_names`)
      .pipe(map((res) => res as ListGraduationNamesResult));
  }

  wAdd(payload: GraduationNamePayload) {
    return this.http
      .post(`${environment.api_base_url}/graduation_names/add_graduation_name`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: GraduationNamePayload) {
    return this.http.post(
      `${environment.api_base_url}/graduation_names/${id}/update_graduation_name`,
      payload
    );
  }
}
