import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  Observable,
  catchError,
  concatMap,
  forkJoin,
  iif,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { convertHttpErrorToApiError } from '../../../shared/models/api-results/error.api-result';
import {
  LoginPayload,
  RefreshTokenPayload,
  SSOAppleLoginPayload,
  SSODefaultLoginPayload,
  SSOFacebookLimitedLoginPayload,
} from '../../../shared/models/payloads/auth.payload';
import { LocaleKeys } from '../../../shared/utils/locale-keys';
import { EncryptService } from '../../services/encrypt.service';
import { HttpAuthService } from '../../services/http/http-auth.service';
import { HttpStudentService } from '../../services/http/http-student.service';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import * as GlobalEntitiesSelectors from '../global-entities-state/global-entities.selectors';
import * as GlobalEntitiesHelpers from '../global-entities-state/global-entities.helpers';
import { HttpWriterService } from '../../services/http/http-writer.service';
import { SSOProvider, UserRole } from '../../../shared/models/enums/auth.enums';
import { SSOFacebookService } from '../../services/sso-facebook.service';
import { SSOGoogleService } from '../../services/sso-google.service';
import { SSOAppleService } from '../../services/sso-apple.service';
import { HttpStudentSettingService } from '../../services/http/http-student-settings.service';
import { HttpSubscriptionService } from '../../services/http/http-subscription.service';
import { ErrorService } from '../../services/error.service';
import { shutdown, update } from '@intercom/messenger-js-sdk';
import { IsApplicationService } from '../../services/is-application.service';
import { environment } from '../../../../environments/environment';
import { HttpNotificationSettingService } from '../../services/http/http-notification-settings.service';
import { NotificationSetting } from '../../../shared/models/entities/notification-setting.entity';
import { NotificationTemplate } from '../../../shared/models/enums/notification.enums';
import * as Sentry from '@sentry/angular';
import { MatDialog } from '@angular/material/dialog';
import {
  ModalMaintenanceComponent,
  ModalMaintenanceData,
} from '../../../shared/modals/modal-maintenance/modal-maintenance.component';
import { SSOAuthResult, SSOLoginType } from '../../../shared/models/interfaces/sso.interface';
import { LoginResult } from '../../../shared/models/api-results/auth.api-result';

// nnkitodo [v2later] typage
declare var fbq: any;

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
    private ngZone: NgZone,
    private router: Router,
    private store: Store,
    private httpAuthService: HttpAuthService,
    private httpNotificationSettingService: HttpNotificationSettingService,
    private httpStudentService: HttpStudentService,
    private httpStudentSettingsService: HttpStudentSettingService,
    private httpSubscriptionService: HttpSubscriptionService,
    private httpWriterService: HttpWriterService,
    private encryptService: EncryptService,
    private errorService: ErrorService,
    private isApplicationService: IsApplicationService,
    private SSOFacebookService: SSOFacebookService,
    private SSOGoogleService: SSOGoogleService,
    private SSOAppleService: SSOAppleService
  ) {}

  resetAccessToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.noSessionToRecover,
          AuthActions.login,
          AuthActions.SSOLoginWithSDK,
          AuthActions.activateAccount,
          AuthActions.refreshToken,
          AuthActions.logout
        ),
        tap((action) => {
          localStorage.removeItem(LocaleKeys.accessToken);
          if (action.type !== AuthActions.refreshToken.type) {
            localStorage.removeItem(LocaleKeys.refreshToken);
          }
        })
      ),
    { dispatch: false }
  );

  setAccessToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.loginSucceed,
          AuthActions.fillStudentInformationsAfterSSOSucceed,
          AuthActions.refreshTokenSucceed
        ),
        tap((action) => {
          localStorage.setItem(LocaleKeys.accessToken, action.result?.token);
          localStorage.setItem(LocaleKeys.refreshToken, action.result?.refreshToken);
        })
      ),
    { dispatch: false }
  );

  findSessionToRecover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.findSessionToRecover),
      withLatestFrom(this.store.select(AuthSelectors.selectAuth)),
      map(([action, authState]) => {
        if (
          localStorage.getItem(LocaleKeys.accessToken) &&
          localStorage.getItem(LocaleKeys.refreshToken)
        ) {
          return AuthActions.recoverSession();
        } else {
          return AuthActions.noSessionToRecover();
        }
      })
    )
  );

  recoverSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recoverSession),
      switchMap(() =>
        forkJoin([
          this.httpStudentService.getMyStudentProfile(),
          this.httpStudentSettingsService.list(),
          this.httpSubscriptionService.listActive(),
        ]).pipe(
          map(([student, studentSettings, activeSubscriptions]) =>
            AuthActions.recoverSessionSucceed({ student, studentSettings, activeSubscriptions })
          ),
          catchError((err) => {
            if (err.status === 404) {
              return of(AuthActions.fillStudentInformationsAfterSSO());
            } else {
              return of(AuthActions.noSessionToRecover());
            }
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) => {
        const payload = new LoginPayload(action.formValue);
        return this.httpAuthService.login(payload).pipe(
          tap(() => {
            if (action.rememberMe) {
              localStorage.setItem(LocaleKeys.rememberMeUsername, payload.login);
              localStorage.setItem(
                LocaleKeys.rememberMePassword,
                this.encryptService.encrypt(payload.password)
              );
            } else {
              localStorage.removeItem(LocaleKeys.rememberMeUsername);
              localStorage.removeItem(LocaleKeys.rememberMePassword);
            }
            localStorage.setItem(LocaleKeys.rememberMe, action.rememberMe ? '1' : '0');
          }),
          map((result) => AuthActions.loginSucceed({ result })),
          catchError((error) =>
            of(
              AuthActions.loginFailed({
                error: convertHttpErrorToApiError(error),
              })
            )
          )
        );
      })
    )
  );

  activateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.activateAccount),
      switchMap((action) => {
        return this.httpAuthService.activateAccount(action.token).pipe(
          tap(() => {
            fbq('track', 'CompleteRegistration');
          }),
          map((result) => AuthActions.loginSucceed({ result })),
          catchError((error) =>
            of(
              AuthActions.loginFailed({
                error: convertHttpErrorToApiError(error),
              })
            )
          )
        );
      })
    )
  );

  SSOLoginWithSDK$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SSOLoginWithSDK),
      switchMap((action) => {
        let ssoLogin: Observable<{
          ssoAuthResult: SSOAuthResult;
          loginType: SSOLoginType;
        }>;
        if (action.provider === SSOProvider.FACEBOOK) {
          ssoLogin = this.SSOFacebookService.login();
        } else if (action.provider === SSOProvider.GOOGLE) {
          ssoLogin = this.SSOGoogleService.login();
        } else if (action.provider === SSOProvider.APPLE) {
          ssoLogin = this.SSOAppleService.login();
        }
        return ssoLogin.pipe(
          map((result) =>
            AuthActions.SSOLoginWithBackend({ provider: action.provider, result: result })
          ),
          catchError((error) => {
            return of(
              AuthActions.loginFailed({
                error: convertHttpErrorToApiError(error),
              })
            );
          })
        );
      })
    )
  );

  SSOLoginWithBackend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SSOLoginWithBackend),
      switchMap((action) => {
        let SSOBackendHttpRequest = this.httpAuthService.SSODefaultLogin(
          action.provider,
          new SSODefaultLoginPayload(action.result.ssoAuthResult.authResponse.accessToken)
        );
        if (action.provider === SSOProvider.APPLE) {
          SSOBackendHttpRequest = this.httpAuthService.SSOAppleLogin(
            new SSOAppleLoginPayload(
              action.result.ssoAuthResult.authResponse.accessToken,
              this.isApplicationService.isApplication()
            )
          );
        } else if (action.provider === SSOProvider.FACEBOOK) {
          if (action.result.loginType === SSOLoginType.LIMITED) {
            SSOBackendHttpRequest = this.httpAuthService.SSOFacebookLimitedLogin(
              new SSOFacebookLimitedLoginPayload(
                action.result.ssoAuthResult.authResponse.accessToken
              )
            );
          }
        }

        return SSOBackendHttpRequest.pipe(
          map((result) => AuthActions.loginSucceed({ result })),
          catchError((error) => {
            return of(
              AuthActions.loginFailed({
                error: convertHttpErrorToApiError(error),
              })
            );
          })
        );
      })
    )
  );

  loginSucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSucceed),
      tap((action) => {
        localStorage.setItem(LocaleKeys.accessToken, action.result.token);
        localStorage.setItem(LocaleKeys.refreshToken, action.result.refreshToken);
      }),
      map(() => AuthActions.getMyStudentProfileAfterLogin())
    )
  );

  getMyStudentProfileAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getMyStudentProfileAfterLogin),
      switchMap(() =>
        forkJoin([
          this.httpStudentService.getMyStudentProfile(),
          this.httpStudentSettingsService.list(),
          this.httpSubscriptionService.listActive(),
        ]).pipe(
          map(([student, studentSettings, activeSubscriptions]) =>
            AuthActions.getMyStudentProfileAfterLoginSucceed({
              student,
              studentSettings,
              activeSubscriptions,
            })
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(AuthActions.fillStudentInformationsAfterSSO());
            } else {
              return of(
                AuthActions.loginFailed({
                  error: convertHttpErrorToApiError(error),
                })
              );
            }
          })
        )
      )
    )
  );

  updateIntercomAndSentrySessions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getMyStudentProfileAfterLoginSucceed, AuthActions.recoverSessionSucceed),
        concatMap(() => this.httpNotificationSettingService.list()),
        withLatestFrom(
          this.store.select(AuthSelectors.loggedStudent),
          this.store.select(AuthSelectors.currentSubscription),
          this.store.select(AuthSelectors.currentSubscriptionTier)
        ),
        tap(
          ([
            notificationSettingsResult,
            loggedStudent,
            currentSubscription,
            currentSubscriptionTier,
          ]) => {
            // nnkitodo [v2later] supprimer sur intercom les champs qu'on n'utilise plus (pseudo, adresse, etc) - attributes + people data
            if (loggedStudent) {
              const notificationSettings = notificationSettingsResult['hydra:member'].map(
                (elt) => new NotificationSetting(elt)
              );
              update({
                name:
                  localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1'
                    ? `HIDDEN-${loggedStudent.id}`
                    : `${loggedStudent.firstName} ${loggedStudent.lastName}`,
                user_id: loggedStudent.id,
                username: loggedStudent.username,
                email: loggedStudent.email,
                created_at: loggedStudent.createdAt.getTime() / 1000,
                address:
                  localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1'
                    ? '-'
                    : loggedStudent.address?.street,
                zip_code:
                  localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1'
                    ? '-'
                    : loggedStudent.address?.zipCode,
                city:
                  localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1'
                    ? '-'
                    : loggedStudent.address?.city,
                phone:
                  localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1'
                    ? '-'
                    : loggedStudent.phoneNumber,
                graduation_name: loggedStudent.graduationName.name,
                start_subscription_at: currentSubscription
                  ? currentSubscription.startAt.getTime() / 1000
                  : undefined,
                end_subscription_at: currentSubscription
                  ? currentSubscription.endAt.getTime() / 1000
                  : undefined,
                subscription_type: currentSubscriptionTier.toString(),
                admin_user_url: `https://${environment.domain}/app/admin/utilisateurs/etudiants/edit/${loggedStudent.id}/infos`,
                unsubscribed_from_emails: notificationSettings.find(
                  (setting) => setting.name === NotificationTemplate.COMMUNICATION_MARKETING
                ).isMailAllowed
                  ? undefined
                  : true,
              });

              Sentry.getCurrentScope().setUser({
                id: loggedStudent.id,
                email: loggedStudent.email,
                username: loggedStudent.username,
              });
            }
          }
        )
      ),
    { dispatch: false }
  );

  navigateAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getMyStudentProfileAfterLoginSucceed),
      withLatestFrom(
        this.store.select(AuthSelectors.pathToReachAfterLogin),
        this.store.select(AuthSelectors.loggedStudent)
      ),
      tap(([action, pathToReachAfterLogin, loggedStudent]) => {
        this.ngZone.run(() => {
          if (pathToReachAfterLogin) {
            this.router.navigateByUrl(pathToReachAfterLogin);
          } else {
            if (loggedStudent.role === UserRole.ROLE_STUDENT) {
              this.router.navigate(['/app/home']);
            } else if (loggedStudent.role === UserRole.ROLE_ECNI) {
              this.router.navigate(['/app/admin/home']);
            } else {
              this.router.navigate(['/app/admin/feedbacks']);
            }
          }
        });
      }),
      map(() => AuthActions.navigateAfterLoginSucceed())
    )
  );

  fillStudentInformationsAfterSSO$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.fillStudentInformationsAfterSSO),
        tap(() => {
          this.ngZone.run(() => {
            this.router.navigate(['/user/finaliser-inscription']);
          });
        })
      ),
    { dispatch: false }
  );

  fillStudentInformationsAfterSSOSucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.fillStudentInformationsAfterSSOSucceed),

      map(() => AuthActions.getMyStudentProfileAfterLogin())
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      switchMap((action) => {
        const payload = new RefreshTokenPayload(action.refreshToken);
        return this.httpAuthService.refreshAuthToken(payload).pipe(
          map((result) => AuthActions.refreshTokenSucceed({ result })),
          catchError(() => of(AuthActions.refreshTokenFailed()))
        );
      })
    )
  );

  refreshTokenSucceed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.refreshTokenSucceed),
        tap((action) => {
          localStorage.setItem(LocaleKeys.accessToken, action.result.token);
          localStorage.setItem(LocaleKeys.refreshToken, action.result.refreshToken);
        })
      ),
    { dispatch: false }
  );

  refreshTokenFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenFailed),
      tap(() => this.errorService.toastError($localize`Tu as été déconnecté par sécurité`)),
      map(() => AuthActions.logout())
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.router.navigate(['/user/login']);

          localStorage.removeItem(LocaleKeys.accessToken);
          localStorage.removeItem(LocaleKeys.refreshToken);

          sessionStorage.removeItem(LocaleKeys.ancrageTodayShowed);

          if (this.isApplicationService.isApplication()) {
            this.SSOFacebookService.logoutFromApplication();
            this.SSOGoogleService.logoutFromApplication();
          } else {
            this.SSOFacebookService.logoutFromDesktop();
          }

          shutdown();
        })
      ),
    { dispatch: false }
  );

  updateMyUniversity = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateMyUniversity),
      withLatestFrom(
        this.store.select(GlobalEntitiesSelectors.rawGraduationNames),
        this.store.select(GlobalEntitiesSelectors.rawUniversities)
      ),
      map(([action, graduationNames, universities]) =>
        // nnkitodo [v2later auth] voir si on peut gérer ça avec static formValue to Result
        AuthActions.updateMyUniversitySucceed({
          graduationName: GlobalEntitiesHelpers.getGraduationNameResult(
            graduationNames,
            action.formValue.graduationName.id
          ),
          university: GlobalEntitiesHelpers.getUniversityResult(
            universities,
            action.formValue.university.id
          ),
        })
      )
    )
  );

  getMyWriterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getMyWriterProfile),
      switchMap(() =>
        this.httpWriterService.getMyWriterProfile().pipe(
          map((result) => AuthActions.getMyWriterProfileSucceed({ result })),
          catchError(() => of(AuthActions.getMyWriterProfileFailed()))
        )
      )
    )
  );

  updateMyActiveSubscriptions = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateMyActiveSubscriptions),
      switchMap(() =>
        this.httpSubscriptionService
          .listActive()
          .pipe(
            map((result) =>
              AuthActions.updateMyActiveSubscriptionsSucceed({ activeSubscriptions: result })
            )
          )
      )
    )
  );

  triggerMaintenance$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.triggerMaintenance),
        tap((action) => {
          const modalData: ModalMaintenanceData = {
            message: action.message,
          };

          this.matDialog.open(ModalMaintenanceComponent, {
            maxWidth: '100vw',
            data: modalData,
            disableClose: true,
          });
        })
      ),
    { dispatch: false }
  );

  impersonateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.impersonateUser),
        tap((action) => {
          this.router.navigate(['/app/home']);
        })
      ),
    { dispatch: false }
  );

  stopImpersonateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.stopImpersonateUser),
        tap((action) => {
          this.router.navigate([
            '/app/admin/utilisateurs/etudiants/edit',
            action.student.id,
            'infos',
          ]);
        })
      ),
    { dispatch: false }
  );
}
