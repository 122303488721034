import { ChallengeSpecialtyScoreBadgeResult } from '../api-results/challenge-specialty-score-badge.api-result';
import { Specialty } from './specialty.entity';

export class ChallengeSpecialtyScoreBadge {
  specialty: Specialty;
  level: number;

  constructor(result: ChallengeSpecialtyScoreBadgeResult) {
    if (result.specialty) this.specialty = new Specialty(result.specialty);
    this.level = result.level;
  }
}
