// nnkitodo [v2later] mutualiser avec app update ?

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import {
  ModalUpdateVersionData,
  ModalUpdateVersionComponent,
} from '../../shared/modals/modal-update-version/modal-update-version.component';
import { IsApplicationService } from './is-application.service';
import { EnvironmentService } from './environment.service';
import { SwUpdate } from '@angular/service-worker';
import { HttpFrontAppVersionService } from './http/http-front-app-version.service';
import {
  FrontAppVersion,
  LastDeployedVersions,
} from '../../shared/models/entities/front-app-version.entity';

@Injectable({
  providedIn: 'root',
})
export class FrontAppVersionSiteService {
  newVersionAvailable = false;
  newBreakingVersionAvailable = false;
  newVersionAvailableDismissed = false;

  lastDeployedVersions: LastDeployedVersions;

  constructor(
    private router: Router,
    private isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    private environmentService: EnvironmentService,
    private swUpdate: SwUpdate,
    private httpFrontAppVersionService: HttpFrontAppVersionService
  ) {}

  init() {
    if (!this.isApplicationService.isApplication() && environment.production) {
      // check every 5 minutes if a new version is available / first check after 20 seconds (leave time to sw to download new files)
      timer(20 * 1000, 60 * 5 * 1000).subscribe(() => {
        this.checkNewVersionAvailable();
      });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.newBreakingVersionAvailable) {
          this.openVersionUpdate(true);
        } else if (this.newVersionAvailable && !this.newVersionAvailableDismissed) {
          this.openVersionUpdate(false);
          this.newVersionAvailableDismissed = true;
        }
      });

      // nnkitodo [v2later] checker service worker
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates
          .pipe(filter((versionEvent) => versionEvent.type === 'VERSION_READY'))
          .subscribe(() => {
            this.newVersionAvailable = true;
          });
      }
    }
  }

  checkNewVersionAvailable() {
    this.httpFrontAppVersionService.getLastDeployedVersions().subscribe((result) => {
      this.lastDeployedVersions = new LastDeployedVersions(result);
      if (
        this.lastDeployedVersions.lastBreakingVersion &&
        !this.environmentService.frontAppVersion.isUpToDate(
          this.lastDeployedVersions.lastBreakingVersion
        )
      ) {
        this.newBreakingVersionAvailable = true;
      } else if (
        this.lastDeployedVersions.lastVersion &&
        !this.environmentService.frontAppVersion.isUpToDate(this.lastDeployedVersions.lastVersion)
      ) {
        this.newVersionAvailable = true;
      }
    });
  }

  openVersionUpdate(force: boolean) {
    const modalData: ModalUpdateVersionData = {
      forceUpdate: force,
      currentVersion: this.environmentService.stringifiedFrontAppVersion,
      lastDeployedVersions: this.lastDeployedVersions,
    };

    this.matDialog.open(ModalUpdateVersionComponent, {
      maxWidth: '100vw',
      disableClose: force,
      data: modalData,
    });
  }
}
