import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpGraduationNameService } from '../../services/http/http-graduation-name.service';
import { HttpUniversityCityService } from '../../services/http/http-university-city.service';
import { HttpUniversityCountryService } from '../../services/http/http-university-country.service';
import { HttpUniversityService } from '../../services/http/http-university.service';
import * as GlobalEntitiesActions from './global-entities.actions';
import * as GlobalEntitiesSelectors from './global-entities.selectors';
import { HttpExamTypeService } from '../../services/http/http-exam-type.service';

@Injectable()
export class GlobalEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private httpGraduationNameService: HttpGraduationNameService,
    private httpUniversityCountryService: HttpUniversityCountryService,
    private httpUniversityCityService: HttpUniversityCityService,
    private httpUniversityService: HttpUniversityService,
    private httpExamTypeService: HttpExamTypeService
  ) {}

  listGraduationNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalEntitiesActions.listGraduationNames),
      withLatestFrom(this.store.select(GlobalEntitiesSelectors.graduationNames)),
      exhaustMap(([action, graduationNames]) => {
        if (!graduationNames || action.forceUpdate) {
          return this.httpGraduationNameService
            .list()
            .pipe(map((result) => GlobalEntitiesActions.listGraduationNamesSucceed({ result })));
        } else {
          return EMPTY;
        }
      })
    )
  );

  listUniversityCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalEntitiesActions.listUniversityCountries),
      withLatestFrom(this.store.select(GlobalEntitiesSelectors.universityCountries)),
      exhaustMap(([action, universityCountries]) => {
        if (!universityCountries || action.forceUpdate) {
          return this.httpUniversityCountryService
            .list()
            .pipe(
              map((result) => GlobalEntitiesActions.listUniversityCountriesSucceed({ result }))
            );
        } else {
          return EMPTY;
        }
      })
    )
  );

  listUniversityCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalEntitiesActions.listUniversityCities),
      withLatestFrom(this.store.select(GlobalEntitiesSelectors.universityCities)),
      exhaustMap(([action, universityCities]) => {
        if (!universityCities || action.forceUpdate) {
          return this.httpUniversityCityService
            .list()
            .pipe(map((result) => GlobalEntitiesActions.listUniversityCitiesSucceed({ result })));
        } else {
          return EMPTY;
        }
      })
    )
  );

  listUniversities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalEntitiesActions.listUniversities),
      withLatestFrom(this.store.select(GlobalEntitiesSelectors.universities)),
      exhaustMap(([action, universities]) => {
        if (!universities || action.forceUpdate) {
          return this.httpUniversityService
            .list()
            .pipe(map((result) => GlobalEntitiesActions.listUniversitiesSucceed({ result })));
        } else {
          return EMPTY;
        }
      })
    )
  );

  listExamTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalEntitiesActions.listExamTypes),
      withLatestFrom(this.store.select(GlobalEntitiesSelectors.examTypes)),
      exhaustMap(([action, examTypes]) => {
        if (!examTypes || action.forceUpdate) {
          return this.httpExamTypeService
            .list()
            .pipe(map((result) => GlobalEntitiesActions.listExamTypesSucceed({ result })));
        } else {
          return EMPTY;
        }
      })
    )
  );
}
