import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-time-remaining',
  templateUrl: './widget-time-remaining.component.html',
  styleUrls: ['./widget-time-remaining.component.scss'],
})
export class WidgetTimeRemainingComponent implements OnInit {
  @Input() remainingSeconds: number;
  @Input() percentTimeElapsed: number;

  constructor() {}

  ngOnInit(): void {}

  get ceiledCountdown() {
    return Math.ceil(this.remainingSeconds / 60);
  }
}
