import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AddDeviceTokenPayload } from '../../../shared/models/payloads/device-token.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpDeviceTokenService {
  constructor(private http: HttpClient) {}

  add(payload: AddDeviceTokenPayload) {
    return this.http.post(`${environment.api_base_url}/device_tokens/add_device_token`, payload);
  }
}
