import { NotificationSettingResult } from '../api-results/notification-setting.api-result';
import { NotificationTemplate } from '../enums/notification.enums';

export class NotificationSetting {
  id: string;
  name: NotificationTemplate;
  isMailAllowed: boolean;
  isPushAllowed: boolean;
  isWebsiteAllowed: boolean;

  constructor(result: NotificationSettingResult) {
    this.id = result.id;
    this.name = result.name;
    this.isMailAllowed = result.isMailAllowed;
    this.isPushAllowed = result.isPushAllowed;
    this.isWebsiteAllowed = result.isWebsiteAllowed;
  }
}
