import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  ListSpecialtiesResult,
  SpecialtyResult,
  UpdateSpecialtyIconResult,
} from '../../../shared/models/api-results/specialty.api-result';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { convertToFormData, prepareQueryParams } from '../../../shared/utils/api-transformations';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import {
  AddSpecialtyPayload,
  SpecialtyQueryData,
  TrainingSpecialtyQueryData,
  UpdateSpecialtyIconPayload,
  UpdateSpecialtyPayload,
} from '../../../shared/models/payloads/specialty.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpSpecialtyService {
  constructor(private http: HttpClient) {}

  list(params: SpecialtyQueryData) {
    return this.http
      .get(`${environment.api_base_url}/specialties/list_specialties`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListSpecialtiesResult));
  }

  listForTraining(params: TrainingSpecialtyQueryData) {
    return this.http
      .get(`${environment.api_base_url}/specialties/list_specialties_available_for_training`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListSpecialtiesResult));
  }

  getForTraining(id: string) {
    return this.http
      .get(`${environment.api_base_url}/training_sessions/specialty/${id}/get_my_training_details`)
      .pipe(
        // nnkitodo [v2later] fix api
        map((res: any) => {
          res.totalAnchoredQuestions = res.totalAnchored;
          res.totalAnchoringInProgressQuestions = res.totalAnchoredInProgress;
          return res;
        }),
        map((res) => res as SpecialtyResult)
      );
  }

  wList(params: SpecialtyQueryData) {
    return this.http
      .get(`${environment.api_base_url}/specialties/admin/list_specialties`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListSpecialtiesResult));
  }

  wAdd(payload: AddSpecialtyPayload) {
    return this.http
      .post(`${environment.api_base_url}/specialties/add_specialty`, convertToFormData(payload))
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UpdateSpecialtyPayload) {
    return this.http.post(
      `${environment.api_base_url}/specialties/update_specialty/${id}`,
      payload
    );
  }

  wUpdateIcon(id: string, payload: UpdateSpecialtyIconPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/specialties/${id}/update_specialty_icon`,
        convertToFormData(payload)
      )
      .pipe(map((res) => res as UpdateSpecialtyIconResult));
  }
}
