import { environment } from '../../../../environments/environment';
import { getCountryName } from '../../constants/countries';
import { UniversityCountryResult } from '../api-results/university-country.api-result';
import { UniversityResult } from '../api-results/university.api-result';
import {
  UniversityCountryFormValue,
  UniversityCountryPayload,
} from '../payloads/university-country.payload';

export class UniversityCountry {
  id: string;
  name: string;

  // [FRONT CREATED FIELD]
  displayName: string;

  constructor(result: UniversityCountryResult) {
    this.id = result.id;
    this.name = result.name;

    this.displayName = getCountryName(environment.defaultLanguage, result.name);
  }

  toFormValue(): UniversityCountryFormValue {
    return {
      country: {
        code: this.name,
        name: this.displayName,
      },
    };
  }

  toResult(): UniversityCountryResult {
    return {
      id: this.id,
      name: this.name,
    };
  }

  static updatedEntityToResult(
    payload: UniversityCountryPayload
  ): Partial<UniversityCountryResult> {
    return {
      name: payload.name,
    };
  }
}
