// nnkitodo [v2later] mieux

import { CorrectionCoordinates } from '../models/entities/correction.entity';

export function stringToCoordinates(listOfcoordinatesInString: string) {
  const listOfCoordinates = listOfcoordinatesInString.split(',');
  return listOfCoordinates.map((coordinatesInString) => {
    if (!coordinatesInString) return null;
    const coordinates = coordinatesInString.split(':');
    if (coordinates.length !== 2) return null;
    return {
      x: Number(coordinates[0]),
      y: Number(coordinates[1]),
    };
  });
}

export function coordinatesToString(coordinates: CorrectionCoordinates[]) {
  if (!coordinates) return null;
  return coordinates.map((coordinate) => `${coordinate.x}:${coordinate.y}`).join(',');
}
