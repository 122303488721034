import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  constructor() {}

  encrypt(plainText) {
    return window.btoa(plainText);
  }

  decrypt(cryptedText) {
    return window.atob(cryptedText);
  }
}
