import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Device, DeviceInfo } from '@capacitor/device';
import { InAppReview } from '@capacitor-community/in-app-review';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { filter, take, map, shareReplay, withLatestFrom } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  FrontAppVersion,
  LastDeployedVersions,
} from '../../shared/models/entities/front-app-version.entity';
import { HttpFrontAppVersionService } from './http/http-front-app-version.service';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../state/auth-state/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class IsApplicationService {
  renderer: Renderer2;
  platform: DeviceInfo['platform'];
  shouldRateApp = false;
  isMobileDevice = false;

  lastDeployedVersions$ = new BehaviorSubject<LastDeployedVersions>(null);

  constructor(
    rendererFactory: RendererFactory2,
    private router: Router,
    private store: Store,
    private httpFrontAppVersionService: HttpFrontAppVersionService
  ) {
    if (this.isApplication()) {
      Device.getInfo().then((info) => {
        this.platform = info.platform;

        if (this.platform === 'ios') {
          this.httpFrontAppVersionService.getLastDeployedVersions().subscribe((result) => {
            this.lastDeployedVersions$.next(new LastDeployedVersions(result));
          });
        }
      });
    }

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    const match = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
    if (match || this.isApplication()) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.renderer.addClass(document.body, '-mobile-device');

      this.isMobileDevice = true;

      if (!this.isApplication()) {
        this.renderer.addClass(document.body, '-mobile-browser');
      }
    }

    // si app ouverte depuis +20 minutes : proposer à l'utilisateur de noter à la prochaine navigation
    if (this.isApplication()) {
      timer(1000 * 60 * 20)
        .pipe(take(1))
        .subscribe(() => {
          this.shouldRateApp = true;
        });

      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.shouldRateApp) {
          InAppReview.requestReview();
          this.shouldRateApp = false;
        }
      });
    }
  }

  isAppIos() {
    if (this.isApplication()) {
      return !this.platform || this.platform === 'ios';
    } else {
      return false;
    }
  }

  isAppAndroid() {
    if (this.isApplication()) {
      return !this.platform || this.platform === 'android';
    } else {
      return false;
    }
  }

  isApplication() {
    if (environment.env === 'mobile') {
      return true;
    } else {
      return false;
    }
  }

  // nnkitodo [v2later] beaucoup plus réactif au cas où platform ou get last deployed prend trop de temps + mettre dans store non ?
  get premiumCanBeDisplayed$() {
    return combineLatest([
      this.lastDeployedVersions$,
      this.store.select(AuthSelectors.isPremiumPlus),
    ]).pipe(
      map(([lastDeployedVersions, isPremiumPlus]) => {
        if (!lastDeployedVersions) {
          return isPremiumPlus || !this.isAppIos();
        } else {
          return (
            isPremiumPlus ||
            !this.isAppIos() ||
            !lastDeployedVersions.lastMobileVersion.isInAppleApprovalProcess
          );
        }
      })
    );
  }
}
