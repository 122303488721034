import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { take, startWith, takeUntil } from 'rxjs/operators';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { ColorService } from '../../../core/services/color.service';
import { LanguageService } from '../../../core/services/language.service';
import { ConversationService } from '../../../core/services/conversation.service';
import { ModalService } from '../../../core/services/modal.service';

import { BaseComponent } from '../inherited/base/base.component';

import { ModalChallengeBadgeInfosComponent } from '../../modals/modal-challenge-badge-infos/modal-challenge-badge-infos.component';
import {
  ModalListFriendsComponent,
  ModalListFriendsData,
} from '../../modals/modal-list-friends/modal-list-friends.component';
import { Student } from '../../models/entities/student.entity';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { HttpStudentService } from '../../../core/services/http/http-student.service';
import { HttpFollowService } from '../../../core/services/http/http-follow.service';
import { FollowNumbersResult } from '../../models/api-results/follow.api-result';
import { FollowStatus, ModalListFollowsType } from '../../models/enums/follow.enum';
import { FollowService } from '../../../core/services/follow.service';
import { forkJoin } from 'rxjs';
import { HttpChallengeScoreService } from '../../../core/services/http/http-challenge-score.service';
import { GlobalChallengeScore } from '../../models/entities/challenge-score.entity';
import { HttpProgressService } from '../../../core/services/http/http-progress.service';
import { GlobalProgress } from '../../models/entities/progress.entity';
import { HttpApplicationStatsService } from '../../../core/services/http/http-application-stats.service';
import { ApplicationStats } from '../../models/entities/application-stats.entity';
import { HttpChallengeSpecialtyScoreBadgeService } from '../../../core/services/http/http-challenge-specialty-score-badge.service';
import { QueryData } from '../../models/payloads/query-data.payload';
import { ChallengeSpecialtyScoreBadge } from '../../models/entities/challenge-specialty-score-badge.entity';

// nnkitodo [v2later] typage
declare var ProgressBar: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() studentId: string;
  @Input() inModale = false;

  student: Student;
  followNumbers: FollowNumbersResult;
  globalChallengeScore: GlobalChallengeScore;
  globalProgress: GlobalProgress;
  applicationStats: ApplicationStats;
  challengeSpecialtyScoreBadges: ChallengeSpecialtyScoreBadge[];

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  FollowStatus = FollowStatus;
  ModalListFollowsType = ModalListFollowsType;

  now = new Date().getTime();

  svgProgress;
  svgSuccess;

  constructor(
    private matDialog: MatDialog,
    private store: Store,
    private colorService: ColorService,
    private conversationService: ConversationService,
    public followService: FollowService,
    public isApplicationService: IsApplicationService,
    public languageService: LanguageService,
    public modalService: ModalService,
    private httpApplicationStatsService: HttpApplicationStatsService,
    private httpChallengeScoreService: HttpChallengeScoreService,
    private httpChallengeSpecialtyScoreBadgeService: HttpChallengeSpecialtyScoreBadgeService,
    private httpFollowService: HttpFollowService,
    private httpProgressService: HttpProgressService,
    private httpStudentService: HttpStudentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loggedStudent$.pipe(take(1)).subscribe((loggedStudent) => {
      if (loggedStudent.id === this.studentId) {
        this.student = loggedStudent;
        this.getMyFollowNumbers();

        this.httpChallengeScoreService.getMyChallengeScores().subscribe((res) => {
          this.globalChallengeScore = new GlobalChallengeScore(res);
        });

        forkJoin([
          this.httpApplicationStatsService.getApplicationStats(),
          this.httpProgressService.getMyGlobalProgress(),
        ]).subscribe(([stats, progress]) => {
          this.applicationStats = new ApplicationStats(stats);
          this.globalProgress = new GlobalProgress(progress);

          this.animateCharts();
        });

        this.httpChallengeSpecialtyScoreBadgeService
          .listMyChallengeSpecialtyScoreBadges(new QueryData({ page: 1, perPage: 3 }, true))
          .subscribe((res) => {
            this.challengeSpecialtyScoreBadges = res['hydra:member'].map(
              (elt) => new ChallengeSpecialtyScoreBadge(elt)
            );
          });
      } else {
        this.getStudent();
        this.getFollowNumbers();

        this.httpChallengeScoreService
          .getChallengeScoresOfStudent(this.studentId)
          .subscribe((res) => {
            this.globalChallengeScore = new GlobalChallengeScore(res);
          });

        forkJoin([
          this.httpApplicationStatsService.getApplicationStats(),
          this.httpProgressService.getGlobalProgressOfStudent(this.studentId),
        ]).subscribe(([stats, progress]) => {
          this.applicationStats = new ApplicationStats(stats);
          this.globalProgress = new GlobalProgress(progress);

          this.animateCharts();
        });

        this.httpChallengeSpecialtyScoreBadgeService
          .listChallengeSpecialtyScoreBadgesOfStudent(
            this.studentId,
            new QueryData({ page: 1, perPage: 3 }, true)
          )
          .subscribe((res) => {
            this.challengeSpecialtyScoreBadges = res['hydra:member'].map(
              (elt) => new ChallengeSpecialtyScoreBadge(elt)
            );
          });
      }
    });
  }

  ngAfterViewInit(): void {
    this.initCharts();
  }

  getStudent() {
    forkJoin([
      this.httpStudentService.getStudent(this.studentId),
      this.httpFollowService.isFollowedByMe(this.studentId),
    ]).subscribe(([student, followedByMe]) => {
      // nnkitodo [v2later] retour api merdique ici si on suit pas
      student.followedByMe = followedByMe.status ? followedByMe : null;
      this.student = new Student(student);
    });
  }

  getMyFollowNumbers() {
    this.followService.reloadFollowNumbers
      .pipe(takeUntil(this.alive$), startWith(true))
      .subscribe(() => {
        this.httpFollowService.getMyFollowNumbers().subscribe((result) => {
          this.followNumbers = result;
        });
      });
  }

  getFollowNumbers() {
    this.followService.reloadFollowNumbers
      .pipe(takeUntil(this.alive$), startWith(true))
      .subscribe(() => {
        this.httpFollowService.getFollowNumbers(this.studentId).subscribe((result) => {
          this.followNumbers = result;
        });
      });
  }

  initCharts() {
    setTimeout(() => {
      if (
        ProgressBar &&
        document.getElementById('svgProgress-' + this.now) &&
        document.getElementById('svgSuccess-' + this.now)
      ) {
        this.svgProgress = new ProgressBar.Circle('#svgProgress-' + this.now, {
          color: this.colorService.getAppPurpleFour(),
          trailColor: this.colorService.getAppPurpleFive(),
          strokeWidth: 15,
        });
        this.svgSuccess = new ProgressBar.Circle('#svgSuccess-' + this.now, {
          color: this.colorService.getAppGreenThree(),
          trailColor: this.colorService.getGreenFive(),
          strokeWidth: 15,
        });
      }
    });
  }

  animateCharts() {
    if (document.getElementById('svgProgress-' + this.now) && this.svgProgress) {
      this.svgProgress.animate(
        this.globalProgress.totalAnsweredQuestions / this.applicationStats.totalQuestions
      );
    }

    if (document.getElementById('svgSuccess-' + this.now) && this.svgSuccess) {
      this.svgSuccess.animate(this.globalProgress.globalSuccessRate);
    }
  }

  openModalChallengeBadgeInfos() {
    this.matDialog.open(ModalChallengeBadgeInfosComponent, {
      maxWidth: '100vw',
    });
  }

  openModalListFollows(type: ModalListFollowsType) {
    this.loggedStudent$.pipe(take(1)).subscribe((loggedStudent) => {
      const modalData: ModalListFriendsData = {
        type,
        studentId: loggedStudent.id !== this.studentId ? this.studentId : null,
      };
      this.matDialog.open(ModalListFriendsComponent, {
        maxWidth: '100vw',
        data: modalData,
      });
    });
  }

  // nnkitodo [v2later]
  facebookImport() {
    // this.isFacebookImporting = true;
    // this.userService
    //   .facebookImport()
    //   .pipe(
    //     finalize(() => {
    //       this.isFacebookImporting = false;
    //     })
    //   )
    //   .subscribe((nbImported) => {
    //     this.ecniTweetsService.emitMyUserFollowedChanged(nbImported);
    //   });
  }

  createTemporaryConversation(student: Student) {
    this.conversationService.createTemporaryConversation([student]);
    if (this.inModale) {
      this.matDialog.closeAll();
    }
  }
}
