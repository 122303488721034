import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})

// nnkitodo [v2later ux] ng-content ?
export class ButtonComponent implements OnInit {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() label: string;
  @Input() icon: string;
  @Input() isMaterial = false;
  @Input() isV2Icon = false;
  @Input() loading = false;
  @Input() backgroundClass = 'bg-site-main-one';
  @Input() borderClass = '';
  @Input() textClass = 'text-white';
  @Input() iconWidth = 14;
  @Input() heightClass = 'h-[40px]';
  @Input() uppercase = true;
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
