import { createReducer, on } from '@ngrx/store';
import { initialFiltersState } from './filters.state';
import * as FiltersActions from './filters.actions';

export const filtersReducer = createReducer(
  initialFiltersState,

  on(FiltersActions.search, (state, action) => ({
    ...state,
    search: action.search?.trim(),
  })),

  on(FiltersActions.resetFilters, (state, action) => ({
    ...state,
    ...initialFiltersState,
  }))
);
