import moment from 'moment';
import {
  FolderProgressResult,
  GlobalProgressResult,
  TrainingSessionGraphResult,
} from '../api-results/progress.api-result';

export class GlobalProgress {
  totalAnsweredPublishedOnTrainingQuestions: number;
  totalAnsweredPublishedFolderQuestions: number;
  totalAnsweredUnpublishedOnTrainingPublishedOnExamExamContentQuestions: number;
  globalSuccessRate: number;

  totalAnsweredQuestions: number;

  constructor(result: GlobalProgressResult) {
    this.totalAnsweredPublishedOnTrainingQuestions =
      result.totalAnsweredPublishedOnTrainingQuestions;
    this.totalAnsweredPublishedFolderQuestions = result.totalAnsweredPublishedFolderQuestions;
    this.totalAnsweredUnpublishedOnTrainingPublishedOnExamExamContentQuestions =
      result.totalAnsweredUnpublishedOnTrainingPublishedOnExamExamContentQuestions;
    this.globalSuccessRate = Math.max(result.globalSuccessRate, 0);

    this.totalAnsweredQuestions =
      this.totalAnsweredPublishedOnTrainingQuestions +
      this.totalAnsweredPublishedFolderQuestions +
      this.totalAnsweredUnpublishedOnTrainingPublishedOnExamExamContentQuestions;
  }
}

export class FolderProgress {
  totalCompletedPublishedFolders: number;
  totalPublishedFolders: number;

  constructor(result: FolderProgressResult) {
    this.totalCompletedPublishedFolders = result.totalCompletedPublishedFolders;
    this.totalPublishedFolders = result.totalPublishedFolders;
  }
}

export class TrainingSessionGraph {
  nbQuestionsAnswered: number;
  period: Date;
  totalDuration: number;

  constructor(result: TrainingSessionGraphResult) {
    this.nbQuestionsAnswered = result.nbQuestionsAnswered;
    if (result.period) this.period = moment(result.period).toDate();
    this.totalDuration = result.totalDuration;
  }
}
