import moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ConversationResult } from '../api-results/conversation.api-result';
import { ConversationMessage } from './conversation-message.entity';
import { Student } from './student.entity';

export class Conversation {
  id: string;
  members: Student[];
  createdBy: Student;
  createdAt: Date;
  totalUnreadConversationMessages: number;
  lastConversationMessage: ConversationMessage;

  constructor(result: ConversationResult) {
    this.id = result.id;
    if (result.members) {
      this.members = result.members.map((elt) => new Student(elt));
    }
    if (result.createdBy) this.createdBy = new Student(result.createdBy);
    if (result.createdAt) this.createdAt = moment(result.createdAt).toDate();
    this.totalUnreadConversationMessages = result.totalUnreadConversationMessages;
    if (result.lastConversationMessage)
      this.lastConversationMessage = new ConversationMessage(result.lastConversationMessage);
  }

  getMember(id: string) {
    return this.members.find((student) => student.id === id);
  }
}
