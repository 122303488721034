import { FollowStatus } from '../enums/follow.enum';
import { FiltersFormValue, QueryData } from './query-data.payload';

export class FollowPayload {
  followedUserId: string;

  constructor(followedUserId: string) {
    this.followedUserId = followedUserId;
  }
}

export class HandleFollowRequestPayload {
  followerUserId: string;

  constructor(followedUserId: string) {
    this.followerUserId = followedUserId;
  }
}

export interface FollowFiltersFormValue extends FiltersFormValue {
  status: FollowStatus;
}

export class FollowQueryData extends QueryData {
  status: FollowStatus;

  constructor(filtersFormValue: FollowFiltersFormValue) {
    super(filtersFormValue, true);
    this.status = filtersFormValue.status;
  }
}
