import { FormControl } from '@angular/forms';
import { tap } from 'rxjs';

export function resetFormFieldWhenValueChanged(
  controlToObserve: FormControl,
  controlToReset: FormControl
) {
  return controlToObserve.valueChanges.pipe(
    tap(() => {
      controlToReset.patchValue('');
    })
  );
}

export function generateUniqueId() {
  return `${new Date().getTime().toString()}-${Math.floor(Math.random() * 1000)}`;
}

export const patternTrue = 'true';
export const patternUppercase = '.*[A-Z].*';
export const patternLowercase = '.*[a-z].*';
export const patternNumber = '.*[0-9].*';
export const patternSpecialCharacter = '.*[^A-Za-z0-9].*';
