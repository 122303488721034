import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { routerReducer, RouterState } from '@ngrx/router-store';
import { AuthState } from './auth-state/auth.state';
import { authReducer } from './auth-state/auth.reducers';
import { GlobalEntitiesState } from './global-entities-state/global-entities.state';
import { globalEntitiesReducer } from './global-entities-state/global-entities.reducers';
import { FiltersState } from './filters-state/filters.state';
import { filtersReducer } from './filters-state/filters.reducers';

export interface AppState {
  router: RouterState;
  auth: AuthState;
  globalEntities: GlobalEntitiesState;
  filters: FiltersState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  globalEntities: globalEntitiesReducer,
  filters: filtersReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
