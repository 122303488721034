<app-modal [isWriterArea]="true" color="red-one" (close)="close(false)">
  <ng-container modalTitle>
    <ng-container i18n>Êtes-vous sûr ?</ng-container>
  </ng-container>

  <ng-container *ngIf="modalData.message">
    {{ modalData.message }}
  </ng-container>

  <ng-container modalButtons>
    <app-button
      i18n-label
      label="Annuler"
      icon="backspace"
      [isMaterial]="true"
      backgroundClass="bg-theme-white"
      borderClass="border border-text-red-one border-solid"
      textClass="text-red-one"
      [iconWidth]="30"
      (click)="close(false)"
    ></app-button>
    <app-button
      i18n-label
      label="Confirmer"
      icon="check"
      [isMaterial]="true"
      backgroundClass="bg-red-one"
      [iconWidth]="30"
      (click)="close(true)"
    ></app-button>
  </ng-container>
</app-modal>
