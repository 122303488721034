import { HttpFeedbackService } from './http/http-feedback.service';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  checkTimeSubscription: Subscription;
  totalUnreadFeedbackAnswers$ = new BehaviorSubject<number>(null);

  constructor(private store: Store, private httpFeedbackService: HttpFeedbackService) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateFeedbackAnswersUnread();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.totalUnreadFeedbackAnswers$.next(null);
      }
    });
  }

  updateFeedbackAnswersUnread() {
    this.httpFeedbackService.countTotalUnreadFeedbackAnswers().subscribe((res) => {
      this.totalUnreadFeedbackAnswers$.next(res.totalUnreadFeedbackAnswers);
    });
  }
}
