import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../core/services/modal.service';
import { IsApplicationService } from '../../../core/services/is-application.service';

import { Student } from '../../models/entities/student.entity';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { FollowService } from '../../../core/services/follow.service';
import { FollowStatus, StudentThumbnailType } from '../../models/enums/follow.enum';

@Component({
  selector: 'app-student-thumbnail',
  templateUrl: './student-thumbnail.component.html',
  styleUrls: ['./student-thumbnail.component.scss'],
})
export class StudentThumbnailComponent implements OnInit {
  @Input() student: Student;
  @Input() type = StudentThumbnailType.DEFAULT;

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  FollowStatus = FollowStatus;
  StudentThumbnailType = StudentThumbnailType;

  // nnkitodo [v2later] boutons pas très réactifs si une modif est faite sur une modale ouverte au dessus / idem sur le profil student j'imagine

  constructor(
    private store: Store,
    public followService: FollowService,
    public isApplicationService: IsApplicationService,
    public modalService: ModalService
  ) {}

  ngOnInit(): void {}
}
