import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { ChapterResult } from '../api-results/chapter.api-result';
import { ChapterType } from '../enums/chapter.enum';
import { ChapterFormValue, ChapterPayload } from '../payloads/chapter.payload';
import { Notion } from './notion.entity';
import moment from 'moment';

export class Chapter {
  id: string;
  name: string;
  type: ChapterType;
  createdAt: Date;

  constructor(result: ChapterResult) {
    this.id = result.id;
    this.name = result.name;
    this.type = result.type;
    if (result.createdAt) {
      this.createdAt = moment(result.createdAt).toDate();
    }
  }

  get displayName() {
    return this.name;
  }

  toFormValue(): ChapterFormValue {
    return {
      name: this.name,
    };
  }

  toResult(): ChapterResult {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
    };
  }

  static updatedEntityToResult(payload: ChapterPayload): Partial<ChapterResult> {
    return {
      name: payload.name,
    };
  }
}

export class ChapterFrontEnd extends Chapter {
  notions: Notion[];
}
