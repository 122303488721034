import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject, timer } from 'rxjs';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { HttpNotificationService } from './http/http-notification.service';

@Injectable({
  providedIn: 'root',
})
export class WebsiteNotificationsService {
  checkTimeSubscription: Subscription;
  totalUnreadWebsiteNotifications$ = new BehaviorSubject<number>(null);

  constructor(private store: Store, private httpNotificationService: HttpNotificationService) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.countUnreadWebsiteNotifications();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.totalUnreadWebsiteNotifications$.next(null);
      }
    });
  }

  countUnreadWebsiteNotifications() {
    this.httpNotificationService.listUnread().subscribe((res) => {
      this.totalUnreadWebsiteNotifications$.next(res.totalUnreadNotifications);
    });
  }

  decrementUnreadWebsiteNotifications() {
    this.totalUnreadWebsiteNotifications$.next(
      this.totalUnreadWebsiteNotifications$.getValue() - 1
    );
  }
}
