import { UniversityResult } from '../api-results/university.api-result';
import { UniversityFormValue, UniversityPayload } from '../payloads/university.payload';
import { UniversityCity } from './university-city.entity';
import { UniversityCountry } from './university-country.entity';

export class University {
  id: string;
  name: string;
  universityCity: UniversityCity;

  universityRank: number;
  totalParticipantsInUniversity: number;
  universityAverageGrade: number;

  constructor(result: UniversityResult) {
    this.id = result.id;
    this.name = result.name;

    // nnkitodo [v2later] uniformiser output api
    if (result.city || result.universityCity) {
      this.universityCity = new UniversityCity(result.city ? result.city : result.universityCity);
    }

    this.universityRank = result.universityRank;
    this.totalParticipantsInUniversity = result.totalParticipantsInUniversity;
    this.universityAverageGrade = result.universityAverageGrade;
  }

  toFormValue(): UniversityFormValue {
    return {
      name: this.name,
      country: this.universityCity.universityCountry,
      city: this.universityCity,
    };
  }

  toResult(): UniversityResult {
    return {
      id: this.id,
      name: this.name,
      city: this.universityCity?.toResult(),
      universityRank: this.universityRank,
      totalParticipantsInUniversity: this.totalParticipantsInUniversity,
      universityAverageGrade: this.universityAverageGrade,
    };
  }

  static updatedEntityToResult(
    payload: UniversityPayload,
    formValue: Partial<UniversityFormValue>
  ): Partial<UniversityResult> {
    return {
      name: payload.name,
      city: formValue.city.toResult(),
    };
  }
}
