import { ApiError } from '../api-results/error.api-result';

export enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SCROLLING = 'SCROLLING',
  LOADED = 'LOADED',
}
export interface ErrorState {
  error: ApiError;
}

export type CallState = LoadingState | ErrorState;

export function getCallStateError(callState: CallState): ApiError | null {
  if ((callState as ErrorState)?.error !== undefined) {
    return (callState as ErrorState).error;
  }
  return null;
}
