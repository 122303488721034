export enum NotionRank {
  A = 'A',
  B = 'B',
  C = 'C',
  UNCLASSIFIED = 'UNCLASSIFIED',
}

export function notionRankDifficulty(rank: NotionRank) {
  switch (rank) {
    case NotionRank.UNCLASSIFIED:
      return 1;
    case NotionRank.A:
      return 2;
    case NotionRank.B:
      return 3;
    case NotionRank.C:
      return 4;
  }
}
