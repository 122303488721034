import { CoursePictureResult } from '../api-results/course-picture.api-result';
import { Course } from './course.entity';

export class CoursePicture {
  id: string;
  name: string;
  course: Course;

  constructor(result: CoursePictureResult) {
    this.id = result.id;
    this.name = result.name;
    if (result.course) this.course = new Course(result.course);
  }

  toResult(): CoursePictureResult {
    return {
      id: this.id,
      name: this.name,
      course: this.course?.toResult(),
    };
  }
}
