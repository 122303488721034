export enum FollowStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
}

export enum ModalListFollowsType {
  FOLLOWED = 'FOLLOWED',
  FOLLOWERS = 'FOLLOWERS',
  PENDING = 'PENDING',
}

export enum StudentThumbnailType {
  DEFAULT = 'DEFAULT',
  FOLLOWERS = 'FOLLOWERS',
  PENDING = 'PENDING',
}
