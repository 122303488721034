import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  GraduationNameResult,
  ListGraduationNamesResult,
} from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversityCitiesResult } from '../../../shared/models/api-results/university-city.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import { UniversityCityPayload } from '../../../shared/models/payloads/university-city.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpUniversityCityService {
  constructor(private http: HttpClient) {}

  list() {
    return this.http
      .get(`${environment.api_base_url}/university_cities/list_university_cities`)
      .pipe(map((res) => res as ListUniversityCitiesResult));
  }

  wAdd(payload: UniversityCityPayload) {
    return this.http
      .post(`${environment.api_base_url}/university_cities/add_university_city`, payload)
      .pipe(map((res) => res as AddedEntity));
  }

  wUpdate(id: string, payload: UniversityCityPayload) {
    return this.http.post(
      `${environment.api_base_url}/university_cities/${id}/update_university_city`,
      payload
    );
  }
}
