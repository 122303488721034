import { Component, Input, OnInit } from '@angular/core';
import { ApiError } from '../../models/api-results/error.api-result';

@Component({
  selector: 'app-error-tooltip',
  templateUrl: './error-tooltip.component.html',
  styleUrls: ['./error-tooltip.component.scss'],
})
export class ErrorTooltipComponent implements OnInit {
  @Input() error: ApiError;

  constructor() {}

  ngOnInit(): void {}

  get errorText() {
    if (this.error.code) {
      return `[${this.error.code}] ${this.error.message}`;
    } else {
      return this.error.message;
    }
  }
}
