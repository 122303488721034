import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AvatarService } from '../../../core/services/avatar.service';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { SanitizerService } from '../../../core/services/sanitizer.service';

import {
  ModalListImagesComponent,
  ModalListImagesData,
} from '../../modals/modal-list-images/modal-list-images.component';
import { Student } from '../../models/entities/student.entity';
import { LanguageService } from '../../../core/services/language.service';
import { ChallengeScore } from '../../models/entities/challenge-score.entity';
import { ChallengeSpecialtyScoreBadge } from '../../models/entities/challenge-specialty-score-badge.entity';
import { LinkService } from '../../../core/services/link.service';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { AcceptType } from '../../models/enums/form.enum';

@Component({
  selector: 'app-user-sunray',
  templateUrl: './user-sunray.component.html',
  styleUrls: ['./user-sunray.component.scss'],
})
export class UserSunrayComponent implements OnInit {
  @Input() student: Student;
  @Input() challengeScore: ChallengeScore;
  @Input() challengeSpecialtyScoreBadges: ChallengeSpecialtyScoreBadge[];

  @Input() canChangePhoto = false;
  @Input() introChallengeMode = false;

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  AcceptType = AcceptType;

  constructor(
    private store: Store,
    public sanitizerService: SanitizerService,
    public isApplicationService: IsApplicationService,
    public avatarService: AvatarService,
    private matDialog: MatDialog,
    public languageService: LanguageService,
    private linkService: LinkService
  ) {}

  ngOnInit(): void {}

  openModalImage() {
    if (this.student?.picture && !this.introChallengeMode) {
      if (!this.isApplicationService.isApplication()) {
        const modalData: ModalListImagesData = {
          images: [this.student.picture],
          currentImageIndex: 0,
        };
        this.matDialog.open(ModalListImagesComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      } else {
        this.linkService.openBlank(this.student.picture);
      }
    }
  }
}
