import { AddedEntity } from '../api-results/base.api-result';
import { ExamContentQuestionResult } from '../api-results/exam-content-question.api-result';
import { ExamContentQuestionPayload } from '../payloads/exam-content-question.payload';
import { ContentQuestion } from './content-question.entity';
import { Question } from './question.entity';

export class ExamContentQuestion extends ContentQuestion {
  static addedEntityToResult(
    payload: ExamContentQuestionPayload,
    addedEntity: AddedEntity,
    question: Question,
    priority: number
  ): ExamContentQuestionResult {
    return {
      id: addedEntity.id,
      priority,
      question: question.toResult(),
    };
  }
}
