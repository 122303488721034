import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';

import { ChallengeSession } from '../../../models/entities/challenge-session.entity';

@Component({
  selector: 'app-challenge-major',
  templateUrl: './challenge-major.component.html',
  styleUrls: ['./challenge-major.component.scss'],
})
export class ChallengeMajorComponent implements OnInit {
  @Input() challengeSession: ChallengeSession;

  constructor(public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
