import { FollowResult, FollowedStudentResult } from '../api-results/follow.api-result';
import { StudentResult } from '../api-results/student.api-result';
import { FollowStatus, ModalListFollowsType } from '../enums/follow.enum';
import { GraduationName } from './graduation-name.entity';
import { Student } from './student.entity';
import { University } from './university.entity';

export class Follow {
  status: FollowStatus;

  constructor(result: FollowResult) {
    this.status = result.status;
  }

  toResult(): FollowResult {
    return {
      status: this.status,
    };
  }
}

export class FollowedStudent {
  student: Student;

  constructor(result: FollowedStudentResult, modalType: ModalListFollowsType) {
    // nnkitodo [v2later] typage, éviter le as
    this.student = new Student({
      id: result.userId,
      username: result.username,
      picture: result.picture,
      graduationName: result.graduationName ? new GraduationName(result.graduationName) : null,
      university: result.university ? new University(result.university) : null,
      followedByMe:
        modalType === ModalListFollowsType.FOLLOWED
          ? {
              status: FollowStatus.APPROVED,
            }
          : null,
      followingMe:
        modalType === ModalListFollowsType.FOLLOWERS
          ? {
              status: FollowStatus.APPROVED,
            }
          : modalType === ModalListFollowsType.PENDING
          ? {
              status: FollowStatus.PENDING,
            }
          : null,
    } as any);
  }
}
