<div class="users-pictures-list">
  <div
    *ngFor="
      let student of content.students
        | filterLoggedUser : (loggedStudent$ | async)?.id
        | slice : 0 : 2
    "
    class="nnki-rounded-background-image"
    [style.background-image]="sanitizerService.imageProfile(student.picture)"
  ></div>
  <div
    class="user-plus"
    *ngIf="(content.students | filterLoggedUser : (loggedStudent$ | async)?.id).length > 2"
  >
    +{{ (content.students | filterLoggedUser : (loggedStudent$ | async)?.id).length - 2 }}
  </div>
</div>

<div class="truncate leading-loose">
  <ng-container *ngIf="isInvitation">
    <span
      >{{ 'challenge.te_lance' | translate : { user: content.students[0].username } }}<br
    /></span>
    <span class="text-11 sm:hidden">
      {{ content.challenge.specialty.name }}
    </span>
  </ng-container>

  <ng-container *ngIf="!isInvitation">
    <span class="hidden sm:inline">
      {{ content.students | filterLoggedUser : (loggedStudent$ | async)?.id | list : 'username' }}
    </span>
    <span class="sm:hidden">{{ content.challenge.specialty.name }}</span>
  </ng-container>
</div>
