<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -xlarge">
  <button class="mat-modal-closer" (click)="close()">
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div
    class="modal-inside -candidate-challenge block--modal-challenge"
    [class.bg-site-card-gradient-1]="modalMode == 'question-intro' || modalMode == 'question'"
  >
    <app-intro-candidate-challenge
      *ngIf="modalMode == 'intro' && challengeSession"
      [challengeSession]="challengeSession"
      (launchEmitter)="initSession()"
      (exitEmitter)="launchChallenge()"
    ></app-intro-candidate-challenge>

    <app-intro-question-challenge
      *ngIf="modalMode == 'question-intro' && currentChallengeQuestion"
      [challengeSession]="challengeSession"
      [currentChallengeQuestion]="currentChallengeQuestion"
      (exitEmitter)="launchCurrentChallengeQuestionForm()"
    ></app-intro-question-challenge>

    <app-question-challenge
      *ngIf="modalMode == 'question' && currentChallengeQuestion"
      [challengeSession]="challengeSession"
      [currentChallengeQuestion]="currentChallengeQuestion"
      [remainingSeconds]="remainingSeconds"
      [percentTimeElapsed]="(percentTimeElapsed$ | async) * 100"
      (goToNextQuestionEmitter)="launchNextChallengeQuestionIntro()"
    ></app-question-challenge>

    <app-end-challenge
      *ngIf="modalMode == 'end'"
      [challengeId]="modalData.challengeId"
      (closeEmitter)="close()"
    ></app-end-challenge>
  </div>
</div>
