import { Specialty } from '../entities/specialty.entity';
import { Student } from '../entities/student.entity';

export interface ChallengeFormValue {
  specialty: Specialty;
  students: Student[];
}

export class ChallengePayload {
  specialtyId: string;
  studentIds: string[];

  constructor(formValue: ChallengeFormValue) {
    this.specialtyId = formValue.specialty.id;
    this.studentIds = formValue.students.map((elt) => elt.id);
  }
}
