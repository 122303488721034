<div
  class="block-scrollable-list {{ scrollableListHeight }} sm:h-[270px]"
  id="friendsList"
  appInfiniteScroll
  [scrollContainer]="'friendsList'"
  [scrollContent]="'scrollableContent'"
  (needToScroll)="scroll()"
>
  <div class="scrollable_content" id="scrollableContent">
    <app-error-tooltip
      *ngIf="componentStore.error$ | async as error"
      [error]="error"
    ></app-error-tooltip>

    <ng-container *ngIf="componentStore.displayedStudents$ | async as students">
      <div class="list-grid">
        <app-friend-invite
          *ngFor="let student of students"
          [student]="student"
          (click)="toggleUserSelected(student)"
        ></app-friend-invite>
      </div>

      <app-no-results *ngIf="(componentStore.loaded$ | async) && !students.length"></app-no-results>
    </ng-container>

    <app-loader-infinite-scroll
      [loading$]="componentStore.loading$"
      [scrolling$]="componentStore.scrolling$"
      [smallLoader]="true"
    ></app-loader-infinite-scroll>
  </div>
</div>

<div class="block--friends-list-search mt-8">
  <div class="row-form-nnki -nowrap">
    <mat-form-field [floatLabel]="'auto'" class="form-field-nnki width100">
      <mat-label>{{ 'challenge.rechercher' | translate }}</mat-label>
      <input matInput type="text" [formControl]="searchControl" />
    </mat-form-field>
    <button
      matRipple
      [matRippleCentered]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="50"
      class="btn-nnki btn-nnki-primary btn-nnki-icon"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-search-bold.svg"
        [svgStyle]="{ 'width.px': '20' }"
      ></svg-icon>
    </button>
  </div>
</div>

<!-- nnkitodo [v2later] -->
<!-- <button
  matRipple
  [matRippleCentered]="false"
  [matRippleUnbounded]="true"
  [matRippleRadius]="50"
  type="button"
  class="btn-nnki btn-nnki-primary btn-nnki-icon-left btn-nnki-uppercase btn-nnki-heavy ml-auto mt-8 bg-facebook text-11 sm:text-12"
  (click)="facebookImport()"
  *ngIf="!isImporting"
>
  <svg-icon
    src="assets/images/svg-icons/icon-facebook.svg"
    [svgStyle]="{ 'width.px': '22' }"
  ></svg-icon>
  {{ 'settings.facebook_import' | translate }}
</button>

<div class="spinner_100_container" *ngIf="isImporting">
  <app-loader-dots></app-loader-dots>
</div> -->
