import { ContentQuestionResult } from '../api-results/content-question.api-result';
import { Question } from './question.entity';
import { SessionQuestion } from './session-question.entity';

export abstract class ContentQuestion {
  id: string;
  priority: number;
  question: Question;

  index: number;

  sessionQuestion: SessionQuestion;

  constructor(result: ContentQuestionResult) {
    this.id = result.id;
    this.priority = result.priority;
    if (result.question) this.question = new Question(result.question);
  }

  get displayName() {
    return `Q${this.index + 1} - ${this.question.displayName}`;
  }

  toResult(): ContentQuestionResult {
    return {
      id: this.id,
      priority: this.priority,
      question: this.question?.toResult(),
    };
  }
}
