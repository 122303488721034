import { ExamTypeResult, UpdateExamTypeIconResult } from '../api-results/exam-type.api-result';
import { AuthorizedExamType } from '../enums/exam.enums';
import { ExamTypeFormValue, UpdateExamTypePayload } from '../payloads/exam-type.payload';

export class ExamType {
  id: string;
  name: AuthorizedExamType;
  description: string;
  isPaidByContent: boolean;
  icon: string;
  totalPublishedExams: number;
  totalCompletedPublishedExams: number;

  constructor(result: ExamTypeResult) {
    this.id = result.id;
    this.name = result.name as AuthorizedExamType;
    this.description = result.description;
    this.isPaidByContent = result.isPaidByContent;
    this.icon = result.icon;
    this.totalPublishedExams = result.totalPublishedExams;
    this.totalCompletedPublishedExams = result.totalCompletedPublishedExams;
  }

  get isMultiple() {
    if (this.name === AuthorizedExamType.ANNALS || this.name === AuthorizedExamType.ECNI_BLANK) {
      return true;
    }
    return false;
  }

  get priority() {
    if (this.name === AuthorizedExamType.QUIZZ) return 1;
    if (this.name === AuthorizedExamType.QUIZZ_ISO) return 11;
    if (this.name === AuthorizedExamType.QUIZZ_MMG) return 12;
    if (this.name === AuthorizedExamType.QUIZZ_PREPACADEMY) return 13;
    if (this.name === AuthorizedExamType.CONFTUBE) return 20;
    if (this.name === AuthorizedExamType.MASTERCLASS) return 30;
    if (this.name === AuthorizedExamType.LCA) return 40;
    if (this.name === AuthorizedExamType.IMAGING) return 50;
    if (this.name === AuthorizedExamType.CONTEST) return 60;
    if (this.name === AuthorizedExamType.CONTEST_ISO) return 61;
    if (this.name === AuthorizedExamType.CONTEST_OFFICIAL) return 62;
    if (this.name === AuthorizedExamType.EASY_DP) return 70;
    if (this.name === AuthorizedExamType.MINI_DP) return 80;
    if (this.name === AuthorizedExamType.KFP) return 90;
    if (this.name === AuthorizedExamType.TCS) return 100;
    if (this.name === AuthorizedExamType.ECNI_BLANK) return 110;
    if (this.name === AuthorizedExamType.ANNALS) return 120;
    if (this.name === AuthorizedExamType.ECOS) return 130;
  }

  get hasFolders() {
    return (
      this.name === AuthorizedExamType.QUIZZ ||
      this.name === AuthorizedExamType.MASTERCLASS ||
      this.name === AuthorizedExamType.LCA ||
      this.name === AuthorizedExamType.IMAGING ||
      this.name === AuthorizedExamType.CONTEST ||
      this.name === AuthorizedExamType.EASY_DP ||
      this.name === AuthorizedExamType.MINI_DP ||
      this.name === AuthorizedExamType.KFP ||
      this.name === AuthorizedExamType.TCS ||
      this.name === AuthorizedExamType.ECNI_BLANK ||
      this.name === AuthorizedExamType.ANNALS
    );
  }

  get hasIsolatedQuestions() {
    return (
      this.name === AuthorizedExamType.QUIZZ_ISO ||
      this.name === AuthorizedExamType.QUIZZ_MMG ||
      this.name === AuthorizedExamType.QUIZZ_PREPACADEMY ||
      this.name === AuthorizedExamType.CONFTUBE ||
      this.name === AuthorizedExamType.MASTERCLASS ||
      this.name === AuthorizedExamType.CONTEST_ISO ||
      this.name === AuthorizedExamType.CONTEST_OFFICIAL ||
      this.name === AuthorizedExamType.ECNI_BLANK ||
      this.name === AuthorizedExamType.ANNALS
    );
  }

  toFormValue(): ExamTypeFormValue {
    return {
      name: this.name,
      description: this.description,
      isPaidByContent: this.isPaidByContent,
      icon: {
        file: null,
        path: this.icon,
      },
    };
  }

  toResult(): ExamTypeResult {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      isPaidByContent: this.isPaidByContent,
      icon: this.icon,
      totalPublishedExams: this.totalPublishedExams,
      totalCompletedPublishedExams: this.totalCompletedPublishedExams,
    };
  }

  static updatedEntityToResult(
    payload: UpdateExamTypePayload,
    updatePictureResult: UpdateExamTypeIconResult
  ): Partial<ExamTypeResult> {
    return {
      description: payload.description,
      isPaidByContent: payload.isPaidByContent,
      icon: updatePictureResult.icon,
    };
  }
}
