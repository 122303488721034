<!-- nnkitodo [v2later icons] remplacer tous les svg icon par ce component -->
<!-- nnkitodo [v2later icons] voir si on remplacerait pas les font-awesome par du google fonts -->
<!-- nnkitodo [v2later icons] nettoyer dossier icons -->
<svg-icon
  [src]="
    isMaterial
      ? 'assets/images/material-icons/' + icon + '.svg'
      : isV2Icon
      ? 'assets/images/v2-icons/' + icon + '.svg'
      : 'assets/images/svg-icons/' + icon + '.svg'
  "
  [svgStyle]="{ 'width.px': width }"
  [ngClass]="{ '-inline': inline }"
></svg-icon>
