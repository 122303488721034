import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryData } from '../../../shared/models/payloads/query-data.payload';

import { environment } from '../../../../environments/environment';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import {
  ListWebsiteNotificationsResult,
  TotalUnreadWebsiteNotificationsResult,
} from '../../../shared/models/api-results/website-notification.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpNotificationService {
  constructor(private http: HttpClient) {}

  list(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/me/list_my_website_notifications`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListWebsiteNotificationsResult));
  }

  listUnread() {
    return this.http
      .get(`${environment.api_base_url}/me/get_my_total_unread_website_notifications`)
      .pipe(map((res) => res as TotalUnreadWebsiteNotificationsResult));
  }

  read(id: string) {
    return this.http.post(`${environment.api_base_url}/me/read_website_notification/${id}`, {});
  }
}
