import { Component, Input, OnInit } from '@angular/core';

import { SanitizerService } from '../../../../core/services/sanitizer.service';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';

import { ChallengeInvitation } from '../../../models/entities/challenge-invitation.entity';
import { Store } from '@ngrx/store';
import { ChallengeSession } from '../../../models/entities/challenge-session.entity';

@Component({
  selector: 'app-challenge-description',
  templateUrl: './challenge-description.component.html',
  styleUrls: ['./challenge-description.component.scss'],
})
export class ChallengeDescriptionComponent implements OnInit {
  @Input() content: ChallengeSession | ChallengeInvitation;
  @Input() isInvitation: boolean;

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  constructor(private store: Store, public sanitizerService: SanitizerService) {}

  ngOnInit(): void {}
}
