import moment from 'moment';
import { SessionQuestionResult } from '../api-results/session-question.api-result';
import { QuestionAnswerMethod } from '../enums/question.enums';
import { convertStringToCorrectionCoordinates } from './correction.entity';
import { CustomExamQuestion } from './custom-exam-question.entity';
import { ExamContentQuestion } from './exam-content-question.entity';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { ContentQuestion } from './content-question.entity';
import { Question } from './question.entity';
import { QuestionResult } from '../api-results/question.api-result';

export class SessionQuestion {
  grade: number;
  maxGrade: number;
  successRate: number;
  question: Question;
  answers: string[];
  answeredAt: Date;
  lastRecomputedAt: Date;

  constructor(result: SessionQuestionResult) {
    this.grade = result.grade;
    this.maxGrade = result.maxGrade;
    this.successRate = result.successRate;
    if (result.question) this.question = new Question(result.question);
    if (result.answeredAt) this.answeredAt = moment(result.answeredAt).toDate();
    if (result.lastRecomputedAt) this.lastRecomputedAt = moment(result.lastRecomputedAt).toDate();
    this.answers = result.answers;
  }

  toResult(): SessionQuestionResult {
    return {
      grade: this.grade * 100,
      maxGrade: this.maxGrade,
      successRate: this.successRate * 100,
      question: this.question?.toResult(),
      answers: this.answers,
      answeredAt: this.answeredAt ? moment(this.answeredAt).format(API_DATETIME_FORMAT) : null,
      lastRecomputedAt: this.lastRecomputedAt
        ? moment(this.lastRecomputedAt).format(API_DATETIME_FORMAT)
        : null,
    };
  }

  static buildQuestionsFromSession(
    questions: SessionQuestion[],
    contentQuestions: ContentQuestion[]
  ) {
    questions.forEach((sessionQuestion) => {
      const foundContentQuestion = contentQuestions.find(
        (contentQuestion) => contentQuestion.question.id === sessionQuestion.question?.id
      );
      if (foundContentQuestion?.question) {
        foundContentQuestion.question.isAnswered = true;
        if (sessionQuestion.answers?.length) {
          switch (foundContentQuestion.question.answerMethod) {
            case QuestionAnswerMethod.MATCH:
              foundContentQuestion.question.answerMatch = sessionQuestion.answers[0];
              break;
            case QuestionAnswerMethod.ONE:
            case QuestionAnswerMethod.TCS:
              foundContentQuestion.question.selectedAnswerOne = sessionQuestion.answers[0];
              // pour les challenges
              sessionQuestion.answers.forEach((answer) => {
                const foundCorrection = foundContentQuestion.question.corrections.find(
                  (correction) => correction.id === answer
                );
                if (foundCorrection) {
                  foundCorrection.isSelected = true;
                }
              });
              break;
            case QuestionAnswerMethod.MANY_UNDEFINED:
            case QuestionAnswerMethod.MANY_DEFINED_SHORT:
            case QuestionAnswerMethod.MANY_DEFINED_LONG:
              sessionQuestion.answers.forEach((answer) => {
                const foundCorrection = foundContentQuestion.question.corrections.find(
                  (correction) => correction.id === answer
                );
                if (foundCorrection) {
                  foundCorrection.isSelected = true;
                }
              });
              break;
            case QuestionAnswerMethod.MATCHING_AREA:
              foundContentQuestion.question.answerMatchingRecognition =
                convertStringToCorrectionCoordinates(sessionQuestion.answers[0]);
              break;
          }
        }
      }
    });
  }
}
