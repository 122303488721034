<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -large">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles -noImage">
      <div class="background -green"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>

    <div class="modal-text">
      <div class="modal-picto -picto-svg -green">
        <svg-icon
          src="assets/images/svg-icons/icon-pictures.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title">
        {{ 'util.zoom' | translate }}
      </div>

      <div class="grid gap-8 grid-cols-[repeat(auto-fit,minmax(75px,1fr))]">
        <img
          *ngFor="let image of images"
          [src]="image"
          (click)="openPhotoViewer(image)"
          class="cursor-pointer"
        />
      </div>
    </div>
  </div>
</div>
