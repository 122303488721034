import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-list-scroll',
  templateUrl: './loader-list-scroll.component.html',
  styleUrls: ['./loader-list-scroll.component.scss'],
})
export class LoaderListScrollComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
