// nnkitodo [v2later] remplacer ça ?
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss'],
})
export class TextContentComponent implements OnInit {
  @Input() text: string;
  @Input() dummyWidth: number;
  @Input() dummyHeight = 20;
  @Input() dummyCentered = false;

  constructor() {}

  ngOnInit(): void {}
}
