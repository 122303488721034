import { Question } from '../entities/question.entity';

export abstract class AnswerQuestionPayload {
  questionId: string;
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}

export class AnswerMatchQuestionPayload extends AnswerQuestionPayload {
  answer: string;

  constructor(question: Question, timeSpent?: number) {
    super(question, timeSpent);
    this.answer = question.answerMatch;
  }
}

export class AnswerOneQuestionPayload extends AnswerQuestionPayload {
  answer: string;

  constructor(question: Question, timeSpent?: number) {
    super(question, timeSpent);
    if (question.selectedAnswerOne) {
      this.answer = question.selectedAnswerOne;
    } else {
      this.answer = question.corrections
        .filter((correction) => correction.isSelected)
        .map((correction) => correction.id)?.[0];
    }
  }
}

export class AnswerManyQuestionPayload extends AnswerQuestionPayload {
  answers: string[];

  constructor(question: Question, timeSpent?: number) {
    super(question, timeSpent);
    this.answers = question.corrections
      .filter((correction) => correction.isSelected)
      .map((correction) => correction.id);
  }
}

export class AnswerMatchingAreaQuestionPayload extends AnswerQuestionPayload {
  answer: string;

  constructor(question: Question, timeSpent?: number) {
    super(question, timeSpent);
    if (question.answerMatchingRecognition) {
      this.answer = `${question.answerMatchingRecognition?.x}:${question.answerMatchingRecognition?.y}`;
    }
  }
}
