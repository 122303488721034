<mat-form-field [floatLabel]="'auto'" [class.form-field-nnki]="legacy">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControlName]="controlName"
    [type]="type"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
  />
  <svg-icon *ngIf="icon" [src]="icon" [svgStyle]="{ 'width.px': '20' }" matSuffix></svg-icon>

  <mat-error
    *ngIf="group.controls[controlName].invalid"
    [innerHTML]="group.controls[controlName].errors | displayFormControlErrors"
    class="error-tooltip"
  ></mat-error>
</mat-form-field>
<!-- nnkitodo [v2later] utiliser partout ad/stu, idem sur tous les form fields -->
