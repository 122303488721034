import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Data } from '@angular/router';
import { RoutingService } from './routing.service';
import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { merge, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private routingService: RoutingService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}

  // nnkitodo [v2later router] nettoyer + renommer tout student + public area
  // nnkitodo [v2later router] ajouter dans le title 'dashboard' si on est dans la writer area / idem student / public
  // nnkitodo [v2later] utiliser breadcrumb:label partout au lieu de navbarTitleKey
  init() {
    this.translate.get('meta.description').subscribe((trad) => {
      this.meta.updateTag({ name: 'description', content: trad });
    });

    merge(
      of(this.routingService.getLastRouteNodeData()),
      this.routingService.lastRouteRouterEvents
    ).subscribe((nodeData: Data) => {
      let title = $localize`Le site de préparation aux EDN`;
      if (nodeData?.breadcrumb?.label) title = nodeData?.breadcrumb?.label;
      else if (nodeData?.navbarTitleKey) title = this.translate.instant(nodeData.navbarTitleKey);

      this.titleService.setTitle(`${this.languageService.siteName} | ${title}`);
    });
  }
}
