import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { ChallengeSessionQueryData } from '../../../shared/models/payloads/challenge-session.payload';
import {
  ChallengeSessionResult,
  ListChallengeSessionsResult,
} from '../../../shared/models/api-results/challenge-session.api-result';
import {
  AnswerMatchQuestionPayload,
  AnswerMatchingAreaQuestionPayload,
  AnswerManyQuestionPayload,
  AnswerOneQuestionPayload,
} from '../../../shared/models/payloads/session.payload';
import {
  AnswerToSessionQuestionResult,
  SessionQuestionResult,
} from '../../../shared/models/api-results/session-question.api-result';

@Injectable({
  providedIn: 'root',
})
export class HttpChallengeSessionService {
  constructor(private http: HttpClient) {}

  get(challengeId: string) {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/get_details_of_one_of_my_challenge_session`
      )
      .pipe(map((res) => res as ChallengeSessionResult));
  }

  list(params: ChallengeSessionQueryData) {
    return this.http
      .get(`${environment.api_base_url}/challenge_sessions/me/list_my_challenge_sessions`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListChallengeSessionsResult));
  }

  resume(challengeId: string) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/resume_challenge_session`,
        {}
      )
      .pipe(map((res) => res as ChallengeSessionResult));
  }

  pause(challengeId: string) {
    return this.http.post(
      `${environment.api_base_url}/challenge_sessions/${challengeId}/pause_challenge_session`,
      {}
    );
  }

  close(challengeId: string) {
    return this.http.post(
      `${environment.api_base_url}/challenge_sessions/${challengeId}/close_challenge_session`,
      {}
    );
  }

  answerMatchQuestion(challengeId: string, payload: AnswerMatchQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_match_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  answerOneQuestion(challengeId: string, payload: AnswerOneQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_one_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  answerTCSQuestion(challengeId: string, payload: AnswerOneQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_tcs_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  answerManyUndefinedQuestion(challengeId: string, payload: AnswerManyQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_many_undefined_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  answerManyDefinedQuestion(challengeId: string, payload: AnswerManyQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_many_defined_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  answerMatchingAreaQuestion(challengeId: string, payload: AnswerMatchingAreaQuestionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/answer_to_a_question_answer_method_matching_area_in_a_challenge_session`,
        payload
      )
      .pipe(map((res) => res as AnswerToSessionQuestionResult));
  }

  getCorrection(challengeId: string) {
    return this.http
      .get(
        `${environment.api_base_url}/challenge_sessions/${challengeId}/get_correction_of_closed_challenge_session`
      )
      .pipe(
        // nnkitodo [v2later] fix api
        map((res: any) => {
          res.challengeSessionQuestionAnswers = res.challengeSessionQuestionAnswers.map((elt) => {
            elt.question = {
              id: elt.id,
            };
            elt.grade = elt.grade / 100;
            return elt;
          });
          return res;
        }),
        map((res) => res as ChallengeSessionResult)
      );
  }
}
