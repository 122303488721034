import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-form-field-text',
  templateUrl: './form-field-text.component.html',
  styleUrls: ['./form-field-text.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class FormFieldTextComponent implements OnInit {
  @Input() label: string;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() autocomplete: string;
  @Input() type = 'text';
  @Input() icon: string;

  @Input() legacy = false;

  constructor() {}

  ngOnInit(): void {}
}
