export enum SessionStatus {
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
  CLOSED = 'CLOSED',
}

export function getColorForSessionStatus(status: SessionStatus) {
  if (status === SessionStatus.OPEN) {
    return 'bg-purple-one';
  } else if (status === SessionStatus.PAUSED) {
    return 'bg-yellow-one';
  } else if (status === SessionStatus.CLOSED) {
    return 'bg-green-three';
  }
}

export enum SessionContentType {
  NONE = 'NONE',
  SPECIALTIES = 'SPECIALTIES',
  COURSES = 'COURSES',
}

export enum SessionContentSetting {
  NONE = 'NONE',
  NEVER_DONE = 'NEVER_DONE',
  WRONGLY_ANSWERED = 'WRONGLY_ANSWERED',
}

export enum SessionSourceSetting {
  NONE = 'NONE',
  ECNI = 'ECNI',
  LISA = 'LISA',
  ANNALS = 'ANNALS',
}

export enum SessionDifficultySetting {
  NONE = 'NONE',
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD',
  EXPERT = 'EXPERT',
}

export enum SessionPediatricSetting {
  NONE = 'NONE',
  NO_PEDIATRIC = 'NO_PEDIATRIC',
  ONLY_PEDIATRIC = 'ONLY_PEDIATRIC',
}

export enum SessionQuestionAnswerMethodSetting {
  NONE = 'NONE',
  ONE = 'ONE',
  MANY_UNDEFINED = 'MANY_UNDEFINED',
  MANY_DEFINED_SHORT = 'MANY_DEFINED_SHORT',
  MANY_DEFINED_LONG = 'MANY_DEFINED_LONG',
  MATCH = 'MATCH',
  MATCHING_AREA = 'MATCHING_AREA',
  TCS = 'TCS',
}

export enum SessionNotionRankSetting {
  NONE = 'NONE',
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum SessionType {
  CUSTOM_EXAM = 'CUSTOM_EXAM',
  EXAM_CONTENT = 'EXAM_CONTENT',
  CHALLENGE = 'CHALLENGE',
  TRAINING = 'TRAINING',
}

export enum ContentSessionType {
  FOLDER = 'FOLDER',
  ISOLATED_QUESTIONS = 'ISOLATED_QUESTIONS',
  ECOS = 'ECOS',
  CUSTOM_EXAM = 'CUSTOM_EXAM',
  CHALLENGE = 'CHALLENGE',
  TRAINING = 'TRAINING',
}

export enum SessionTrainingType {
  CUSTOM = 'custom',
  KNOWLEDGE_ANCHORING = 'ancrage',
}
