<div class="modal-top">
  <app-oval-header-card [type]="'large'"></app-oval-header-card>
</div>

<div class="end-challenge-modal-middle">
  <ng-container *ngIf="challengeSession">
    <div class="content-top">
      <div class="user-image-sunray">
        <div class="sunray"></div>
        <div
          class="nnki-rounded-background-image"
          [style.background-image]="
            sanitizerService.imageProfile((loggedStudent$ | async)?.picture)
          "
        ></div>
      </div>

      <div
        class="modal-title"
        [innerHTML]="
          'challenge.defi_termine'
            | translate
              : { score: challengeSession.grade, maxScore: languageService.challengeMaxGrade }
        "
      ></div>
    </div>

    <div class="challenge-summary">
      <app-challenge-description [content]="challengeSession"></app-challenge-description>

      <app-challenge-major [challengeSession]="challengeSession"></app-challenge-major>
    </div>

    <div class="challenge-new">
      <div class="new-text">
        {{ 'challenge.relancer' | translate }}
      </div>

      <div
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-purple btn-nnki-icon-left btn-nnki-uppercase"
        (click)="relaunchChallenge()"
      >
        <svg-icon
          src="assets/images/svg-icons/icon-challenge.svg"
          [svgStyle]="{ 'width.px': '22' }"
        ></svg-icon>
        {{ 'challenge.relancer2' | translate }}
      </div>
    </div>
  </ng-container>

  <div class="spinner_100_container mt-8" *ngIf="!challengeSession">
    <app-loader-dots></app-loader-dots>
  </div>
</div>

<div class="modal-buttons">
  <div
    matRipple
    [matRippleCentered]="false"
    [matRippleUnbounded]="true"
    [matRippleRadius]="50"
    class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
    (click)="close()"
  >
    {{ 'util.fermer' | translate }}
  </div>
</div>
