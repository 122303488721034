import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ChallengeService } from '../../../../core/services/challenge.service';
import { SanitizerService } from '../../../../core/services/sanitizer.service';
import { SoundService } from '../../../../core/services/sound.service';

import {
  ModalCreateChallengeComponent,
  ModalCreateChallengeData,
} from '../../modal-create-challenge/modal-create-challenge.component';
import { ChallengeSession } from '../../../models/entities/challenge-session.entity';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';
import { LanguageService } from '../../../../core/services/language.service';
import { HttpChallengeSessionService } from '../../../../core/services/http/http-challenge-session.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-end-challenge',
  templateUrl: './end-challenge.component.html',
  styleUrls: ['./end-challenge.component.scss'],
})
export class EndChallengeComponent implements OnInit {
  @Input() challengeId: string;
  @Output() closeEmitter = new EventEmitter();

  challengeSession: ChallengeSession;

  loggedStudent$ = this.store.select(AuthSelectors.loggedStudent);

  constructor(
    private matDialog: MatDialog,
    private store: Store,
    private challengeService: ChallengeService,
    public languageService: LanguageService,
    public sanitizerService: SanitizerService,
    private soundService: SoundService,
    private httpChallengeSessionService: HttpChallengeSessionService
  ) {}

  ngOnInit(): void {
    this.loggedStudent$.pipe(take(1)).subscribe((loggedStudent) => {
      this.httpChallengeSessionService.get(this.challengeId).subscribe((res) => {
        this.challengeSession = new ChallengeSession(res, loggedStudent);

        this.challengeService.reloadChallengeList.emit();
        this.challengeService.updateAvailableChallenges();

        this.soundService.isChallengeMusicActivated().subscribe((challengeMusicActivated) => {
          if (challengeMusicActivated) {
            this.soundService.playSound('challenge-applause');
            this.soundService.fadeSound('challenge-applause');
          }
        });
      });
    });
  }

  relaunchChallenge() {
    this.loggedStudent$.pipe(take(1)).subscribe((loggedStudent) => {
      const modalData: ModalCreateChallengeData = {
        usersSelected: this.challengeSession.students.filter((student) => {
          return student.id !== loggedStudent.id;
        }),
      };

      this.matDialog.open(ModalCreateChallengeComponent, {
        maxWidth: '100vw',
        data: modalData,
      });

      this.close();
    });
  }

  close() {
    this.closeEmitter.emit();
  }
}
