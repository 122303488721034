import { ContractResult } from '../api-results/contract.api-result';

export class Contract {
  id: string;
  file: string;

  constructor(result: ContractResult) {
    this.id = result.id;
    this.file = result.file;
  }

  toResult(): ContractResult {
    return {
      id: this.id,
      file: this.file,
    };
  }
}
