<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content">
  <button class="mat-modal-closer" mat-dialog-close *ngIf="!data.forceUpdate">
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside bg-theme-white">
    <div class="modal-circles -noImage">
      <div class="background -green"></div>
      <div class="circle-1"></div>
      <div class="circle-2"></div>
    </div>

    <div class="modal-text">
      <div class="modal-picto -picto-svg -green">
        <svg-icon
          src="assets/images/svg-icons/icon-code.svg"
          [svgStyle]="{ 'width.px': '30' }"
        ></svg-icon>
      </div>

      <div class="modal-title">
        {{ 'update.modal_title' | translate }}
      </div>

      <div class="modal-theme">
        <p
          class="space-y-4"
          [innerHTML]="
            'update.modal_content'
              | translate
                : {
                    currentVersion: data.currentVersion,
                    newVersion: data.lastDeployedVersions
                      ? data.lastDeployedVersions.lastVersion.displayName
                      : '[auto]'
                  }
          "
        ></p>

        <ng-container *ngIf="data.lastDeployedVersions?.lastVersion.content">
          <div class="text-site-main-one font-bold mt-8 mb-4">
            {{ 'version.contenu' | translate }}
          </div>
          <div class="mb-8" [innerHTML]="data.lastDeployedVersions.lastVersion.content"></div>
        </ng-container>
      </div>

      <div class="modal-buttons">
        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
          (click)="updateSite()"
        >
          {{ 'update.mettre_a_jour' | translate }}
        </button>

        <button
          matRipple
          [matRippleCentered]="false"
          [matRippleUnbounded]="true"
          [matRippleRadius]="50"
          type="button"
          class="btn-nnki btn-nnki-transparent btn-nnki-uppercase"
          mat-dialog-close
          *ngIf="!data.forceUpdate"
        >
          {{ 'version.plus_tard' | translate }}
        </button>
      </div>

      <!-- nnkitodo [v2later] wtf -->
      <div class="h-[110px] sm:hidden"></div>
    </div>
  </div>
</div>
