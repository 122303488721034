import { createAction, props } from '@ngrx/store';
import { ListGraduationNamesResult } from '../../../shared/models/api-results/graduation-name.api-result';
import { ListUniversityCitiesResult } from '../../../shared/models/api-results/university-city.api-result';
import { ListUniversityCountriesResult } from '../../../shared/models/api-results/university-country.api-result';
import { ListUniversitiesResult } from '../../../shared/models/api-results/university.api-result';
import { ListExamTypesResult } from '../../../shared/models/api-results/exam-type.api-result';

export const listGraduationNames = createAction(
  '[GlobalEntitiesAction] List Graduation Names',
  props<{ forceUpdate: boolean }>()
);
export const listGraduationNamesSucceed = createAction(
  '[GlobalEntitiesAction] List Graduation Names Succeed',
  props<{ result: ListGraduationNamesResult }>()
);

export const listUniversityCountries = createAction(
  '[GlobalEntitiesAction] List University Countries',
  props<{ forceUpdate: boolean }>()
);
export const listUniversityCountriesSucceed = createAction(
  '[GlobalEntitiesAction] List University Countries Succeed',
  props<{ result: ListUniversityCountriesResult }>()
);

export const listUniversityCities = createAction(
  '[GlobalEntitiesAction] List University Cities',
  props<{ forceUpdate: boolean }>()
);
export const listUniversityCitiesSucceed = createAction(
  '[GlobalEntitiesAction] List University Cities Succeed',
  props<{ result: ListUniversityCitiesResult }>()
);

export const listUniversities = createAction(
  '[GlobalEntitiesAction] List Universities',
  props<{ forceUpdate: boolean }>()
);
export const listUniversitiesSucceed = createAction(
  '[GlobalEntitiesAction] List Universities Succeed',
  props<{ result: ListUniversitiesResult }>()
);

export const listExamTypes = createAction(
  '[GlobalEntitiesAction] List Exam Types',
  props<{ forceUpdate: boolean }>()
);
export const listExamTypesSucceed = createAction(
  '[GlobalEntitiesAction] List Exam Types Succeed',
  props<{ result: ListExamTypesResult }>()
);

export const listCountries = createAction(
  '[GlobalEntitiesAction] List Countries',
  props<{ defaultLanguage: string }>()
);
