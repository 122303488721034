// nnkitodo [v2later] remplacer ça ?
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-cover-image',
  templateUrl: './round-cover-image.component.html',
  styleUrls: ['./round-cover-image.component.scss'],
})
export class RoundCoverImageComponent implements OnInit {
  @Input() src: string;
  @Input() size: number;

  constructor() {}

  ngOnInit(): void {}
}
