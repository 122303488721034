import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { startWith, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../components/inherited/base/base.component';
import { Student } from '../../models/entities/student.entity';
import { ChooseFriendsStoreService } from './choose-friends.component-store';
import { StudentFiltersFormValue } from '../../models/payloads/student.payload';

@Component({
  selector: 'app-choose-friends',
  templateUrl: './choose-friends.component.html',
  styleUrls: ['./choose-friends.component.scss'],
  providers: [ChooseFriendsStoreService],
})
export class ChooseFriendsComponent extends BaseComponent implements OnInit {
  @Input() usersSelected: Student[] = [];
  @Input() scrollableListHeight: string;

  searchControl = new FormControl<string>('');

  constructor(public componentStore: ChooseFriendsStoreService) {
    super();
  }

  ngOnInit(): void {
    this.initFormEvents();
    this.componentStore.addSelectedUsers(this.usersSelected ? this.usersSelected : []);
  }

  initFormEvents() {
    this.searchControl.valueChanges.pipe(takeUntil(this.alive$), startWith('')).subscribe((val) => {
      this.componentStore.storeSelectedUsers();
      this.componentStore.initList({ username: val } as StudentFiltersFormValue);
    });
  }

  scroll() {
    this.componentStore.scroll();
  }

  toggleUserSelected(user: Student) {
    this.componentStore.updateUserSelected({ user, selected: !user.selected });
  }

  // nnkitodo [v2later]
  facebookImport() {
    // this.isImporting = true;
    // this.userService
    //   .facebookImport()
    //   .pipe(
    //     finalize(() => {
    //       this.isImporting = false;
    //     })
    //   )
    //   .subscribe(() => {
    //     if (!this.friendSearchControl.value || !this.friendSearchControl.value.length) {
    //       this.friendsList = [];
    //       // this.infiniteScrollDirective.resetScrollProperties();
    //       this.updateSelectedListDisplayed();
    //       this.getUsersByFriends();
    //     }
    //   });
  }
}
