import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader-infinite-scroll',
  templateUrl: './loader-infinite-scroll.component.html',
  styleUrls: ['./loader-infinite-scroll.component.scss'],
})
export class LoaderInfiniteScrollComponent implements OnInit {
  @Input() loading$: Observable<boolean>;
  @Input() scrolling$: Observable<boolean>;

  // nnkitodo [v2later] plus scalable
  @Input() smallLoader = false;

  constructor() {}

  ngOnInit(): void {}
}
