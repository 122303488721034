import {
  ExamContentEcosResult,
  UpdateExamContentEcosPatientPictogramResult,
  UpdateExamContentEcosPictureResult,
} from '../api-results/exam-content-ecos.api-result';
import {
  ExamContentEcosFormValue,
  UpdateExamContentEcosPayload,
} from '../payloads/exam-content-ecos.payload';
import { Course } from './course.entity';
import { ExamContent } from './exam-content.entity';

export class ExamContentEcos {
  subject: string;
  instructions: string;
  picture: string;
  preparationTime: number;
  patientPictogram: string;
  patientName: string;
  patientAge: number;
  socioEconomicStatus: string;
  consultationReasons: string;
  totalActionsAuthorized: number;
  courses: Course[];

  examContent: ExamContent;

  constructor(result: ExamContentEcosResult) {
    this.subject = result.subject;
    this.instructions = result.instructions;
    this.picture = result.picture;
    this.preparationTime = result.preparationTime;
    this.patientPictogram = result.patientPictogram;
    this.patientName = result.patientName;
    this.patientAge = result.patientAge;
    this.socioEconomicStatus = result.socioEconomicStatus;
    this.consultationReasons = result.consultationReasons;
    this.totalActionsAuthorized = result.totalActionsAuthorized;
    if (result.courses) this.courses = result.courses.map((elt) => new Course(elt));
    if (result.examContent) this.examContent = new ExamContent(result.examContent);
  }

  // nnkitodo [v2later] exam content ecos form value ce serait mieux
  toFormValue(): ExamContentEcosFormValue {
    return {
      subject: this.subject,
      instructions: this.instructions,
      picture: {
        file: null,
        path: this.picture,
      },
      preparationTime: this.preparationTime / 60,
      patientPictogram: {
        file: null,
        path: this.patientPictogram,
      },
      patientName: this.patientName,
      patientAge: this.patientAge,
      socioEconomicStatus: this.socioEconomicStatus,
      consultationReasons: this.consultationReasons,
      totalActionsAuthorized: this.totalActionsAuthorized,
    };
  }

  toResult(): ExamContentEcosResult {
    return {
      subject: this.subject,
      instructions: this.instructions,
      picture: this.picture,
      preparationTime: this.preparationTime,
      patientPictogram: this.patientPictogram,
      patientName: this.patientName,
      patientAge: this.patientAge,
      socioEconomicStatus: this.socioEconomicStatus,
      consultationReasons: this.consultationReasons,
      totalActionsAuthorized: this.totalActionsAuthorized,
      courses: this.courses?.map((elt) => elt.toResult()),
      examContent: undefined,
    };
  }

  static updatedEntityToResult(
    payload: UpdateExamContentEcosPayload,
    updatePictureResult: UpdateExamContentEcosPictureResult,
    updatePatientPictogramResult: UpdateExamContentEcosPatientPictogramResult
  ): Partial<ExamContentEcosResult> {
    return {
      subject: payload.subject,
      instructions: payload.instructions,
      picture: updatePictureResult.picture,
      preparationTime: payload.preparationTime,
      patientPictogram: updatePatientPictogramResult.patientPictogram,
      patientName: payload.patientName,
      patientAge: payload.patientAge,
      socioEconomicStatus: payload.socioEconomicStatus,
      consultationReasons: payload.consultationReasons,
      totalActionsAuthorized: payload.totalActionsAuthorized,
    };
  }
}
